import _ from 'lib/lodash.custom';

// We were originally using the precense of window.gtag to indicating a client was using GA4 via gtag.js, rather than GTM.
// However, we're finding that some clients have custom code that's defining this function even when using gtm.  So instead,
// we'll check for events that indicate gtm is loaded at all, and only make use of gtag when it's not.  There's a potential
// race condition here where this fires before gtm is initialized, but the risk seems relatively compares to the loss of event data
function shouldUseGtag() {
  var isGtmLoaded = _.some(window.dataLayer, function (d) { return d && d.hasOwnProperty('gtm.start'); });
  return window.gtag && !isGtmLoaded;
}

export default {
  shouldUseGtag: shouldUseGtag
};
