// Handles merging environmental and build configs
import $ from 'lib/jquery.ui';
import _ from 'lib/lodash.custom';
import ActionTagGeneratedConfig from 'generated/config.generated';

// Parse global config settings from any form divs present on the page.
// Form specific settings will be handled as we process each individual form.
// If multiple forms exist on the page, we'll pull the setting from the first one with that setting
// (So, don't set different global config values on different form divs!)
function getFormData(settingName) {
  return $('.ngp-form').filter(function () {
    return !!$(this).data(settingName);
  }).first().data(settingName);
}

var formDataConfig = {
  DATABAG_COOKIE_CONSENT: getFormData('databagCookieconsentmode'),
  FORMBUILDER_HOSTED: getFormData('formbuilderHosted') === true
};

var defaultConfig = {
  PAYPAL_SESSION_TIMEOUT_MINUTES: 60,
  PAYPAL_COMMERCE_PLATFORM_SESSION_TIMEOUT_MINUTES: 360, // https://developer.paypal.com/docs/api/orders/v2/#orders_create!c=200&path=links&t=response
  APPLE_PAY_SESSION_TIMEOUT_MINUTES: 8,
  VGS_POST_TIMEOUT_SECONDS: 30,
  // Asset locations for doublethedonation/matchpro functionality
  MATCH_PRO_SCRIPT_URL: 'https://doublethedonation.com/api/js/ddplugin.js',
  MATCH_PRO_CSS_URL: 'https://doublethedonation.com/api/css/ddplugin.css',
  FASTACTION_ENABLED: true
};

// Prefer the form config, then the generated config, then any default values
export default _.defaults({}, formDataConfig, ActionTagGeneratedConfig, defaultConfig);
