import _ from 'lib/lodash.custom';
import $ from 'lib/jquery.ui';
import baseUrl from 'app/baseUrl';
import loadContent from 'app/loadContent';
// need the .js here or esbuild tries to load the .resx file
import PrimaryResources from 'resources/PrimaryResources.resx.js';
import DatepickerResources from 'resources/Datepicker';

var resources = {
  'PrimaryResources': PrimaryResources,
  'Datepicker': DatepickerResources
};

export default _.memoize(function loadResource(resourceName, languageCode) {
  var dfd = $.Deferred();

  if (!resources.hasOwnProperty(resourceName)) {
    return dfd.resolve(Object.freeze({}));
  }

  var resource = resources[resourceName];
  var resourceData = _.clone(resource.data);

  var languageParts = languageCode ? languageCode.split('-') : [];
  var language = (languageParts[0] || '').toLowerCase();
  var countryCode = (languageParts[1] || '').toUpperCase();
  languageCode = language + (countryCode ? '-' + countryCode : '');

  var supportsLanguageCode;

  if (!languageCode || !resource.supported ||
    // Must support either the full language code or at least the language
    (!(supportsLanguageCode = _.contains(resource.supported, languageCode)) && !_.contains(resource.supported, language))) {
    return dfd.resolve(Object.freeze(resourceData));
  }

  var jsonFile = resourceName + '.' + (supportsLanguageCode ? languageCode : language) + '.json';
  return loadContent(baseUrl + jsonFile).then(function (result) {
    var langData = result;
    if (!_.isObject(result)) {
      try {
        langData = JSON.parse(result);
      } catch (err) {
        //failed to parse
        console.error('failed to parse resource json:', jsonFile, err);
        langData = {};
      }
    }
    return Object.freeze(_.extend(resourceData, langData));
  });
}, function (resourceName, languageCode) {
  return (resourceName || '_') + '-' + (languageCode || '_');
});
