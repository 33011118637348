import FieldView from 'app/views/FieldView';
import SignaturePad from 'app/SignaturePad';
import invokeCallbacks from 'app/NVTagCallbacks';

export default FieldView.extend({
  __name__: 'SignaturePadView',
  type: 'signature_pad',
  tagName: 'div',
  className: function () {
    return 'at-signature-pad   ' + this.options.definition.name;
  },
  events: {
    'click': 'renderFeedback',
    'touchend': 'renderFeedback'
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    this.template = this.options.templates.signature_pad;
  },
  context: function () {
    var context = {
      title: this.title,
      label: this.def.label || this.title,
      required: this.def.required && 'required'
    };

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    var self = this;

    self.$el.html(this.template(this.context()));

    var canvas = this.$el.find('canvas')[0];
    var clearBtn = this.$el.find('button[name="clear"]')[0];
    var undoBtn = this.$el.find('button[name="undo"]')[0];

    SignaturePad.setupSignaturePad(canvas, clearBtn, undoBtn)
      .then(function (signaturePad) {
        self.signaturePad = signaturePad;
      });

    return self;
  },
  val: function () {
    return this.signaturePad && !this.signaturePad.isEmpty() ? this.signaturePad.toDataURL() : null;
  }
});
