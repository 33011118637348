import Backbone from 'lib/backbone';
import CheckboxView from 'app/views/CheckboxView';
import currency from 'app/views/helpers/currency';

var _ = Backbone._;

export default CheckboxView.extend({
  __name__: 'CoverCostsCheckboxView',
  // This overrides any events tied to the checkbox view
  events: {
    'change input': 'updateModel'
  },
  updateModel: function () {
    var state = this.isChecked();
    this.contributionAmountModel.setCoverCostState(state);
  },
  initialize: function () {
    CheckboxView.prototype.initialize.call(this);
    _.bindAll(this, 'isChecked', 'renderFeedback', 'updateModel');

    this.contributionAmountModel = this.options.formview.contributionAmountModel;

    // listen to pre-AutoProcessing so we can turn off the checkbox if we are about to autoprocess
    // without the user interacting with the form
    this.listenTo(this.options.formview, 'preAutoProcess', _.partial(this.setval, false));

    // When the total amount changes, we might need to recalculate
    this.contributionAmountModel.on('change:totalAmount', this.renderFeedback);
  },
  setval: function (value) {
    // There are several cases where the parent form will pass in `undefined` here
    // The checkbox `setval` method ignores that value, so bail early and don't update the cover cost state
    if (typeof value === 'undefined') {
      return;
    }
    CheckboxView.prototype.setval.call(this, value);
    this.contributionAmountModel.setCoverCostState(value);
    this.renderFeedback();
    return this;
  },
  render: function () {
    return CheckboxView.prototype.render.call(this).hideOrShow();
  },
  renderFeedback: function () {
    // called on change to input
    this.hideOrShow();
    if (!this.isVisible()) {
      return;
    }

    var coverCostsAmount = this.contributionAmountModel.get('coverCost');
    var $titleContainer = this.$el.find('span.at-checkbox-title-container');
    var $info = $titleContainer.find('span.at-cover-costs-info');
    var $title = $titleContainer.find('span.at-checkbox-title');

    if (coverCostsAmount <= 0) {
      // hide info
      if ($info && $info.length) {
        $info.remove();
      }
      if ($title && $title.length && $title.text() !== this.def.title) {
        // remove the trailing period
        $title.text(this.def.title);
      }
    } else {
      var totalAmount = this.contributionAmountModel.get('totalAmount');

      var infoHtml = this.resources.PrimaryResources.TotalContributionAmountWillBe +
        ' <strong>' + currency.format(totalAmount) + '</strong>.';
      if (!$info || !$info.length) {
        var infoEl = document.createElement('span');
        infoEl.className = 'at-cover-costs-info';
        infoEl.innerHTML = infoHtml;
        $titleContainer.append(infoEl);
      } else {
        $info.html(infoHtml);
      }

      if ($title && $title.length) {
        var text = $title.text();
        if (text) {
          text = text.trim();
          var lastPeriod = text.lastIndexOf('.');
          var delimiter = ' ';
          if (lastPeriod !== text.length - 1) {
            delimiter = '. ';
          }
          $title.text(text + delimiter);
        }
      }
    }
    // do not return any errors since this view can never be in an error state
  },
  shouldHide: function () {
    // called within setval and renderFeedback (indirectly)
    var baseAmount = this.contributionAmountModel.get('baseAmount');
    var maxAmount = this.contributionAmountModel.maxAmount;
    return typeof baseAmount !== 'number' || baseAmount >= maxAmount || baseAmount <= 0;
  },
  val: function () {
    var coverCost = this.contributionAmountModel.get('coverCost');
    return coverCost > 0 ? coverCost : undefined;
  },
  isChecked: function () {
    return !!CheckboxView.prototype.val.call(this);
  }
});
