import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import FormError from 'app/FormError';
import FieldsetView from 'app/views/FieldsetView';
import moment from 'lib/moment';
import currency from 'app/views/helpers/currency';

var _ = Backbone._,
  recurring_fields = ['IsRecurring', 'SelectedFrequency', 'SelectedDuration', 'EndDate'],
  after_recurring_fields = ['CoverCostsAmount'];

export default FieldsetView.extend({
  __name__: 'ContributionView',
  type: 'contribution',
  events: {
    'blur': 'renderFeedback',
    'change *': 'renderFeedback',
    'change': 'touch',
    'change [name^="SelectedDuration"]': 'recurringCheckbox',
    'click [name="IsRecurring"]': 'recurringChecked',
    'change [name^="SelectedFrequency"]': 'onFrequencyChange',
    'change [name="ProcessingCurrency"]': 'onCurrencyChange'
  },
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);
    _.bindAll(this, 'onFrequencyChange', 'onCurrencyChange', 'recurringChecked', 'recurringCheckbox', 'updateRecurringSection');

    this.contributionRecurrenceModel = this.options.formview.contributionRecurrenceModel;
  },
  updateRecurringSection: function () {
    // cannot use jquery .show()/.hide() because it add inline "display: block" on the show call
    // which breaks custom styling for clients. so instead, toggle a class hides it
    if (this.contributionRecurrenceModel.allowOneTimeFrequency && !this.contributionRecurrenceModel.get('isRecurring')) {
      this.$el.find('.at-recurring').toggleClass('at-hide', true);
    } else {
      this.$el.find('.at-recurring .at-recurring-frequency').html(this.contributionRecurrenceModel.getFrequencyDisplayName());
      this.$el.find('.at-recurring').toggleClass('at-hide', false);
    }

    // handle accessibility attributes
    // this arrangement of inputs is really terrible for screen readers so we help things out
    // by explicitly telling screenreaders how to read this flow of inputs
    // for now this just handles the checkbox + single frequency
    var $recurringCheckbox = this.$el.find('input[type=checkbox][name=IsRecurring]');
    if ($recurringCheckbox.length) {
      var recurringLabel = this.$el.find('.IsRecurring .at-checkbox-title').text();
      var frequencyLabel = this.$el.find('.SelectedFrequency.select-collapse span.select-collapse').text() || this.$el.find('.SelectedFrequency select option:selected').text();
      var labels = _.filter([recurringLabel, frequencyLabel])
        .map(function (str) {
          return str.trim();
        });
      if (labels.length > 1) {
        $recurringCheckbox.attr('aria-label', labels.join(' '));
      }
    }
  },
  onFrequencyChange: function (event) {
    this.contributionRecurrenceModel.setFrequency(event.target.value);
    this.updateRecurringSection();
  },
  // Since the contribution fieldset owns the processing currency selector, it's responsible for
  // handling any necessary changes on selection
  onCurrencyChange: function (event) {
    var selectedCurrency = event.target.value;

    // Return if there's no change
    if (currency.getCurrency().code === selectedCurrency) {
      return;
    }

    // Set the new currency, then rerender the amount view and the currency note
    currency.setByCode(selectedCurrency);

    this.updateCurrencyNote();
  },
  updateCurrencyNote: function () {
    var c = currency.getCurrency();
    var message = this.resources.PrimaryResources.ContributionAcceptedIn + ' <b>' + c.code + ' ' + c.symbol + ' - ' + this.resources.PrimaryResources[c.resourceName] + '</b>';

    this.$('[name=AmountSubtitle]').html(message);
  },
  context: function () {
    // Get Currency and populate with display name
    var c = currency.getCurrency();
    c.name = this.resources.PrimaryResources[c.resourceName];

    // Show the currency note if the processing currency is non-USD or multiple currencies are allowed on the form
    var processingCurrency = _.find(this.def.children, { name: 'ProcessingCurrency' });
    var showCurrencyNote = c.code !== 'USD' || (processingCurrency && processingCurrency.type === 'select');

    var recurring = [];
    var context = {
      id: this.name.toLowerCase(),
      title: this.title,
      children: [],
      recurring: [],
      afterRecurring: [],
      currency: c,
      showCurrencyNote: showCurrencyNote,
      multistep: this.options.multistep,
      includeFrequencyPlaceholder: !!this.contributionRecurrenceModel.allowOneTimeFrequency
    };

    // TODO: Clean this up!
    _.each(this.def.children, function (element, index) {
      if (element.name === 'ConfirmIdentityHtml') {
        context.children.push({ type: 'notme', index: index });
      } else if (_.contains(recurring_fields, element.name) && !(element.name === 'SelectedFrequency' && element.type === 'radios')) {
        if (element.name === 'IsRecurring') {
          element.title = this.def.children[index + 1].title;
        } else if (element.name === 'SelectedFrequency') {
          element.oldTitle = element.title;
          element.title = this.resources.PrimaryResources.FrequencyTitle;
        } else if (element.name === 'SelectedDuration') {
          element.options = _.map(element.options, function (option, i) {
            if (i) option.display = this.resources.fill(this.resources.PrimaryResources.SelectedDurationTitle, option.display);
            return option;
          }, this);
          element.title = this.resources.PrimaryResources.DurationTitle;
        } else if (element.name === 'EndDate') {
          element.title = this.resources.PrimaryResources.RecurringEndDateTitle;
        }
        recurring.push({ type: element.type, index: index });
      } else if (_.contains(after_recurring_fields, element.name)) {
        context.afterRecurring.push({ type: element.type, index: index });
      } else {
        context.children.push({ type: element.type, index: index });
      }
    }, this);

    _.each(recurring, function (recurr) {
      context.recurring.push(recurr);
    });
    return invokeCallbacks('alterContext', { element: 'fieldset', context: context, def: this.def }).context;
  },
  errors: function () {
    var val = this.val();
    var recurring = _(recurring_fields.slice());
    var subviews = _(this.subviews).chain();
    // Don't check for errors in recurring fields if recurring payments aren't enabled.
    if (!val.IsRecurring) {
      subviews = subviews.reject(function (subview) {
        return recurring.contains(subview.name);
      });
    }
    var errors = subviews.invoke('errors').flatten().compact().value();
    return invokeCallbacks('alterErrors', {
      val: val,
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors;
  },
  focus: function () {
    if (this.subviews.SelectAmount) {
      this.subviews.SelectAmount.focus();
    } else if (this.subviews.SuggestedAmount) {
      this.subviews.SuggestedAmount.focus();
    }

    return this;
  },
  val: function () {
    var val = FieldsetView.prototype.val.call(this);

    // ignore the subview value in favor of the model's value
    val.IsRecurring = this.contributionRecurrenceModel.get('isRecurring');

    if (!val.IsRecurring) {
      val.SelectedFrequency = val.SelectedFrequency ? 0 : val.SelectedFrequency;
      delete val.SelectedDuration;
      delete val.EndDate;
    }
    return val;
  },
  setval: function (values) { // TODO: This is identical to the method on fieldset. Fix! - Bjorn
    var self = this;
    _.each(this.subviews, function (subview) {
      if (!_.isObject(subview.val())) {
        _.each(values, function (value, key) {
          if (subview.field_name() === key) {
            subview.setval(value);
          }
          if (key === 'IsRecurring') {
            self.contributionRecurrenceModel.setIsRecurring(value);
          }
          if (key === 'SelectedFrequency') {
            self.contributionRecurrenceModel.setFrequency(value);
          }
        });
      } else {
        if (_.isFunction(subview.setval)) {
          subview.setval(values);
        }
      }
    });
    return this;
  },
  _onSubviewsRendered: function () {
    var endDate = this.subviews.EndDate;
    var frequency = this.subviews.SelectedFrequency;
    var duration = this.subviews.SelectedDuration;
    var isRecurringElem = this.subviews.IsRecurring;
    var self = this;
    function isRecurring() {
      return self.contributionRecurrenceModel.get('isRecurring');
    }
    if (endDate) {
      var oldErrorsFn = endDate.errors;
      endDate.errors = _.bind(function () {
        var errors = oldErrorsFn.call(this) || [];
        var val = this.val();
        if (!errors.length && isRecurring() && duration.val() === 4) {
          if (moment(new Date(val)).utc().diff(moment().utc(), 'days') < 0) {
            errors.push(new FormError(this.resources.PrimaryResources.SpecifyEndDateInFuture, this));
          }
        }
        return errors;
      }, endDate);
    }

    if (frequency && frequency.def.oldTitle && (!isRecurringElem || (isRecurringElem && isRecurringElem.def.type === 'hidden'))) {
      this.$('.at-recurring').prepend('<label class="IsRecurring"><span>' + frequency.def.oldTitle + '</span></label>');
    }
    if (frequency) {
      frequency.errors = _.bind(function () {
        var errors = FieldView.prototype.errors.call(this);
        if (isRecurring() && !this.val()) {
          errors.push(new FormError(this.resources.PrimaryResources.SelectFrequencyForRecurring, this));
        }
        return errors;
      }, frequency);
    }

    if (duration) {
      duration.errors = _.bind(function () {
        var errors = FieldView.prototype.errors.call(this);
        if (isRecurring() && !this.val()) {
          errors.push(new FormError(this.resources.PrimaryResources.SelectDurationForRecurring, this));
        }
        return errors;
      }, duration);
    }

    this.updateRecurringSection();

    return this.recurringCheckbox();
  },
  recurringCheckbox: function (event) {
    // *sigh* magic number indicates "this date" option
    var duration = this.subviews.SelectedDuration;
    var val = event ? parseInt(event.currentTarget.value, 10) : (duration ? duration.val() : null);
    this.$('.at-date').css({
      'display': (val === 4 ? 'inline-block' : 'none'),
      'vertical-align': 'top'
    });
    return this;
  },
  recurringChecked: function () {
    var val = this.subviews.IsRecurring.val();
    this.contributionRecurrenceModel.setIsRecurring(val);
  }
});
