import $ from 'lib/jquery.ui';
import _ from 'lib/lodash.custom';

var render = function (self) {
  var def = self.def;

  if (def.other) {
    // 'Other' text field may not be rendered yet, so do this in next event loop
    _.defer(function () {
      // Find the label (parent of text field) associated with 'other'
      // Hide it
      var target = '.at-text.' + def.other.name;
      self.$other = $(target);

      // Multi-select dropdowns have the 'other' checkbox appear under the 'other' checkbox option
      // so, skip the DOM manipulations for multi-select
      if (self.def.type !== 'select') {
        var otherFieldSet = self.$other.parent();
        var otherParent = self.$el.parent();

        // radio buttons are special
        if (self.type === 'radios') {
          // radio options are nested within label elements, we want to append it to the same element
          var $option = $('input[value=\'Other\'][name=\'' + self.name + '\']');
          otherParent = $option.parent();
        }

        // In some cases, the default DOM layout of the 'other' text field
        // needs to be modified (so that it lies closer to the related input element)
        var tmp = self.$other.detach();
        otherParent.append(tmp);
        otherFieldSet.remove();
      }
      // Hide it now.  If setval gets called (because query strings are passed in, other may get shown later)
      self.$other.hide();

    });
  }
};

function isSelected(view) {
  var res = false;

  switch (view.type) {
    case 'radios':
      res = (view.val() === 'Other');
      break;
    case 'checkbox':
      res = view.val();
      break;
    case 'select':
      // Works for both single and multi select
      res = (view.val() !== null && (view.val().split('||').filter(function (item) { return item === 'Other' }).length > 0));
      break;
    default:
      break;
  }
  return res;
}

var showHide = function (self) {
  // The related $other textfield may not be rendered yet, so do
  // this in a deferred manner.  This can happen if query strings are passed
  // for the 'other' option, which leads to setval being called on dropdown before $other is rendered.
  _.defer(function () {
    if (self.def.other && self.$other && isSelected(self)) {
      if (self.def.type !== 'select') {
        self.$el.parent().addClass('other-option');
      }
      self.$other.show();
    } else if (self.$other) {
      self.$el.parent().removeClass('other-option');
      self.$other.hide();
    }
  });
};
export default {
  showHide: showHide,
  render: render
};
