import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import _ from 'lib/lodash.custom';
// Backbone was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'lib/backbone';

function _openTwitterPopup(url) {
  // try to center popup
  // take dual screens into account
  var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
  var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

  var width = window.innerWidth ?
    window.innerWidth : document.documentElement.clientWidth ?
      document.documentElement.clientWidth : screen.width;
  var height = window.innerHeight ?
    window.innerHeight : document.documentElement.clientHeight ?
      document.documentElement.clientHeight : screen.height;

  var popupWidth = 500;
  var popupHeight = 500;
  var left = ((width / 2) - (popupWidth / 2)) + dualScreenLeft;
  var top = ((height / 2) - (popupHeight / 2)) + dualScreenTop;

  var popup = window.open(url,
    'ModalPopUp',
    'toolbar=no,' +
    'scrollbars=no,' +
    'location=no,' +
    'statusbar=no,' +
    'menubar=no,' +
    'resizable=0,' +
    'width=' + popupWidth + ', ' +
    'height=' + popupHeight + ', ' +
    'left=' + left + ', ' +
    'top=' + top);

  return popup;
}

export default FieldView.extend({
  __name__: 'AdvocacyTweetView',
  type: 'advocacy_tweet',
  className: 'at-advocacy-tweet clearfix',
  events: {
    'click button.at-tweet-button': 'showPopup',
    'click a.at-show-tweet': 'showTweet'
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    this.template = this.options.templates.advocacy_tweet;
  },
  context: function () {
    var def = this.def;
    var context = {
      img: def.img,
      name: def.name,
      meta: def.meta,
      title: 'Tweet ' + def.name,
      default_value: def.message,
      maxlength: 280,
      required: 'required',
      hasTwitter: def.hasTwitter,
      readonly: (def.readonly || '') && 'readonly disabled'
    };

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    var context = this.context();
    this.$el.html(this.template(context));
    this.$textarea = this.$('.at-tweet-message');
    this.$label = this.$('.at-tweet-label');
    this.$button = this.$('.at-tweet-button');
    //Bind to changes
    this.$textarea.bind('input propertychange', _.debounce(this.renderFeedback, 150));
    return this;
  },
  val: function () {
    return this.$textarea.val() || null;
  },
  showPopup: function (e) {
    e.preventDefault();
    var text = this.$textarea.val();
    var url = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(text);
    var popup = _openTwitterPopup(url);

    var self = this;

    var pollTimer = window.setInterval(function () {
      if (popup.closed !== false) { // !== is required for compatibility with Opera
        window.clearInterval(pollTimer);
        self.popupClosed();
      }
    }, 200);

    var tweet = {
      msg: this.$textarea.val(),
      bioId: this.def.bioId
    };
    this.trigger('tweetOpened', tweet);

  },
  popupClosed: function () {
    var $closedMsg = this.$('.at-tweet-closed-msg');
    var $tweetMsg = this.$('.at-tweet-area');
    $tweetMsg.hide();
    $closedMsg.show();
  },
  showTweet: function (e) {
    e.preventDefault();
    var $closedMsg = this.$('.at-tweet-closed-msg');
    var $tweetMsg = this.$('.at-tweet-area');
    $tweetMsg.show();
    $closedMsg.hide();
  },
  renderFeedback: function () {
    if (!this.$textarea.val()) {
      this.renderFeedbackWithErrors('Tweet is required.');
    } else {
      this.clearFeedback();
    }
  },
  error_class: 'error',
  clearFeedback: function () {
    this.$('small.' + this.error_class).remove();
    this.$textarea.removeClass('noValue');
    this.$label.removeClass(this.error_class);
    this.$button.prop('disabled', false);
    return this;
  },
  renderFeedbackWithErrors: function (error) {
    this.$label.addClass(this.error_class);
    this.$textarea.addClass('noValue');
    this.$label.find('small.' + this.error_class).remove();
    this.$label.append(this.error_template({ 'error_text': error, 'behavior_classes': this.error_class }));
    this.$button.prop('disabled', true);
    return error;
  }
});
