import DoubleBracket from 'app/DoubleBracket';
import FieldView from 'app/views/FieldView';
import parseUrl from 'lib/parseUrl';
import $ from 'lib/jquery';
import deviceInfo from 'app/deviceInfo';

export default FieldView.extend({
  __name__: 'MarkupView',
  type: 'markup',
  tagName: 'div',
  className: function () {
    var classes = [
      'at-markup',
      this.options.definition.name
    ];
    var legal = ['SmsLegalDisclaimer'];
    if (legal.indexOf(this.options.definition.name) !== -1) {
      classes.push('at-legal');
    }
    return classes.join(' ');
  },
  render: function () {
    if (this.options.definition.markup) {
      // render the tracking pixel html but hide the div to avoid collision with other elements
      if (this.options.definition.name === 'TrackingPixel') {

        // append formSessionId query string to the tracking request (and other metadata)
        // prevent browser from preloading the src: https://stackoverflow.com/questions/15113910/jquery-parse-html-without-loading-images
        var virtualDoc = document.implementation.createHTMLDocument('Virtual Doc');
        var domNode = $.parseHTML(DoubleBracket.translate(this.options.definition.markup), virtualDoc)[0];

        // Get a Base64-encoded representation of the first 500 characters of the current window location
        var encodedUrl;
        try {
          if (window.location && window.location.href) {
            var truncatedUrl = window.location.href.substring(0, 500);
            encodedUrl = encodeURIComponent(btoa(truncatedUrl));
          }
        } catch (err) {
          encodedUrl = null;
        }

        // Get a Base64-encoded representation of the first 500 characters of the referrer
        var encodedReferrer;
        try {
          if (document.referrer) {
            var truncatedReferrer = document.referrer.substring(0, 500);
            encodedReferrer = encodeURIComponent(btoa(truncatedReferrer));
          }
        } catch (err) {
          encodedReferrer = null;
        }

        if (domNode.src && this.options.formview && this.options.formview.formSessionId) {
          var url = parseUrl(domNode.src);
          var queryString = (url.query ? '&' : '?') +
            'formSessionId=' + this.options.formview.formSessionId +
            '&bName=' + (deviceInfo.browser || '') +
            '&dType=' + (deviceInfo.deviceType || '');


          var formDef = this.options.formview.form_definition();
          // We expect form version to always be here, but it's possible it's not
          if (!!formDef.formVersion) {
            queryString += '&formVersion=' + formDef.formVersion;
          }
          // embed form optimization experiment variant id here if we have one
          if (!!formDef.experiment && !!formDef.experiment.chosenVariantId) {
            queryString += '&experimentVariantId=' + formDef.experiment.chosenVariantId;
          }


          var fUrlParam = '&fUrl=' + (encodedUrl || '');
          var fRefParam = '&fRef=' + (encodedReferrer || '');

          // Make sure we don't create a url that would exceed max lengths
          if (domNode.src.length + queryString.length + fUrlParam.length < 2000) {
            queryString += fUrlParam;
          }

          if (domNode.src.length + queryString.length + fRefParam.length < 2000) {
            queryString += fRefParam;
          }

          domNode.src += queryString;
        }

        this.$el.html(domNode.outerHTML).hide();
      }
      else {
        this.$el.html(DoubleBracket.translate(this.options.definition.markup)).show();
      }
    } else {
      this.$el.hide();
    }
  }
});
