import Backbone from 'lib/backbone';
import FieldsetView from 'app/views/FieldsetView';
import invokeCallbacks from 'app/NVTagCallbacks';

var _ = Backbone._;

export default FieldsetView.extend({
  __name__: 'TributeGiftView',
  type: 'tribute_gift',
  events: {
    'click [name="EnableTributeGift"]': 'toggleTributeGift'
  },
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);

    var enableTributeGiftVal = this.getEnableTributeGiftFromDef();
    this._enableTributeGift = (enableTributeGiftVal &&
      (enableTributeGiftVal.default_value || enableTributeGiftVal.value));

    this.template = this.options.templates.tribute_gift;

    // listen to fill event from NVFormView
    this.listenTo(this.options.parent, 'fill', this.postFill);
  },
  context: function () {
    var context = {
      id: this.name.toLowerCase(),
      children: [],
      giftChildren: []
    };

    // get ready for some crazy..
    // re-order the In memory of/In honor of radio buttons so that 'In honor of' is displayed first
    // and 'In memory of' is displayed last. since radio options is an arb object, we can't use that
    // to guarantee order
    var inHonorMemoryOfField = _.findWhere(this.def.children, { name: 'InHonorOrInMemoryOf' });
    if (inHonorMemoryOfField && inHonorMemoryOfField.options && inHonorMemoryOfField.options.length) {
      // normalize the options
      var normalizedOpts = _.map(inHonorMemoryOfField.options, function (option) {
        return { value: _.keys(option)[0], display: _.values(option)[0] };
      });

      // order the normalized options
      var inHonorOf = _.findWhere(normalizedOpts, { value: '2' });
      var inMemoryOf = _.findWhere(normalizedOpts, { value: '1' });

      if (inHonorOf && inMemoryOf) {
        // remap so that the radios view and display it properly
        inHonorMemoryOfField.options = _.map([inHonorOf, inMemoryOf], function (btn) {
          var opt = {};
          opt[btn.value] = btn.display;
          return opt;
        });
      }
    }

    // add the children to the hbs template
    // display the enable tribute gift checkbox outside of the optional 'giftChidren' area
    // so that we can toggle the gift area on and off
    _.each(this.def.children, function (element, index) {
      if (element.name === 'EnableTributeGift') {
        context.children.push({ type: element.type, index: index, name: element.name });
      } else {
        context.giftChildren.push({ type: element.type, index: index, name: element.name });
      }
    });

    return invokeCallbacks('alterContext', { context: context }).context;
  },
  render: function () {
    this.$el.html(this.template(this.context()));

    // omg this is a hack. remove the border for the contribution and premium views
    var contributionElem = this.parent.$('.ContributionInformation');
    if (contributionElem) {
      contributionElem.css('border', 'none');
    }
    var premiums = this.parent.$('.Premiums');
    if (premiums) {
      premiums.css('border', 'none');
    }

    this.displayGiftOptions(this._enableTributeGift);
    return this;
  },
  errors: function () {
    // if we're hidden, ignore errors
    return (this.$('.at-tribute-gift').is(':visible')) ?
      FieldsetView.prototype.errors.call(this) : [];
  },
  renderFeedback: function () {
    // if we're hidden, ignore errors
    return (this.$('.at-tribute-gift').is(':visible')) ?
      FieldsetView.prototype.renderFeedback.call(this) : [];
  },
  getEnableTributeGiftFromDef: function () {
    return _.find(this.def.children, { name: 'EnableTributeGift' });
  },
  toggleTributeGift: function (event) {
    this.displayGiftOptions(event.currentTarget.checked);
  },
  displayGiftOptions: function (show) {
    if (show) {
      this.$('.at-tribute-gift').show();
    } else {
      this.$('.at-tribute-gift').hide();
      this.clearFeedback();
    }
    this.displayRecipientInfoView(show);
  },
  displayRecipientInfoView: function (show) {
    var recipientInfo = this.parent.$('.RecipientInformation');
    var recipientInfoView = this.parent.subviews.RecipientInformation;
    if (!recipientInfo || !recipientInfoView) {
      return;
    }
    if (show) {
      // show recipient info and remove border
      recipientInfo.show();
      this.$el.addClass('at-fieldset-noborder');
    } else {
      recipientInfoView.clearFeedback();

      // hide recipient info and reshow border
      recipientInfo.hide();
      this.$el.removeClass('at-fieldset-noborder');
    }
  },
  postFill: function (args) {
    this._enableTributeGift = (args && args.EnableTributeGift);
    this.displayGiftOptions(this._enableTributeGift);
  }
});
