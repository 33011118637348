// Provide both the container for the errors (ErrorsView) and the individual
// errors themselves (ErrorView)

import Backbone from 'lib/backbone';
import FormError from 'app/FormError';
import templates from 'generated/compiled_handlebars_templates';

var _ = Backbone._;

export default {
  errors_view: Backbone.View.extend({
    __name__: 'InlineErrorView',
    template: templates.errors,
    className: 'at-error-console',
    events: {
      'click a.at-dismiss': 'close',
      'click .at-error': 'focusError'
    },
    renderFeedback: function (feedback) {
      this.form_errors = feedback || this.options.formview.errors();
      if (!_.isArray(this.form_errors)) {
        /* jshint -W109 */
        console.warn("Errors isn't array! Errors is: " + JSON.stringify(this.form_errors));
        /* jshint +W109 */
        this.form_errors = [];
      }

      if (this.server_errors.length) {
        this.form_errors = _.uniq(this.form_errors.concat(this.server_errors));
      }

      _.each(this.form_errors, function (error, index) { // jshint ignore:line
        if (_.isObject(error) && error.field) {
          this.listenToOnce(error.field, 'valid', function () {
            var errorIndex = _.indexOf(this.form_errors, error);
            if (errorIndex !== -1) {
              this.form_errors.splice(errorIndex, 1);
              this.$('.at-error').eq(errorIndex).remove();
            }
            this.handleMultistep();
          });
          this.listeningTo.push(error.field);
        }
      }, this);

      this.render().focusError();

      return this.errors();
    },
    clearFeedback: function () {
      return this.clear();
    },
    focusError: function (e) {
      if (e) {
        e.preventDefault();
      }

      var errorIndex = e ? this.$(e.currentTarget).index() : 0;

      if (_.isObject(this.form_errors[errorIndex]) && this.form_errors[errorIndex].field) {
        var field = this.form_errors[errorIndex].field;

        var stepIdx = 0;
        // find the correct step and set that step
        if (field.name === 'PaymentInformation') {
          stepIdx = field.def && field.def.step || 0;
        } else {
          stepIdx = field.parent && field.parent.def && field.parent.def.step || 0;
        }

        if (this.options.formview.multistep) {
          if (stepIdx) {
            this.options.formview.setStep(stepIdx);
          } else if (field.parent.name) {
            this.options.formview.setStepByName(field.parent.name);
          }
        }

        field.focus();
      }
      // some error divs don't come with fields (like validation errors).
      // making sure this is an else if so it doesn't get in the way of the cc focus
      else if (this.form_errors && this.form_errors.length > 0) {
        var alerts = this.$('.at-alert.at-error');

        alerts[0].focus();
      }

      return this;
    },
    close: function (e) {
      if (e) {
        e.preventDefault();
      }
      var el = this.$(e.currentTarget).parent();
      var errorIndex = el.index();
      var error = this.form_errors[errorIndex];
      if (_.isObject(error) && error.field) {
        var listenIndex = _.indexOf(this.listeningTo, error.field);
        var field = this.listeningTo[listenIndex];
        this.stopListening(field);
        this.listeningTo.splice(listenIndex, 1);
        if (this.listeningTo.length) {
          this.listeningTo[0].focus();
        }
        field.$(':input.error').removeClass('error noValue');
      }
      this.form_errors.splice(errorIndex, 1);
      el.remove();
      return this.handleMultistep();
    },
    handleMultistep: function () {
      if (this.options.formview.multistep) {
        if (this.form_errors.length === 0) {
          this.options.formview.$('.at-step.active.invalid').removeClass('invalid valid');
          this.options.server_errors = [];
        }
      }
      return this;
    },
    clear: function () {
      this.$el.empty();
      this.stopListeningToErrors();
      this.form_errors = [];
      this.server_errors = [];
      return this;
    },
    val: function () {
      return {};
    }, // Hack. This isn't a form element, but we assume all subviews are.
    errors: function () {
      return [];
    }, // Hack. This isn't a form element, but we assume all subviews are.
    setFullWidthError: function (errors) {
      // display errors across the page
      if (errors && errors.length) {
        this.$el.html(this.template({ messages: errors }));
      }
    },
    setServerErrors: function (server_errors) {
      this.server_errors = _.reduce(server_errors, function (memo, error) {
        var props = _(error.properties).without('customerKey', 'ccInfoKey');
        if (props.size() === 0) {
          return memo;
        }

        var fieldView = null;
        var fieldName = null;
        var isPaymentError = false;
        props.each(function (prop) {
          fieldName = prop
            .replace(/\.Value$/, '')
            .replace(/DirectConnectSpecific/, 'Account')
            .replace(/Expiration(Month|Year)/, 'ExpirationDate');

          isPaymentError = isPaymentError || fieldName === 'Account'
            || fieldName === 'ExpirationDate' || fieldName === 'PaymentInformation';

          fieldView = this.options.formview.getField(fieldName);

          // break loop if field is found
          if (fieldView) {
            return false;
          }
        }, this);

        if (isPaymentError) {
          this.options.formview.$('.change-account').trigger('click');
        }

        var accountIndex = props.indexOf('DirectConnectSpecific');
        if (accountIndex >= 0) {
          var temp = props.value();
          temp[accountIndex] = 'Account';
          props = _(temp);
        }

        if (props.size() && error.text) {
          if (props.contains('Account')) {
            error.text = error.text.replace(/^Account/i, 'Credit Card Number');
          } else if (props.contains('ExpirationMonth')) {
            error.text = error.text.replace(/month/i, 'Month');
          } else if (props.contains('ExpirationYear')) {
            error.text = error.text.replace(/year/i, 'Year');
          } else {
            error.text = error.text;
          }

          if (fieldView) {
            var fieldError = new FormError(error.text, fieldView);
            fieldView.renderFeedbackWithErrors([fieldError]);
            memo.push(fieldError);
          } else {
            memo.push(error.text);
          }
        }

        if (isPaymentError && fieldView) {
          if (fieldView.parent && fieldView.parent.def) {
            fieldView.parent.def.required = true;
          }

          if (fieldView.def) {
            fieldView.def.required = true;
          }
        }

        return memo;
      }, [], this);
      return this;
    },
    form_errors: [],
    server_errors: [],
    listeningTo: [],
    stopListeningToErrors: function () {
      function stopListeningToError(field) {
        /*jshint -W040 */
        this.stopListening(field);
        /*jshint +W040 */
      }

      _.each(this.listeningTo, stopListeningToError, this);
      this.listeningTo = [];
      return this;
    },
    render: function () {
      // If inline errors are enabled, don't vomit the errors to the top.
      if (this.options.formview.options.parent_tag.options.inline_error_display !== 'true' ||
        this.server_errors.length > 0) {
        this.$el.html(this.template({ messages: this.form_errors }));
      }
      return this;
    }
  })
};
