import _ from 'lib/lodash.custom';
import formRows from 'data/form.rows';

// viewName - name of the view the rows are in. corresponds to keys in form.rows.js
// contextChildren - children from view context
// formatDef - optional - custom formatting not in form.rows.js
function formatRows(viewName, contextChildren, formatDef) {
  var rows = formatDef || formRows[viewName];
  var child = _.indexBy(contextChildren, 'name');

  var formattedRows = _.reduce(rows, function (group, row) {
    var r = _.reduce(row, function (memo, name) {
      if (child[name]) {
        memo.push(child[name]);
        child[name].inRow = true;
        if (memo.classes) {
          memo.classes.push(name);
        } else {
          memo.classes = [name];
        }
        if (name === 'SmsLegalDisclaimer' && child.SmsSubscribeMobilePhone &&
          child.SmsSubscribeMobilePhone.type !== 'hidden') {
          // When the SMS opt-in checkbox is visible, then the SMS legal disclaimer needs to be styled differently.
          memo.classes.push('at-indented');
        }
      }
      return memo;
    }, []);
    if (r.length) {
      if (r.length === 1) {
        if (/^(markup|checkbox)/.test(r[0].type)) {
          r.classes.unshift('at-row-full');
        }
        r.classes.unshift('at-row-solo');
      }

      // if every member of a row requires a particular mode
      // also apply the mode-restriction CSS class to the row itself
      // to show/hide the entire row based on the active mode
      var contactModes = _.countBy(r, 'contactMode');
      if (contactModes.org === r.length) {
        r.classes.push('at-mode-org-only');
      } else if (contactModes.person === r.length) {
        r.classes.push('at-mode-person-only');
      }

      r.classes = r.classes.join(' ');
      group.push(r);
    }
    return group;
  }, []);

  return formattedRows;
}

export default {
  format: formatRows
};
