import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import TextFieldView from 'app/views/TextFieldView';
import TextAreaView from 'app/views/TextAreaView';
import MarkupView from 'app/views/MarkupView';
import CheckboxView from 'app/views/CheckboxView';
import SelectView from 'app/views/SelectView';
import RadiosView from 'app/views/RadiosView';
import AmountView from 'app/views/AmountView';
import DateView from 'app/views/DateView';
import NotMeView from 'app/views/NotMeView';
import TicketHolderView from 'app/views/TicketHolderView';
import TicketingLevelView from 'app/views/TicketingLevelView';
import CreditCardView from 'app/views/CreditCardView';
import HiddenView from 'app/views/HiddenView';
import CVV2View from 'app/views/CVV2View';
import CCExpirationView from 'app/views/CCExpirationView';
import TargetsView from 'app/views/TargetsView';
import TargetSelectView from 'app/views/TargetSelectView';
import AdvocacyTweetView from 'app/views/AdvocacyTweetView';
import ReCaptchaView from 'app/views/ReCaptchaView';
import ActivistCodeQuestionView from 'app/views/ActivistCodeQuestionView';
import CheckboxListManagerView from 'app/views/CheckboxListManagerView';
import EcardsView from 'app/views/EcardsView';
import PremiumGiftsView from 'app/views/PremiumGiftsView';
import TimeView from 'app/views/TimeView';
import occupations from 'data/occupations';
import formRows from 'data/form.rows';
import prefixes from 'data/prefixes';
import formatRows from 'app/views/helpers/formatRows';
import InternationalPhoneView from 'app/views/InternationalPhoneView';
import CoverCostsCheckboxView from 'app/views/CoverCostsCheckboxView';
import SignaturePadView from 'app/views/SignaturePadView';
import PaymentMethodView from 'app/views/PaymentMethodView';
import FormDefUtils from 'app/FormDefUtils';
import currency from 'app/views/helpers/currency';
import MatchProView from 'app/views/MatchProView';
import EmployerOccupationRetiredCheckboxView from 'app/views/EmployerOccupationRetiredCheckboxView';

var _ = Backbone._;
var $ = Backbone.$;
var FieldsetView = FieldView.extend({
  __name__: 'FieldsetView',
  tagName: 'fieldset',
  type: 'fieldset',
  events: {},
  className: function () { return 'at-fieldset ' + this.options.definition.name; },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    Backbone.Subviews.add(this);
    _.bindAll(this, 'subviewOptions', 'hideOrShow');

    // The employer matching section will be hidden (and inactive) in several scenarios
    if (this.name === 'EmployerMatching') {
      this.listenTo(this.options.formview, 'contactModeChanged', this.hideOrShow);
      this.listenTo(this.options.formview.contributionRecurrenceModel, 'change:frequency', this.hideOrShow);
      this.listenTo(currency, 'change', this.hideOrShow);
    }
  },
  _onSubviewsRendered: function () {
    if (this.name === 'EmployerInformation' ||
      (this.name === 'ContactInformation' && this.options.formview.multistep)) {
      if (this.subviews.Employer || this.subviews.Occupation) {
        var special = [
          this.resources.PrimaryResources.Retired,
          this.resources.PrimaryResources.Unemployed,
          this.resources.PrimaryResources.Student,
          this.resources.PrimaryResources.NotEmployed,
          this.resources.PrimaryResources.SelfEmployed
        ];
        // if the EmployerOccupationRetiredCheckbox is present, we don't want "Retired" to be
        // a suggested value for the Employer or Occupation fields
        if (this.subviews.EmployerOccupationIsRetiredCheckbox) {
          special = _.without(special, this.resources.PrimaryResources.Retired);
        }
        if (!document.getElementById('at-occupations')) {
          this.$el.append(this.options.templates.datalist({
            id: 'at-occupations',
            options: _.uniq(this.resources.sort(occupations.translatedOccupations(this.resources).concat(special)))
          })).append(this.options.templates.datalist({
            id: 'at-employers',
            options: _.uniq(this.resources.sort(special))
          }));
        }
        if (this.subviews.Occupation) {
          var self = this;
          this.subviews.Occupation.$('input').attr('list', 'at-occupations');
          if (self.subviews.Employer) {
            this.subviews.Occupation.$('input').on('change', function (e) {
              if (_.contains(special, e.currentTarget.value)) {
                self.subviews.Employer.setval(e.currentTarget.value).clearFeedback();
              }
            });
          }
        }
        if (this.subviews.Employer) {
          this.subviews.Employer.$('input').attr('list', 'at-employers');
        }
      }
    }
    if (this.name === 'ExtraInformation') {
      //force focus to the step section of page
      $('html,body').scrollTop(this.parent.$('form').offset().top);
    }
    if (this.subviews.Prefix) {
      if (!document.getElementById('at-prefixes')) {
        this.$el.append(this.options.templates.datalist({
          id: 'at-prefixes',
          options: this.resources.sort(_.uniq(prefixes.translatedPrefixes(this.resources)))
        }));
      }
      if (this.subviews.Prefix) {
        this.subviews.Prefix.$('input').attr('list', 'at-prefixes');
      }
    }
  },
  context: function () {
    var form = this.options.formview,
      context = {
        id: this.name.toLowerCase(),
        title: this.title,
        subTitle: this.options.definition.subTitle || '',
        children: []
      };
    _.each(this.def.children, function (element, index) {
      var child = {
        type: element.type,
        index: index,
        name: element.name,
        contactMode: element.contactMode
      };
      if (element.name === 'SelectAmount') {
        child.type = 'amount';
        context.children.push(child);
      } else if (element.name === 'ConfirmIdentityHtml') {
        child.type = 'notme';
        context.children.push(child);
      } else if (element.name === 'EligibilityConfirmationHtml' || element.name === 'ConfirmedEligibility') {
        if (form.options.form_definition.metadata.hideCompletedFormSections) {
          if (form.options.form_definition.metadata.displayConfirmationMessage) {
            context.children.push(child);
          }
        } else {
          context.children.push(child);
        }
      } else {
        context.children.push(child);
      }
    }, this);

    if (formRows[this.name]) {
      var rows = formRows[this.name];
      if (this.name === 'ContactInformation' && form.multistep) {
        rows = rows.concat(formRows.EmployerInformation);
      }
      var child = _.indexBy(context.children, 'name');
      context.rows = formatRows.format(this.name, context.children, rows);
      var extra = _.reject(child, { inRow: true });
      if (extra.length) {
        for (var i = 0; i < extra.length; i++) {
          context.rows.push([extra[i]]);
        }
      }
    }

    return invokeCallbacks('alterContext', { element: 'fieldset', context: context, def: this.def }).context;
  },
  render: function () {
    this.$el.html(this.template(this.context()));
    return this;
  },
  renderFeedback: function (e) {
    if (e) {
      e.stopPropagation();
    }
    return _(this.subviews).filter(function (subview) {
      // skip errors for inactive subviews
      return subview.isActive();
    }).invoke('renderFeedback').flatten().compact().value();
  },
  clearFeedback: function () {
    _.invoke(this.subviews, 'clearFeedback');
    return this;
  },
  createSubviewDefaults: function () {
    this.subviewDefaults = {
      parent: this,
      definition: this.options.definition,
      templates: this.options.templates,
      labels: this.options.labels,
      formview: this.options.formview
    };
    return this.subviewDefaults;
  },
  subviewOptions: function (opts) {
    var defaults = this.subviewDefaults || this.createSubviewDefaults();
    opts = _.isNumber(opts) ? { index: opts } : (opts || {});
    opts = _.extend(opts, defaults);
    if (_.isNumber(opts.index) && opts.index > -1) {
      opts.definition = opts.definition.children[opts.index];
      if (opts.definition.name) { opts.name = opts.definition.name; }
      delete opts.index;
    }
    return opts;
  },
  subviewCreators: {
    fieldset_view: function (index) { return new FieldsetView(this.subviewOptions(index)); },
    textfield_view: function (index) { return new TextFieldView(this.subviewOptions(index)); },
    textarea_view: function (index) { return new TextAreaView(this.subviewOptions(index)); },
    markup_view: function (index) { return new MarkupView(this.subviewOptions(index)); },
    signature_pad_view: function (index) { return new SignaturePadView(this.subviewOptions(index)); },
    checkbox_view: function (index) { return new CheckboxView(this.subviewOptions(index)); },
    select_view: function (index) {
      var def = this.options.definition.children[index];
      var View = def.target ? TargetSelectView : SelectView;
      return new View(this.subviewOptions(index));
    },
    'checkbox-list-manager_view': function (index) { return new CheckboxListManagerView(this.subviewOptions(index)); },
    activistCodeQuestion_view: function (index) { return new ActivistCodeQuestionView(this.subviewOptions(index)); },
    radios_view: function (index) { return new RadiosView(this.subviewOptions(index)); },
    hidden_view: function (index) { return new HiddenView(this.subviewOptions(index)); },

    // HTML5 special types
    email_view: function (index) {
      return new TextFieldView(this.subviewOptions({ index: index, type: 'email' }));
    },
    // maintain compatibility with Oberon phone fields
    phone_view: function (index) {
      return new TextFieldView(this.subviewOptions({ index: index, type: 'tel' }));
    },

    // Special case views
    matchpro_view: function (index) { return new MatchProView(this.subviewOptions(index)); },
    international_phone_view: function (index) { return new InternationalPhoneView(this.subviewOptions(index)); },
    gifts_view: function (index) { return new PremiumGiftsView(this.subviewOptions(index)); },
    ecard_view: function (index) { return new EcardsView(this.subviewOptions(index)); },
    recaptcha_grid_view: function (index) { return new ReCaptchaView(this.subviewOptions(index)); },
    advocacy_tweet_view: function (index) { return new AdvocacyTweetView(this.subviewOptions(index)); },
    targets_view: function (index) { return new TargetsView(this.subviewOptions(index)); },
    amount_view: function (index) { return new AmountView(this.subviewOptions(index)); },
    credit_card_view: function (index) { return new CreditCardView(this.subviewOptions(index)); },
    cc_expiration_view: function (index) { return new CCExpirationView(this.subviewOptions(index)); },
    cvv2_view: function (index) { return new CVV2View(this.subviewOptions(index)); },
    date_view: function (index) { return new DateView(this.subviewOptions(index)); },
    notme_view: function (index) { return new NotMeView(this.subviewOptions(index)); },
    ticket_holder_view: function (index, id) {
      // child is the index of this row in the ticket holders table
      // so if there are 3 of ticket level 1 and 2 of ticket level 2,
      // there are 5 total, we need to convert that index into the
      // ticket level, so we'll use this helper.
      return new TicketHolderView(this.subviewOptions({
        model: this.ticket_holders.get(id)
      }));
    },
    ticketing_level_view: function (index, id) {
      return new TicketingLevelView(this.subviewOptions({
        ticket_holders: this.ticket_holders,
        model: this.ticket_levels.get(id)
      }));
    },
    time_view: function (index) {
      return new TimeView(this.subviewOptions(index));
    },
    cover_costs_checkbox_view: function (index) { return new CoverCostsCheckboxView(this.subviewOptions(index)); },
    payment_method_view: function (index) {
      var formDef = this.options.formview.form_definition();

      var fields = _(FormDefUtils.fields(formDef.form_elements));
      var recurringFieldDef = fields.find({ name: 'IsRecurring' });
      var emailFieldDef = fields.find({ name: 'EmailAddress' });
      var homePhoneFieldDef = fields.find({ name: 'HomePhone' });
      var mobilePhoneFieldDef = fields.find({ name: 'MobilePhone' });

      var options = this.subviewOptions({
        index: index,
        name: 'PaymentMethod',
        cards: this.options.formview.paymentMethodConfiguration.acceptedCards,
        url: formDef.url,
        title: formDef.title,
        appleMerchantIdentifier: formDef.metadata.appleMerchantIdentifier,
        stripeAccountId: formDef.metadata.stripeAccountId,
        isRecurring: recurringFieldDef && recurringFieldDef.default_value,
        requireEmail: emailFieldDef && emailFieldDef.required,
        requirePhone: (homePhoneFieldDef && homePhoneFieldDef.required) || (mobilePhoneFieldDef && mobilePhoneFieldDef.required),
        alignment: this.options.formview.paymentMethodConfiguration.shouldFillFromPaymentResponse ? 'right' : 'left',
        designation: (formDef.designation || {}).officialName
      });

      if (index === -1) {
        // get definition from central definition if possible, otherwise use this one
        options.definition = {
          name: 'PaymentMethod',
          title: 'Payment Method',
          required: true
        };
      }

      return new PaymentMethodView(options);
    },
    employer_occupation_retired_checkbox_view: function (index) { return new EmployerOccupationRetiredCheckboxView(this.subviewOptions(index)) }
  },
  // ORIG
  errors: function () {
    var errors = _(this.subviews).chain().filter(function (subview) {
      // skip errors for inactive subviews
      return subview.isActive();
    }).invoke('errors').flatten().compact().value();
    return invokeCallbacks('alterError',
      { val: this.val(), field_name: this.field_name(), errors: errors, def: this.def }).errors;
  },
  getTouched: function () {
    return _.transform(this.subviews, function (touched, subview, name) {
      if (_.isFunction(subview.touched) && subview.touched()) {
        touched[name] = subview;
      }
    });
  },
  val: function () {
    var val = {};

    _.each(this.subviews, function (subview) {
      if (!subview.isActive()) {
        return; // skip inactive subviews
      }

      var subview_val = subview.val();

      if (_.isObject(subview_val)) {
        if ('other' in subview_val) { delete subview_val.other; }
        // If it's a dictionary, add it to this dictionary
        _.extend(val, subview_val);
      } else {
        // If just a value, add it to the dictionary, but don't add nulls
        // Note, we used to exclude false-y values but that proved a problem
        // because sometimes we want to explicitly pass a "false" value onto
        // Oberon. This should fix it. By convention, .val() should return
        // null if its value should not be submitted back to Oberon.
        if (subview_val !== null) {
          val[subview.name] = subview_val;
        }
      }
    });

    return val;
  },
  setval: function (values) {
    _.each(this.subviews, function (subview) {
      var multiFields = _.isFunction(subview.multiple_fields) && subview.multiple_fields();
      if (multiFields && multiFields.length > 0) {
        subview.setval(_.pick(values, multiFields));
      } else if (!_.isObject(subview.val())) {
        _.each(values, function (value, key) {
          if (subview.field_name() === key) {
            subview.setval(value);
          }
        });
      } else if (_.isFunction(subview.setval)) {
        subview.setval(values);
      }
    });

    return this.hideOrShow();
  },
  hideOrShow: function () {
    this.$el.toggle(!this.shouldHide());

    return this;
  },
  shouldHide: function () {
    var name = this.name, form = this.options.formview;

    // See if all required fields in a fieldset have a value. If so, hide it.
    var isMissingRequiredField = false;
    _.each(this.subviews, function (subview) {
      if (subview.isRequired() && subview.val() === null) {
        isMissingRequiredField = true;
      }
    });

    // Based on the form metadata setting "hideCompletedFormSections"
    var hideIfSectionCompleted = form.options.smart && !form.options.clickedNotMe
      && name !== 'ContributionInformation' && name !== 'TicketInformation';

    // Hide the employer matching section if its not active
    var hideEmployerMatching = (name === 'EmployerMatching' && !this.employerMatchingIsActive());

    return this.hide
      || (hideIfSectionCompleted && !isMissingRequiredField)
      // Only show if the fieldset is below the maximum visible index on the form
      // This is currently used for hiding fieldsets below payment methods before the payment method is selected
      || (form.lastVisibleFieldsetIndex && this.options.index > form.lastVisibleFieldsetIndex)
      || hideEmployerMatching;
  },
  employerMatchingIsActive: function () {
    // Check the three requirements to enable employer matching:
    // 1. A contribution will be processed as part of submission
    // 2. It is on behalf of an individual
    // 3. It is in USD

    // The contribution is immediate if its anything other than a quarterly or twice monthly contrib.
    var recurringFrequency = this.options.formview.contributionRecurrenceModel.get('frequency');

    var isImmediateContribution = recurringFrequency !== 5 && recurringFrequency !== 7;
    var isUsdContribution = currency.get('currency') === 'USD';
    var isIndividualContribution = this.options.formview.contactMode === 'person';

    return isImmediateContribution && isUsdContribution && isIndividualContribution;
  },
  isActive: function () {
    // Employer matching is active only if its visible
    return FieldView.prototype.isActive.apply(this)
      && (this.name !== 'EmployerMatching' || this.employerMatchingIsActive());
  }
});
export default FieldsetView;
