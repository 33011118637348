// Provide tooling for converting from Oberon's double bracket notation
//
//   {{FirstName, DefaultTo=Friend}}
//
//   becomes
//
//   <var class='ngp-variable' data-replaced='FirstName'>Friend</var>
//
//   and also to fill these var tags with the right value from a provided
//   dictionary. (we do this primarily so we can fill with Default immediately
//   then fill with appropriate value as soon as we can following that. If we
//   fill with just the default, (so "{{FirstName, DefaultTo=Friend}}" becomes
//   just "Friend" we've lost the double bracket doodad and can't unfill or fill
//   it with something else later. Using the appropriate markup element var.

import $ from 'lib/jquery.ui';
import _ from 'lib/lodash.custom';
import moment from 'lib/moment';

// Fill <var data-replaced="FirstName">Friend</var> with the value of FirstName
// from the supplied dict (if it exists)

function fillDoubleBracket(view, dict, lookups) {
  try {
    view.$('var.ngp-variable').each(function () {
      var $var = $(this);
      var data = $var.data();
      if (!$var.data('unescaped')) {
        data.fallback = _.unescape(data.fallback);
        $var.data('fallback', data.fallback)
          .data('unescaped', true);
      }
      if (dict.hasOwnProperty(data.key)) {
        var val = dict[data.key];
        // If the string contains "UTC" and moment can parse it, display it as a formatted time in the local timezone.
        if (val && (data.key.indexOf('Date') !== -1 || val.indexOf('UTC') !== -1 || val.indexOf('Z') === val.length - 1)) {
          var parsed_time = moment(val);
          $var.text(
            parsed_time.isValid()
              ? parsed_time.format('LL')
              : val
          );
        } else if (val.indexOf('||') >= 0) {
          if (data.key === 'Genders' && lookups && lookups.genders) {
            var ids = val.split('||');
            var values = _.map(ids, function (id) {
              var option = _.find(lookups.genders, { value: id });
              return option ? option.display : id;
            });
            $var.text(values.join(', '));
          } else {
            $var.text(val.replace('||', ', '));
          }
        } else {
          var option;
          if (data.key === 'Genders' && lookups && lookups.genders) {
            option = _.find(lookups.genders, { value: val });
            $var.text(option ? option.display : val);
          } else if (data.key === 'Race' && lookups && lookups.races) {
            option = _.find(lookups.races, { value: val });
            $var.text(option ? option.display : val);
          } else if (data.key === 'Pronoun' && lookups && lookups.pronouns) {
            option = _.find(lookups.pronouns, { value: val });
            $var.text(option ? option.display : val);
          } else {
            $var.text(val); // Otherwise, just replace it verbatim.
          }
        }
        $var.data('replaced', true);
      } else if (data.replaced) {
        $var.text(data.fallback).data('replaced', false);
      }
    });
  } catch (err) {
    console.log(err);
  }
}

// Convert {{FirstName,DefaultTo=Friend}} or {{FirstName or 'Friend'}} to
// <var data-key="FirstName" data-fallback="Friend">Friend</var>
// so it is filled but we can access it later to fill.
var merge = /{{\s*(\w+)(?:(?:,\s*DefaultTo=(.*?)}})|(?:(?:\sor\s'(.*?)')?)\s*}})/igm;
var squo = /\\'/g;
var dotValue = /\.Value,\s*DefaultTo=/gm;

function field(match, key, fall, back) {
  var fallback = (fall || back || '').replace(squo, '\'');
  return '<var class="ngp-variable" data-key="' + key + '" data-fallback="' + _.escape(fallback) + '">' + fallback + '</var>';
}

function translateDoubleBracket(markup) {
  try {
    return markup.replace(dotValue, ',DefaultTo=').replace(merge, field);
  } catch (e) {
    console.warn('Merge Field', e);
  }
}


function simpleFill(str, dict) {
  return str.replace(merge, function (match, key, fall, back) {
    var fallback = (fall || back || '').replace(squo, '\'');
    if (dict && dict.hasOwnProperty(key)) {
      var value = dict[key];
      value = value === 0 || value ? value : fallback;
      return value;
    }
    return fallback;
  });
}

export default {
  translate: translateDoubleBracket,
  fill: fillDoubleBracket,
  simpleFill: simpleFill
};
