import Backbone from 'lib/backbone';
import ATConfig from 'config/actionTagConfig';
import FastActionUser from 'app/models/FastActionUser';

var _ = Backbone._;

function getCookie(cookieName) {
  return document.cookie.split(';').some(function (c) {
    return c.trim().startsWith(cookieName + '=');
  });
}

function deleteCookie(cookieName, path) {
  if (getCookie(cookieName)) {
    var cookieString = cookieName + '=' + ((path) ? '; path=' + path : '') +
      '; domain=' + window.location.hostname +
      '; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    document.cookie = cookieString;
  }
}

function deletePerformanceCookies() {
  var cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    name = name.trim();
    if (name.startsWith('ai_')) {
      document.cookie = name + '=;path=/;domain=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    if (name.startsWith('_ga') || name.startsWith('_gid') || name.startsWith('_gtm') || name.startsWith('_gcl') || name.startsWith('__utm')) {
      deleteCookie(name, '/');
    }
  }
}

function deleteFunctionalCookies() {
  // These are no longer used anymore, but browsers will still have them.
  // Clean these up when the user revokes functional cookies.
  deleteCookie('ProfileDatabagId', '/');
  deleteCookie('FastActionDatabagId', '/');
}

var ConfigModel = Backbone.Model.extend({
  __name__: 'CookieConsentConfiguration',

  initialize: function () {
    this.set({
      'cookieConsentMode': ATConfig.DATABAG_COOKIE_CONSENT || false,
      'functionalAccepted': false,
      'performanceAccepted': false
    });

    _.bindAll(this, 'acceptPerformanceCookies', 'revokePerformanceCookies', 'acceptFunctionalCookies', 'revokeFunctionalCookies',
      'isCookieConsentEnabled');
  },

  isCookieConsentEnabled: function () {
    return this.get('cookieConsentMode');
  },

  areFunctionalCookiesAccepted: function () {
    return !this.get('cookieConsentMode') || this.get('functionalAccepted');
  },

  arePerformanceCookiesAccepted: function () {
    return !this.get('cookieConsentMode') || this.get('performanceAccepted');
  },

  acceptFunctionalCookies: function () {
    this.set({
      'functionalAccepted': true
    });
  },

  acceptPerformanceCookies: function () {
    this.set({
      'performanceAccepted': true
    });

    // Each of the following window functions are implemented in OA and called here
    if (window.cookieSettingsAcceptCookiesAi && !window.appInsights) {
      window.cookieSettingsAcceptCookiesAi();
    }
    else if (window.appInsights && window.appInsights.initialize && window.appInsights.getCookieMgr().isEnabled() === false) {
      //if the SDK is already loaded, the user revokes then accepts again:
      window.appInsights.getCookieMgr().setEnabled(true);
      window.appInsights.config.disableTelemetry = false;
    }

    if (window.cookieSettingsAcceptGoogleTagCookies) {
      window.cookieSettingsAcceptGoogleTagCookies();
    }

    if (window.cookieSettingsAcceptAnalyticsCookies) {
      window.cookieSettingsAcceptAnalyticsCookies();
    }
  },

  revokePerformanceCookies: function () {
    this.set({
      'performanceAccepted': false
    });

    if (window.appInsights) {
      window.appInsights.getCookieMgr().setEnabled(false);
      window.appInsights.config.disableTelemetry = true;
    }

    if (window.cookieSettingsRevokeAnalyticsCookies) {
      window.cookieSettingsRevokeAnalyticsCookies();
    }

    if (window.cookieSettingsRevokeGoogleTagCookies) {
      window.cookieSettingsRevokeGoogleTagCookies();
    }

    deletePerformanceCookies();
  },

  revokeFunctionalCookies: function () {
    // Delete Cookies
    deleteFunctionalCookies();

    // Logout and clear the FastAction user. This triggers a rerender of the FastActionView
    // and also clears the persisted FastActionId from localStorage and 1st party cookie
    FastActionUser.clear({ clearPersistedSessionId: true });

    this.set({
      'functionalAccepted': false
    });
  }
});

var cookieConsentConfigModel = new ConfigModel();

export default cookieConsentConfigModel;
