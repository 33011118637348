import Backbone from 'lib/backbone';
import FieldsetView from 'app/views/FieldsetView';
import 'lib/spin';

var _ = Backbone._;
var $ = Backbone.$;

function _getRecaptchaValues(view, idx) {
  var checked = '.at-captcha[data-idx="' + idx + '"] [data-choice].checked';
  var values = view.$(checked).map(function () {
    return $(this).data('choice');
  }).get().join(' ');

  return values;
}

function _displayRecaptchaError(view, idx, error) {
  var captcha = view.$('.at-captcha[data-idx="' + idx + '"]');
  var recaptcha = captcha.find('.at-recaptcha-table');
  if (recaptcha && error) {
    recaptcha.removeClass('error').find('.error').remove();
    recaptcha
      .addClass('error')
      .append('<small class="error">' + error + '</small>');
  }
}

function _toCaptchaError(idx, error, isSimpleCaptcha) {
  return { idx: idx, error: error, simpleCaptcha: isSimpleCaptcha };
}

export default FieldsetView.extend({
  __name__: 'CaptchaView',
  type: 'captcha',
  events: {
    'click .get-new-captcha-link': 'replaceCaptcha',
    'click .at-recaptcha > div': 'toggleCheck'
  },
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);
    this.def.children = _.reduce(this.def.captchas, function (children, def, idx) {
      var input = this.def.captchas[idx].input = {
        name: 'Captcha' + idx,
        title: 'Image Text',
        type: def.simpleCaptcha ? 'textfield' : 'recaptcha_grid',
        captcha: def,
        required: !!def.simpleCaptcha,
        maxlength: 20,
        index: idx
      };
      children.push(input);
      return children;
    }, [], this);
  },
  context: function () {
    var recipients = _.map(this.def.captchas, _.property('name'));
    if (recipients.length > 1) {
      var last = ' and ' + recipients.pop();
      recipients = recipients.join(', ') + last;
    } else {
      recipients = _.first(recipients);
    }

    return {
      title: recipients + ' would like to make sure you’re not a robot.',
      captchas: this.def.captchas
    };
  },
  _onSubviewsRendered: function () {
    _.each(this.def.captchas, function (def, idx) {
      var view = this.subviews['Captcha' + idx];
      if (view && def.simpleCaptcha) {
        if (def.error) {
          view.trigger('invalid');
          view.renderFeedbackWithErrors([view.toError(def.error)]);
        }
      } else {
        _displayRecaptchaError(this, idx, def.error);
      }
    }, this);

    //force focus to the step section of page
    $('html,body').scrollTop(this.parent.$('form').offset().top);
  },
  val: function () {
    return {
      Captchas: _.map(this.def.captchas, function (def, idx) {
        var val = {
          guid: def.guid,
          bioId: def.bioId,
          tries: def.tries,
          cookie: def.cookie,
          gridWidth: def.gridWidth,
          gridHeight: def.gridHeight
        };
        if (def.simpleCaptcha) {
          val.value = this.subviews['Captcha' + idx].val();
        } else {
          val.value = _getRecaptchaValues(this, idx);
        }
        return val;
      }, this)
    };
  },
  toggleCheck: function (e) {
    var recaptcha = $(e.currentTarget).toggleClass('checked').parent().parent().parent();
    recaptcha.removeClass('error').find('.error').remove();
  },
  replaceCaptcha: function (e) {
    e.preventDefault();
    var def = this.def;
    var $this = this.$(e.currentTarget).css('visibility', 'hidden');
    var $view = $this.closest('.at-captcha');
    var index = $view.data('idx');
    var $pin = $view.find('.spinner-wrapper');
    var $phrase = $view.find('.at-captcha-phrase');
    var captcha = def.captchas[index];
    var simple = !!captcha.simpleCaptcha;
    var self = this;
    var $img;

    if (!simple) {
      $img = $view.find('.at-recaptcha-table');
      $phrase.html('&hellip;');
      $img.find('.error').remove();
    } else {
      $img = $view.find('img');
      this.subviews['Captcha' + index].setval('').clearFeedback().focus();
    }

    $pin.css({
      width: $img.innerWidth() + 'px',
      height: $img.innerHeight() + 'px'
    }).show().spin();
    $img.hide();

    var unspin = _.once(function () {
      $pin.spin(false).hide();
      $img.show().find('.checked').removeClass('checked');
      $this.css('visibility', 'visible');
      $phrase.text(captcha.captchaPhrase);
    });

    $.ajax({
      url: def.recaptcha + '/' + captcha.bioId,
      xhrFields: { withCredentials: true },
      type: 'GET'
    }).done(function (res) {
      _.extend(captcha, res);
      if (!simple) {
        // re-layout grid
        self.subviews['Captcha' + index].def.captcha = captcha;
        self.subviews['Captcha' + index].render();
      } else {
        $img.attr('src', captcha.img);
      }
      unspin();
    }).always(function () {
      setTimeout(unspin, 1000);
    });
    return this;
  },
  errors: function () {
    var self = this;
    var errors = _.reduce(this.def.captchas, function (errorVals, def, idx) {
      if (!def.simpleCaptcha) {
        var values = _getRecaptchaValues(this, idx);
        if (!values) {
          errorVals.push(_toCaptchaError(idx, 'Image selection is required.', false));
        }
      }
      else {
        var errors = FieldsetView.prototype.errors.call(self);
        if (errors && errors.length) {
          var errorStr = _.map(errors, function (err) {
            return err.text;
          }).join(' ');
          errorVals.push(_toCaptchaError(idx, errorStr, true));
        }
      }
      return errorVals;
    }, [], this);

    return errors;
  },
  renderFeedback: function () {
    var errors = this.errors();
    if (errors && errors.length) {
      this.renderFeedbackWithErrors(errors);
    }
  },
  renderFeedbackWithErrors: function (errors) {
    var self = this;
    _.each(errors, function (error) {
      // display errors for recaptcha and simple captcha differently
      if (error.simpleCaptcha) {
        var $label = self.$el.find('.at-text.Captcha' + error.idx);
        $label.addClass('error');
        $label.find('.error').remove();
        $label.append(self.error_template({ 'error_text': error.error, 'behavior_classes': 'error' }));
      }
      else {
        _displayRecaptchaError(self, error.idx, error.error);
      }
    });
  }
});
