export default {
  translatedOccupations: function (resources) {
    return [
      resources.PrimaryResources.Accountant,
      resources.PrimaryResources.Administrator,
      resources.PrimaryResources.Analyst,
      resources.PrimaryResources.Architect,
      resources.PrimaryResources.Artist,
      resources.PrimaryResources.Attorney,
      resources.PrimaryResources.Banker,
      resources.PrimaryResources.Consultant,
      resources.PrimaryResources.Dentist,
      resources.PrimaryResources.Designer,
      resources.PrimaryResources.Director,
      resources.PrimaryResources.Doctor,
      resources.PrimaryResources.Editor,
      resources.PrimaryResources.Engineer,
      resources.PrimaryResources.Executive,
      resources.PrimaryResources.Farmer,
      resources.PrimaryResources.Homemaker,
      resources.PrimaryResources.Investor,
      resources.PrimaryResources.Librarian,
      resources.PrimaryResources.Manager,
      resources.PrimaryResources.Musician,
      resources.PrimaryResources.Nurse,
      resources.PrimaryResources.Owner,
      resources.PrimaryResources.Partner,
      resources.PrimaryResources.Pharmacist,
      resources.PrimaryResources.Photographer,
      resources.PrimaryResources.Physician,
      resources.PrimaryResources.President,
      resources.PrimaryResources.Professor,
      resources.PrimaryResources.Psychologist,
      resources.PrimaryResources.Realtor,
      resources.PrimaryResources.Scientist,
      resources.PrimaryResources.SocialWorker,
      resources.PrimaryResources.SoftwareEngineer,
      resources.PrimaryResources.Teacher,
      resources.PrimaryResources.Writer
    ];

  }
};
