﻿import CheckboxView from 'app/views/CheckboxView';

export default CheckboxView.extend({
  __name__: 'EmployerOccupationRetiredCheckboxView',
  events: {
    'change input': 'onInputChanged'
  },
  errors: function () {
    // This checkbox doesn't have any validation to do
    // The validation occurs as it normally would for the
    // Occupation and Employer textfields
    return [];
  },
  val: function () {
    return this.$(':input').prop('checked');
  },
  setval: function (value) {
    this.$(':input').prop('checked', value);
    return this.touch();
  },
  onInputChanged: function () {
    var isRetired = this.val();
    var occupationView = this.parent._getChildViewNamed('Occupation');
    var employerView = this.parent._getChildViewNamed('Employer');

    if (isRetired) {
      if (occupationView) {
        occupationView.input.setAttribute('disabled', 'true');
        occupationView.setval(this.resources.PrimaryResources.Retired);
        occupationView.renderFeedbackWithErrors(occupationView.errors());
      }
      if (employerView) {
        employerView.input.setAttribute('disabled', 'true');
        employerView.setval(this.resources.PrimaryResources.Retired);
        employerView.renderFeedbackWithErrors(employerView.errors());
      }
    }
    else {
      if (occupationView) {
        occupationView.setval(null);
        occupationView.input.removeAttribute('disabled');
      }
      if (employerView) {
        employerView.setval(null);
        employerView.input.removeAttribute('disabled');
        // don't render error feedback on uncheck per design
      }
    }
  },
});
