// A wrapper class for appinsights telemetry
// Handles the case where AI is undefined, catches any errors,
// and handles some minor data normalization

import _ from 'lib/lodash.custom';

// Omit properties which are undefined
function cleanProperties(data) {
  if (data && data.properties) {
    data.properties = _.omit(data.properties, function (val) {
      return typeof val === 'undefined';
    });
  }
}

function trackEvent(eventData) {
  if (window.appInsights) {
    try {
      cleanProperties(eventData);
      window.appInsights.trackEvent(eventData);
    } catch (err) {
      //womp
    }
  }
}

function trackException(errorData) {
  if (window.appInsights) {
    try {
      cleanProperties(errorData);
      if (errorData && errorData.exception && typeof (errorData.exception) === 'string') {
        errorData = _.defaults({ exception: new Error(errorData.exception) }, errorData);
      }
      window.appInsights.trackException(errorData);
    } catch (err) {
      //womp
    }
  }
}

function trackTrace(traceData) {
  if (window.appInsights) {
    try {
      cleanProperties(traceData);
      window.appInsights.trackTrace(traceData);
    } catch (err) {
      //womp
    }
  }
}

function trackDependencyData(dependencyData) {
  if (window.appInsights) {
    try {
      cleanProperties(dependencyData);
      window.appInsights.trackDependencyData(dependencyData);
    } catch (err) {
      //womp
    }
  }
}

export default {
  trackEvent: trackEvent,
  trackException: trackException,
  trackTrace: trackTrace,
  trackDependencyData: trackDependencyData,
  isEnabled: function () { return !!window.appInsights; }
};
