import templates from 'generated/compiled_handlebars_templates';
import $ from 'lib/jquery.ui';

export default function lightboxHelper(lightboxData, declineCallback, preAcceptCallback, postAcceptCallback) {

  function handleHashChange() {
    // If the user closed the lightbox via ESC, the x icon, or clicking outside the modal,
    // treat as if they had explicitly declined.
    declineCallback();
  }

  function hideModal() {
    // Resetting the url triggers the lightbox to hide via modal.js
    $(window).off('hashchange', handleHashChange);
    window.location.hash = '';
  }

  function showLightbox() {
    // Remove any previous instances
    $('#lightbox-modal').remove();

    // Render template
    $('body').append(templates.generic_modal({
      prefix: 'lightbox',
      headerText: lightboxData.headerText
    }));

    if (window.location.hash === '#lightbox-modal') {
      // Reset state to force modal to display again
      hideModal();
    }

    // Trigger modal to display
    window.location.hash = 'lightbox-modal';

    // Insert contents into lightbox
    var modal = $('#lightbox-modal'),
      modalContent = modal.find('#lightbox-modal-content'),
      contentHtml = '<div class="at-lightbox-content-wrapper">' + (lightboxData.mainContent || '') + '</div>',
      customCloseBtn = $('<a href="#" tabindex="3" class="lightbox-close">×</a>'), // we hide the default one with css
      header = modal.find('header'),
      footerContent = modal.find('#lightbox-footer-content'),
      footerHtml = '<div class="at at-lightbox-footer-wrapper">' +
        '<button value="accept" tabindex="1" class="at-submit btn-at btn-at-primary lightbox-accept-button">' + (lightboxData.acceptButtonText || '') + '</button>' +
        '<a href="#" tabindex="2" class="lightbox-decline-button">' + (lightboxData.declineButtonText || '') + '</a>' +
        '<div class="lightbox-footer">' + (lightboxData.footerText || '') + '</div>' +
        '</div>';

    // Insert contents
    modalContent.html(contentHtml);
    footerContent.html(footerHtml);
    header.append(customCloseBtn);

    // Bind handlers for user response
    // Prevent the acceptance handler from being processed more than once, unless we need to reset it.
    var blockAcceptanceRetrigger = false;
    $('button.lightbox-accept-button').on('click', function () {
      if (blockAcceptanceRetrigger) return;
      blockAcceptanceRetrigger = true;
      preAcceptCallback().then(function (result) {
        result = result || {};

        // If the acceptance process was cancelled somehow, keep the modal open and allow acceptance to be retriggered
        if (result.IsCancelled) {
          blockAcceptanceRetrigger = false;
          return;
        }

        // on error or success, close the modal
        // (we close the modal on error because the user might need to make a fix on the form)
        hideModal();

        // On success, proceed to the next step
        if (!result.IsError) {
          postAcceptCallback(result);
        }
      });
    });

    // Trap focus within the modal
    $('button.lightbox-accept-button').keydown(function (e) {
      // If the user shift+tabs (shift + keycode 9) from the accept button, focus the close icon
      if (e.keyCode === 9 && e.shiftKey) {
        $('a.lightbox-close').focus();
        e.preventDefault();
      }
    });

    $('a.lightbox-close').keydown(function (e) {
      // If the user tabs (keycode 9) from the close icon, focus the accept button
      if (e.keyCode === 9) {
        $('button.lightbox-accept-button').focus();
        e.preventDefault();
      }
    });

    setTimeout(function () {
      $(window).one('hashchange', handleHashChange);
      $('button.lightbox-accept-button').focus();
    });
  }

  return {
    show: showLightbox
  };

}
