// Note that unlike most, this model is defined globally for historical reasons
// Possibly it should be collapsed with the contribution amount model

import Backbone from 'lib/backbone';

var env = (window && window.navigator && window.navigator.languages && window.navigator.languages[0]) || 'en-US';

// Source: https://www.xe.com/symbols.php
var mapping = {
  'USD': {
    'resourceName': 'USDollar',
    'code': 'USD',
    'symbol': '$'
  },
  'CAD': {
    'resourceName': 'CanadianDollar',
    'code': 'CAD',
    'symbol': '$'
  },
  'EUR': {
    'resourceName': 'Euro',
    'code': 'EUR',
    'symbol': '€'
  },
  'GBP': {
    'resourceName': 'PoundSterling',
    'code': 'GBP',
    'symbol': '£'
  },
  // The below are supported only by Oberon
  'AUD': {
    'resourceName': 'AustralianDollar',
    'code': 'AUD',
    'symbol': '$'
  },
  'HKD': {
    'resourceName': 'HongKongDollar',
    'code': 'HKD',
    'symbol': '$'
  },
  'CHF': {
    'resourceName': 'SwissFranc',
    'code': 'CHF',
    'symbol': 'CHF'
  },
  'DKK': {
    'resourceName': 'DanishKrone',
    'code': 'DKK',
    'symbol': 'DKK'
  }
};

var CurrencyModel = Backbone.Model.extend({
  __name__: 'ContributionCurrency',
  initialize: function () {
    this.setByCode('USD');
  },
  getCurrency: function () {
    return mapping[this.get('currency')];
  },
  setByCode: function setCurrencyByCode(currencyCode) {
    this.set({ currency: currencyCode || 'USD' });
    return this.getCurrency();
  },
  format: function formatCurrency(amount) {
    if (amount === 'other') return null;

    var amt = parseFloat(amount) || 0.00;
    var minFractionDigits = amt % 1 === 0 ? 0 : 2;
    var currency = this.get('currency');

    return amt.toLocaleString(env, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
      minimumFractionDigits: minFractionDigits
    });
  }
});

export default new CurrencyModel();
