/* NVTagView.segue used to use the Databag.js model to create a new form state
from the old form state, like so

```
if (this.currentFormView() != null) {
  var seguedFromLastForm_databag = new DatabagModel({ name: 'seguedFromLastFormState' });
  seguedFromLastForm_databag.setWithFormState(this.currentFormView().options.last_form_state);
  newFormView.options.last_form_state = seguedFromLastForm_databag.getDict();
  this.currentFormView().remove();
}
```

The databag.js model was killed in EVA-250. The two methods `setWithFormState` and `getDict`
were simplified, combined, and moved to this file
*/

import databag_config from 'app/databag_config';
import _ from 'lib/lodash.custom';

const transformFormStateForSegue = (form_state) => {
  let state_copy;

  // Do not segue form state values we wouldn't save to the FastAction profile
  state_copy = _.omit(form_state, databag_config.denylist.slice());

  state_copy = _.reduce(state_copy, function (memo, value, key) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    memo[key] = value;
    return memo;
  }, {});

  let dict = _.omit(state_copy, ['id', 'name']);

  // this used to be Databag.js name(). Let me know if we'd rather I not rewrite it this way
  var fullName = [dict.FirstName, dict.MiddleName, dict.LastName].filter(s => !!s).join(' ');
  if (fullName) {
    dict.FullName = fullName;
  }

  return dict;
};

export default {
  transformFormStateForSegue: transformFormStateForSegue
};
