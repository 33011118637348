import Backbone from 'lib/backbone';
import FieldsetView from 'app/views/FieldsetView';
import invokeCallbacks from 'app/NVTagCallbacks';

var _ = Backbone._;
var $ = Backbone.$;
var regexKey = /(Message|Subject)([0-9])/;

export default FieldsetView.extend({
  __name__: 'AdvocacyView',
  type: 'advocacy',
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);
    var isRegulationTarget = this.def.name === 'AdvocacyRegulationsFields';
    var messageTitle = isRegulationTarget ? 'Comment' : 'Message';
    var total = this.def.messages.length;
    this.template = this.options.templates.fieldset;
    this.multiple = _.size(this.def.messages) > 1;
    var metadata = this.options.formview.options.form_definition.metadata;
    var requireMessageAndSubject = !metadata || !metadata.deliveryType || (metadata.deliveryType
      && metadata.deliveryType.indexOf('TwilioCalling') < 0 && metadata.deliveryType.indexOf('RevereCalling') < 0);

    // initialize advocacy view with appropriate header
    var message = _.first(this.def.messages);
    var hasTargets = (message && message.targets && message.targets.length);
    var advocacyHeader = hasTargets && !isRegulationTarget ?
      [{
        name: 'AdvocacyHeader',
        type: 'markup',
        markup: '<div style="margin:0 0 0.625rem;"><strong>Send the following message' + (this.multiple ? 's' : '') + ':</strong></div>'
      }] : [];

    this.def.children = _.reduce(this.def.messages, function (children, def, idx) {
      if (def.targets && def.targets.length && !isRegulationTarget) {
        children.push(
          {
            name: 'Targets' + idx,
            type: 'targets',
            targets: def.targets
          });
      }
      if (!isRegulationTarget) {
        children.push(
          def.subject.readonly ?
            {
              name: 'Subject' + idx,
              type: 'markup',
              markup: '<div data-at-id="Subject_' + idx + '" class="Subject" style="white-space: pre-line">' + def.subject.value + '</div><br/>'
            }
            :
            {
              name: 'Subject' + idx,
              title: 'Subject',
              label: 'Personalize your subject',
              type: 'textfield',
              required: requireMessageAndSubject,
              maxlength: 256,
              default_value: def.subject.value
            });

        children.push({
          name: 'Intro' + idx,
          type: 'markup',
          markup: '<div data-at-id="Greeting_' + idx + '" style="white-space: pre-line">' + def.intro + '</div><br/>'
        });
      }

      var maxMessageLength = 4000;

      if (def.introductionMessage && !isRegulationTarget) {
        children.push(
          {
            name: 'IntroductionMessage' + idx,
            type: 'markup',
            markup: '<div data-at-id="Introduction_' + idx + '" style="white-space: pre-line">' + def.introductionMessage + '</div><br/>'
          }
        );
        maxMessageLength -= 1 + def.introductionMessage.length; //+1 for space that gets added
      }

      if (def.conclusionMessage) {
        maxMessageLength -= 1 + def.conclusionMessage.length; //+1 for space that gets added
      }

      children.push(
        def.message.readonly ?
          {
            name: 'Message' + idx,
            type: 'markup',
            markup: '<div data-at-id="Message_' + idx + '" class="Message" style="white-space: pre-line">' + def.message.value + '</div><br/>'
          }
          :
          {
            name: 'Message' + idx,
            title: messageTitle,
            label: 'Personalize your ' + messageTitle.toLowerCase(),
            type: 'textarea',
            required: requireMessageAndSubject,
            maxlength: maxMessageLength,
            default_value: def.message.value,
            attr: {
              rows: 15
            }
          });

      if (def.conclusionMessage && !isRegulationTarget) {
        children.push(
          {
            name: 'ConclusionMessage' + idx,
            type: 'markup',
            markup: '<div data-at-id="Conclusion_' + idx + '" style="white-space: pre-line">' + def.conclusionMessage + '</div><br/>'
          }
        );
      }

      children.push(
        {
          name: 'Outro' + idx,
          type: 'markup',
          markup: '<div data-at-id="Outro_' + idx + '" style="white-space: pre-line">' + def.outro + '</div>' + (idx + 1 < total ? '<hr>' : '')
        }
      );
      return children;
    }, advocacyHeader);

  },
  val: function () {
    return _.reduce(FieldsetView.prototype.val.call(this), function (memo, val, key) {
      var part = key.match(regexKey);
      var type = part[1] + 's';
      var idx = part[2];
      memo[type][idx] = val;
      return memo;
    }, { Messages: [], Subjects: [] });
  },
  _onSubviewsRendered: function () {
    if (this.def.back) {
      var block = this.parent.$('.at-form-submit');
      block.append('<a href="' + this.def.back.href + '" tabindex="-1" class="back-link at-submit btn-at btn-at-link">' + this.def.back.text + '</a>');
      var submit = block.find('input[type="submit"]');
      if (!this.multiple) {
        submit.val('Send message');
      }
      if (this.def.name !== 'AdvocacyRegulationsFields') {
        submit
          .clone()
          .attr('tabIndex', -1)
          .addClass('at-secondary-submit')
          .prependTo(this.$('legend').addClass('clearfix at-legend-with-submit'));
      }
    }

    invokeCallbacks('postRender', {
      form_definition: this.options.formview.options.form_definition,
      options: this.options,
      thank: false
    });

    var metaData = this.parent.options.form_definition.metadata;

    if (metaData && metaData.hasOwnProperty('layoutStyle') && metaData.layoutStyle === 'multistep') {
      //force focus to the step section of page
      $('html,body').scrollTop(this.parent.$('form').offset().top);
    }
  }
});
