import FieldsetView from 'app/views/FieldsetView';
import Backbone from 'lib/backbone';

var _ = Backbone._;

export default FieldsetView.extend({
  __name__: 'EventInformationView',
  type: 'fieldset',
  events: {
    'change [name="EventIsPrivate"]': 'eventPrivacyChange'
  },
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);

    // listen to fill event from NVFormView
    this.listenTo(this.options.parent, 'fill', this.postFill);
  },
  val: function () {
    var values = FieldsetView.prototype.val.call(this);

    // If the reason is being hidden, omit its value
    var reasonWhy = this.subviews.EventIsPrivateReason;
    if (reasonWhy && reasonWhy.$el.is(':hidden')) {
      delete values.EventIsPrivateReason;
    }
    return values;
  },
  errors: function () {
    var errs = FieldsetView.prototype.errors.call(this);

    // If the reason is being hidden, omit its errors
    var reasonWhy = this.subviews.EventIsPrivateReason;
    if (reasonWhy && reasonWhy.$el.is(':hidden')) {
      _.remove(errs, function (e) {
        return e && e.field && e.field.name === 'EventIsPrivateReason';
      });
    }
    return errs;
  },
  postFill: function () {
    this.eventPrivacyChange(); //init
  },
  eventPrivacyChange: function () {
    var eventIsPrivate = this.subviews.EventIsPrivate;
    var reasonWhy = this.subviews.EventIsPrivateReason;
    if (eventIsPrivate && reasonWhy) {
      var val = eventIsPrivate.val();
      if (val && val.toLowerCase() === 'true') {
        reasonWhy.$el.show();
      } else {
        reasonWhy.$el.hide();
      }
    }
  }
});
