export default {
  mveid: 'MinivanExportId',
  dbm: 'DbMode',
  cid: 'CommitteeId',
  plid: 'PrintedListId',
  mvcid: 'MinivanCampaignId',
  vanid: 'VanId',
  midqs: 'MailingId',
  emci: 'EmailMessageContentId',
  emdi: 'DistributionId',
  ceid: 'ContactsEmailId',
  track: 'MarketSource',
  ms: 'MarketSource',
  attr: 'AttributedContactIds',
  source: 'SourceCodeName',
  sourceid: 'SourceCodeId',
  group: 'SupporterGroupId',
  minivan: [
    'DbMode',
    'VanId',
    'CommitteeId',
    'PrintedListId',
    'MinivanCampaignId',
    'MinivanExportId',
    'FirstName',
    'LastName',
    'PaymentMethod',
    'Amount',
    'IsRecurring',
    'SelectedFrequency',
    'SelectedDuration',
    'EndDate'
  ],
  mmdr: 'MobileMessageDistributionRecipientLinkTrackingId'
};
