var timer = {};
var oneMinute = 1000 * 60;

function Timing(variable, opt_label) {
  this.variable = variable;
  this.label = opt_label ? opt_label : undefined;
  this.startTime = new Date().getTime();
  return this;
}

Timing.prototype.send = function () {
  //Avoid sending bad data: https://developers.google.com/analytics/devguides/collection/gajs/gaTrackingTiming#badData
  this.endTime = new Date().getTime();
  var timeSpent = this.endTime - this.startTime;
  return timeSpent;
};

export default {
  start: function (variable, label) {
    var name = label ? variable + ' (' + label + ')' : variable;
    console.time(name);
    timer[name] = new Timing(variable, label);
    timer[name].timeout = setTimeout(function () {
      timer[name] = null;
      console.timeEnd(name);
    }, oneMinute + 1);
  },
  end: function (variable, label) {
    var name = label ? variable + ' (' + label + ')' : variable;
    if (timer[name]) {
      var timing = timer[name];
      timer[name] = null;
      console.timeEnd(name);
      clearTimeout(timing.timeout);
      return timing.send();
    }
  }
};
