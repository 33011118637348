import _ from 'lib/lodash.custom';
import $ from 'lib/jquery.ui';

export default _.memoize(function loadCSS(url) {
  var link = document.createElement('link');
  var dfd = $.Deferred();
  link.type = 'text/css';
  link.rel = 'stylesheet';
  if (typeof link.addEventListener !== 'undefined') {
    link.addEventListener('load', dfd.resolve, false);
    link.addEventListener('error', dfd.reject, false);
  } else if (typeof link.attachEvent !== 'undefined') {
    link.attachEvent('onload', dfd.resolve);
  }
  setTimeout(dfd.resolve, 1000);
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
  return dfd.promise();
});
