import Backbone from 'lib/backbone';
import templates from 'generated/compiled_handlebars_templates';

var $ = Backbone.$;
export default Backbone.View.extend({
  __name__: 'PageAlertView',
  events: {
    'click a.at-dismiss': 'close'
  },
  template: templates.page_alert,
  alerts: [],
  render: function () {
    this.$el.html(this.template({ alerts: this.alerts }));
    return this;
  },
  val: function () {
    return {};
  },
  errors: function () {
    return [];
  },
  renderFeedback: function () {
    return [];
  },
  clearFeedback: function () {
    return this;
  },
  close: function (e) {
    if (e) {
      e.stopPropagation();
      var el = this.$(e.currentTarget).parent();
      this.alerts.splice(el.index(), 1);
      el.remove();
    }
  },
  setAlerts: function (alerts) {
    var self = this;
    self.alerts = alerts || [];
    self.render();
    self.$el.show();
    // only register once to avoid multiple click events that could bubble up
    setTimeout(function () {
      $('body').one('click', self, self.onBodyClick);
    }, 500);

    // close after 6 seconds
    setTimeout(function () {
      self.clearAlert();
    }, 6000);
  },
  onBodyClick: function (e) {
    e.stopPropagation();
    if (e.data) {
      e.data.clearAlert();
    }
  },
  clearAlert: function () {
    this.alerts = [];
    this.$el.hide();
  }
});
