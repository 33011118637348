import Backbone from 'lib/backbone';
import query_string from 'lib/node-querystring';
import parseUrl from 'lib/parseUrl';
import deviceInfo from 'app/deviceInfo';
import timing from 'app/timing';
import loadCSS from 'app/loadCSS';
import baseUrl from 'app/baseUrl';
import FormDefUtils from 'app/FormDefUtils';
import cookieConsentConfig from 'app/CookieConsentConfiguration';
import gaHelper from 'app/googleAnalyticsHelper';
import 'lib/jquery.timepicker';
import 'lib/hustle/modal'; // needed to boostrap the hustle modal
import 'app/NVTagCallbacks';

// NVTag
if ('nvtag' in window) {
  throw 'NVTag was already initialized. The NVTag JS is likely included more than once on this page.';
} else {
  window.nvtag = {
    on: function () {
    }
  };
}

timing.start('Total');
var $ = Backbone.$,
  _ = Backbone._;

$.ajaxSetup({ cache: !deviceInfo.ie });

window.dataLayer = window.hasOwnProperty('dataLayer') ? window.dataLayer : [];
window._gaq = window._gaq || [];

function trackEvent(category, action, label, value, formview) {
  var options = formview.options || {};

  var gaEvent = {
    eventCategory: category,
    eventAction: action,
    eventLabel: undefined,
    eventValue: undefined
  };

  var form_definition = options.form_definition;
  if (form_definition) {
    gaEvent.activistCodes = form_definition.metadata.activistCodes;
    gaEvent.sourceCodeId = form_definition.metadata.sourceCodeId;
    gaEvent.tenantUri = form_definition.metadata.tenantUri;
    gaEvent.formName = form_definition.name;

    // add form guid
    var fullFormUrlParts = form_definition.url.full.split('/');
    var guidIndex = fullFormUrlParts.length - 1;
    var guid = fullFormUrlParts[guidIndex];
    gaEvent.formShortCode = guid;

    // add some payment info
    var acceptedPaymentTypes = formview.paymentMethodConfiguration && formview.paymentMethodConfiguration.acceptedPaymentMethods;
    if (acceptedPaymentTypes && acceptedPaymentTypes.length) {
      gaEvent.acceptedPaymentTypes = acceptedPaymentTypes;
    }

    var processingCurrencyField = FormDefUtils.find_field(form_definition.form_elements, 'ProcessingCurrency');
    if (processingCurrencyField) {
      if (processingCurrencyField.type === 'hidden') {
        gaEvent.processingCurrencies = [processingCurrencyField.value];
      } else if (processingCurrencyField.options) {
        gaEvent.processingCurrencies = processingCurrencyField.options
          .filter(function (x) { return !!x.value; }) // Remove "- Select -"
          .map(function (x) { return x.value; });
      }
    }

    // add campaign id and name
    gaEvent.campaignId = form_definition.metadata.campaignId;
    gaEvent.campaignName = form_definition.metadata.campaignName;
  }

  // query string value takes precedence over formdef value
  var qs = options.query_string;
  if (qs && qs.sourceid && parseInt(qs.sourceid) >= 0) {
    gaEvent.sourceCodeId = parseInt(qs.sourceid);
  }

  if (_.isString(label)) {
    gaEvent.eventLabel = label || 'NO_LABEL';
    if (_.isFinite(value) && value === ~~value) {
      gaEvent.eventValue = value;
    }
  }

  if (gaHelper.shouldUseGtag()) {
    // GA4 via gtag.js implementation
    window.gtag('event', 'FormEvent', gaEvent);
  } else {
    // GTM implementation
    gaEvent.event = 'FormEvent';
    window.dataLayer.push(gaEvent);
  }

  return gaEvent;
}

// GLOBALS
window.nvtag = _.extend(window.nvtag, {
  ua: deviceInfo,
  tags: [],
  track: trackEvent,
  process: function (el) {
    return $(el).each(processNVTag);
  },
  acceptFunctionalCookies: function () {
    cookieConsentConfig.acceptFunctionalCookies();
  },
  acceptPerformanceCookies: function () {
    cookieConsentConfig.acceptPerformanceCookies();
  },
  revokeFunctionalCookies: function () {
    cookieConsentConfig.revokeFunctionalCookies();
  },
  revokePerformanceCookies: function () {
    cookieConsentConfig.revokePerformanceCookies();
  },

}, Backbone.Events);

/*jshint -W024 */
import('app/FastAction').then(({ default: FastAction }) => {
  /*jshint +W024 */
  window.nvtag_plugins = (window.nvtag_plugins || []);
  window.nvtag_plugins.push(FastAction);
});

// '?' is the first character of window.location.search, so strip that.
var qs = _.transform(query_string.parse(window.location.search.substring(1)), function (result, value, key) {
  result[key.toLowerCase()] = value;
});
var qs_case_preserved = query_string.parse(window.location.search.substring(1));

var regex = {
  localhost: /^https?:\/\/localhost/i,
  notSSL: /^http:/i,
  truth: /^true$/i,
  trailingSlash: /\/+$/
};

var titaniaPath = '/v2/Forms/';

function torf(value) {
  if (_.isBoolean(value)) {
    return value;
  } else if (_.isString(value)) {
    return regex.truth.test(value);
  } else {
    return false;
  }
}

function processForms() {
  // Find all elements with a class of "ngp-form".
  // These should all be processed by our library.
  $('.ngp-form').each(processNVTag);
}

function processNVTag() {
  /*jshint -W040 */
  var $this = $(this),
    /*jshint +W040 */
    dataAttr = $this.data();
  if (dataAttr.alreadyProcessed) {
    return;
  }

  if (!!dataAttr.noCss && dataAttr.noCss !== 'false') {
    // do not load at.css and extra.css if data-no-css set
    // https://ngpvan.atlassian.net/wiki/spaces/DIGITAL/pages/1159299748/ActionTag+data+attributes
  }
  else if (window.nvtagDebug) {
    window.nvtag.css = loadCSS('sass/at.css');
    loadCSS('sass/extra.css');
  } else {
    window.nvtag.css = loadCSS(baseUrl + 'at.min.css');
    loadCSS(baseUrl + 'extra.min.css');
    if (dataAttr.customCss) {
      loadCSS(dataAttr.customCss);
    }
  }

  $this.addClass('at');
  $this.data('alreadyProcessed', true);
  var endpoint = dataAttr.endpoint || 'https://api.myngp.com';

  //if this is not a Titania endpoint append the service path
  if (endpoint.indexOf('cwpapi') === -1) {
    endpoint = endpoint.replace(regex.trailingSlash, '') + titaniaPath;
  }

  var formId = dataAttr.id || null;

  var oberonUrl;
  if (dataAttr.formUrl) {
    var oberonUri = parseUrl(dataAttr.formUrl);
    oberonUrl = decodeURIComponent(oberonUri.pathname.split('/')[1]);
    var oberonSegment = parseUrl(oberonUrl);
    formId = oberonSegment.pathname.split('/')[2];
  }

  /*jshint -W024 */
  import('app/views/NVTagView').then(({ default: NVTagView }) => {
    /*jshint +W024 */
    window.nvtag.NVTagView = NVTagView;
    window.nvtag.tags.push(new NVTagView({
      el: $this,
      query_string: qs,
      query_string_case_preserved: qs_case_preserved,
      index: window.nvtag.tags.length,
      endpoint: endpoint,
      raw_endpoint: dataAttr.endpoint,
      labels: dataAttr.labels === 'inline' ? 'placeholder' : dataAttr.labels,
      options: dataAttr.options,
      initial_form_id: formId,
      template: dataAttr.template || 'minimal',
      style: dataAttr.style || 'cloudfront',
      resource_path: (dataAttr.resourcePath || '//d1aqhv4sn5kxtx.cloudfront.net').replace(regex.trailingSlash, ''),
      inline_error_display: 'true', // Make sure this is a string. Legacy reasons.
      fast_action_nologin: !qs.kiosk && torf(dataAttr.fastactionNologin),
      formdefEndpoint: dataAttr.formdefEndpoint || null,
      formUrl: dataAttr.formUrl || null,
      oberonUrl: oberonUrl || null,
      mobileAutofocus: !!dataAttr.mobileAutofocus
    }));
  });
}

// Find and inspire nv tags.
processForms();
setTimeout(function () {
  $(processForms);
});

export default nvtag;
