import Backbone from 'lib/backbone';
import RadiosView from 'app/views/RadiosView';
import invokeCallbacks from 'app/NVTagCallbacks';
import templates from 'generated/compiled_handlebars_templates';
import _ from 'lib/lodash.custom';

var $ = Backbone.$;

export default RadiosView.extend({
  __name__: 'EcardsView',
  type: 'ecard',
  events: {
    'change  input[name=ecard]': 'onEcardRadioSelect',
    'keyup input': 'cycleOptions',
    'click div.at-ecard': 'onEcardSelect',
    'click .at-preview-ecard': 'showPreview'
  },
  initialize: function () {
    RadiosView.prototype.initialize.call(this);
    this.template = this.options.templates.ecards;
  },
  context: function () {
    var context = {
      ecards: [],
      title: this.def.title || '',
      allowPreview: !!this.def.preview_markup
    };

    context.ecards = this.def.imgs;
    // select the first one if there isn't a default value
    if (this.def.default_value) {
      var defaultSelection = _.where(context.ecards, { url: this.def.default_value });
      if (defaultSelection) {
        defaultSelection.checked = 'checked';
      } else {
        context.ecards[0].checked = 'checked';
      }
    } else {
      context.ecards[0].checked = 'checked';
    }

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    var context = this.context();
    this.$el.html(this.template(context));

    // if there is preview markup, add the preview modal
    if (this.def.preview_markup) {
      // add preview modal
      $('body').append(templates.generic_modal({
        prefix: 'preview',
        headerText: this.resources.PrimaryResources.Preview,
        resources: this.resources
      }));
    }

    // add selected style to the default ecard
    var currentSelection = $('input[name=ecard]:checked').parent();
    if (currentSelection) {
      currentSelection.addClass('selected');
    }

    return this;
  },
  selectEcard: function (elem) {
    // remove selected class from everything
    $('input[name=ecard]').each(function () {
      $(this).parent().removeClass('selected');
    });

    // add selected class to new selection
    elem.addClass('selected');
  },
  onEcardSelect: function (e) {
    var target = $(e.currentTarget);
    this.selectEcard(target);

    target.find('input[name=ecard]').prop('checked', true).focus();
  },
  onEcardRadioSelect: function (e) {
    this.selectEcard($(e.currentTarget).parent());
  },
  showPreview: function () {
    // Reset Preview Modal Content
    var selectedCard = this.$('input[name=ecard]:checked');

    if (selectedCard && selectedCard[0]) {
      var img = selectedCard[0].value;
      var modal = $('#preview-modal');
      var modalContent = modal.find('#preview-modal-content');
      var footerContent = modal.find('#preview-footer-content');

      modalContent.empty();

      var htmlWithImg = '<div class="at-preview-wrapper">' +
        '<img src="' + img + '"/>' +
        '<div class="at-preview-markup">' +
        this.def.preview_markup + '</div></div>';

      modalContent.html(htmlWithImg);

      // reset footer
      footerContent.empty();
      var closeButtonHtml = this.resources.fill('<a href="#!" class="close-button" title="{{0}}" data-dismiss="modal">{{0}}</a>', this.resources.PrimaryResources.Close);
      footerContent.html(closeButtonHtml);
    }

    window.location.hash = 'preview-modal';
  },
  val: function () {
    var elm = $('input[name=ecard]:checked');
    if (elm && elm[0]) {
      var val = elm[0].value;
      return val;
    }
    return null;
  },
});
