import Backbone from 'lib/backbone';
import moment from 'lib/moment';
import invokeCallbacks from 'app/NVTagCallbacks';
import VgsTextFieldView from 'app/views/VgsTextFieldView';
import deviceInfo from 'app/deviceInfo';
import 'lib/jquery.payment';

var $ = Backbone.$,
  _ = Backbone._;
export default VgsTextFieldView.extend({
  __name__: 'CCExpirationView',
  type: 'cc_expiration',
  className: 'at-text at-cc-expiration',
  vgsConfig: {
    type: 'card-expiration-date',
    serializers: [],
    validations: ['validCardExpirationDate'],
    autoComplete: 'cc-exp',
    yearLength: '2',
    css: {
      fontSize: '.875rem',
      fontFamily: 'monospace',
      lineHeight: '1',
      backgroundColor: '#fff',
      '&::placeholder': {
        color: '#ced4da',
        fontWeight: 'bold'
      }
    }
  },
  initialize: function () {
    // since this is created in a special way (not really in formdef) need to ensure these values before we call the base initialize
    this.options.definition.name = 'ExpirationDate';
    this.options.definition.title = this.options.formview.options.form_definition.resources.PrimaryResources.ExpirationDate;
    VgsTextFieldView.prototype.initialize.call(this);
    this.min = moment();
    if (this.vgs.loaded) {
      this.vgsConfig.serializers.push({ name: 'separate', options: { monthName: 'ExpirationMonth', yearName: 'ExpirationYear' } });
    }
  },
  context: function () {
    var def = this.options.definition;
    var context = {
      name: def.name,
      title: def.title,
      pattern: '(\\d{2}\\s?/\\s?\\d{2,4})',
      placeholder: this.def.placeholder || this.resources.PrimaryResources.CardExpirationPlaceHolder,
      labelhide: this.options.labels === 'placeholder',
      required: def.required ? 'required' : '',
      value: def.default_value || '',
      type: 'tel',
      vgsContainerId: this.vgs.containerId,
      vgsLoaded: this.vgs.loaded
    };

    if (context.labelhide) {
      var placeholder = this.resources.PrimaryResources.CardExpiration;
      if (!deviceInfo.mobile) {
        placeholder += ' (' + placeholder + ')';
      }
    }

    var parent = this.options.parent;
    if (parent.field_name() === 'PaymentInformation'
      && 'required' in parent.options.definition
      && !parent.options.definition.required) {
      context.required = '';
    }

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: def }).context;
  },
  fallbackRender: function () {
    var lastVal = '';
    var $input = this.$('input');
    var self = this;
    $input.payment('formatCardExpiry');
    if (this.parent.subviews.SecurityCode) {
      $input.on('input', function (e) {
        if (e.currentTarget.value.length > lastVal.length && $.payment.validateCardExpiry($input.payment('cardExpiryVal'))) {
          self.parent.subviews.SecurityCode.focus();
        }
        lastVal = e.currentTarget.value;
      });
    }
    return this;
  },
  handleVgsStateChange: function (newState) {
    if (newState && newState.isValid && !this.hasMoved) {
      this.hasMoved = true;
      if (this.parent.subviews.SecurityCode) {
        this.parent.subviews.SecurityCode.focus();
      }
    }
  },
  val: function () {
    if (this.vgs.loaded) {
      return null;
    }
    var val;
    val = _.mapValues(this.$('input').payment('cardExpiryVal'), function (val, key) {
      if (val) {
        if (key === 'month' && val < 10) {
          return '0' + val;
        }
        if (key === 'year') {
          return '' + (val - 2000);
        }
        return '' + val;
      }
      return '';
    });
    return {
      ExpirationMonth: val.month,
      ExpirationYear: val.year
    };
  },
  setval: function (value) {
    if (this.vgs.loaded) {
      return;
    }

    if (_.isObject(value)) {
      var MM = '' + (value.ExpirationMonth || ''),
        YYYY = '' + (value.ExpirationYear || '');
      if (MM.length === 2 && YYYY.length >= 2) {
        this.$('input').val(MM + ' / ' + YYYY);
      }
    }
  },
  errors: function () {
    var errors = [],
      invalid = this.resources.PrimaryResources.InvalidExpirationDate;

    if (this.vgs.loaded) {
      if (!this.vgs.state || this.vgs.state.isEmpty) {
        errors.push(this.resources.fill(this.resources.PrimaryResources.BlankIsRequired, this.title));
      } else if (!this.vgs.state.isValid) {
        errors.push(invalid);
      }
    } else {
      var exp = this.val();
      var MM = exp.ExpirationMonth, YYYY = exp.ExpirationYear;
      if (MM && YYYY) {
        YYYY = '20' + YYYY;
        var expiry = moment([~~YYYY, ~~MM - 1]);
        if (!expiry.isValid()) {
          errors.push(invalid);
        } else {
          if (expiry.add(1, 'months').isBefore(this.min)) {
            errors.push(this.resources.PrimaryResources.CardHasExpired);
          } else if (!$.payment.validateCardExpiry(MM, YYYY)) {
            errors.push(invalid);
          }
        }
      } else {
        errors.push(invalid);
      }
    }

    errors = invokeCallbacks('alterErrors', {
      val: this.val(),
      field_name: this.field_name(),
      errors: errors,
      def: this.options.definition
    }).errors;
    this.trigger(errors.length ? 'invalid' : 'valid');
    return _.map(errors, this.toError, this);
  }
});
