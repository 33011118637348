// Manages the recurring contribution related data points
// specifically, the recurring frequency and the"IsRecurring" flag,
// that indicates whether the contribution should be consider a recurrence

import Backbone from 'lib/backbone';

var _ = Backbone._;

var numReg = /\d+/;
function isNumber(val) {
  return _.isNumber(val) || numReg.test(val);
}

export default Backbone.Model.extend({
  __name__: 'ContributionRecurrence',
  initialize: function (opts) {
    // this flag tracks if we are using the "new" layout
    // that allows users to explicitly pick "One-Time" (Frequency ID of 0)
    // when "new" layout is used, the IsRecurring flag will be determined by
    // the existence of a frequency ID other than 0
    // when the "old" layout is used, the IsRecurring flag will be determined
    // directly by the checkbox/hidden element
    this.allowOneTimeFrequency = opts.allowOneTimeFrequency;
    this.frequencyOptions = _(opts.frequencyOptions || []).map('value').filter().map(Number).value();
    this.frequencyLookup = _.indexBy(opts.frequencyOptions || [], 'value');
    this.updateModel(opts.isRecurring, opts.frequency);
  },
  setFrequency: function (frequency) {
    if (!isNumber(frequency) || !_.contains(this.frequencyOptions, Number(frequency))) {
      return;
    }
    var isRecurring = this.get('isRecurring');
    this.updateModel(isRecurring, frequency);
  },
  getFrequencyDisplayName: function () {
    var frequency = this.get('frequency');
    var name = this.get('isRecurring') && this.frequencyLookup[frequency] && this.frequencyLookup[frequency].display;
    // ensure the return value is null if falsy
    return name || null;
  },
  setIsRecurring: function (isRecurring) {
    var frequency = this.get('frequency');
    if (this.allowOneTimeFrequency && !isRecurring) {
      frequency = 0;
    }
    this.updateModel(isRecurring, frequency);
  },
  updateModel: function (isRecurring, frequency) {
    frequency = Number(frequency);
    if (this.allowOneTimeFrequency) {
      isRecurring = frequency > 0;
    }
    this.set({
      isRecurring: !!isRecurring,
      frequency: frequency
    });
  }
});
