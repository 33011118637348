import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FormError from 'app/FormError';
import { accounting } from 'lib/accounting';

var _ = Backbone._;
export default Backbone.View.extend({ // TODO: Maybe this should just be a mixin? - Bjorn
  __name__: 'FieldView',
  initialize: function () {
    _.bindAll(this, 'render', 'renderFeedback', 'clearFeedback', 'multiple_fields', 'dispose', 'isVisible', 'toFloat', 'renderFeedbackWithErrors', 'isActive', 'getBaseId');
    Backbone.Courier.add(this);

    this.touch_state = false;
    this.error_template = this.options.templates.inline_error;
    this.def = this.options.definition || {};
    this.name = _.result(this, 'name') || this.def.name || this.options.name;
    this.title = _.result(this, 'title') || this.def.title || this.options.title;
    this.resources = this.options.formview.options.form_definition.resources || {};
    this.template = this.options.templates[this.type];
    if (this.def.contactMode) {
      var className = '';
      switch (this.def.contactMode.toLowerCase()) {
        case 'org':
          className = 'at-mode-org-only';
          break;
        case 'person':
          className = 'at-mode-person-only';
          break;
      }
      if (className) {
        this.$el.toggleClass(className, true);
      }
    }

    if (this.$el) {
      this.$el.attr('id', this.getBaseId());
    }
  },
  touch: function () {
    this.touch_state = true;
    return this;
  },
  touched: function () {
    return this.touch_state;
  },
  isRequired: function () {
    return !!this.options.definition.required;
  },
  render: function () {
    this.$el.html(this.template({ definition: this.def }));
    return this;
  },
  focus: function () {
    var el = this.$(':focusable:first');

    if (el.is(':radio:not(:checked)')) {
      var checkedRadio = this.$(':radio[name="' + el.attr('name') + '"]:checked');

      if (checkedRadio.length) {
        checkedRadio.focus();
        return this;
      }
    }

    el.focus();
    return this;
  },
  focusLast: function () {
    var el = this.$(':focusable:last');

    if (el.is(':radio:not(:checked)')) {
      var checkedRadio = this.$(':radio[name="' + el.attr('name') + '"]:checked');

      if (checkedRadio.length) {
        checkedRadio.focus();
        return this;
      }
    }

    el.focus();
    return this;
  },
  field_name: function () {
    return this.options.definition.name;
  },
  multiple_fields: function () {
    return [];
  },
  field_title: function () {
    return this.options.definition.title;
  },
  notEmpty: function () {
    /*jshint -W116 */
    return this.val() != null;
    /*jshint +W116 */
  },
  error_class: 'error',
  toError: function (text, errorId) {
    return new FormError(text, this, errorId);
  },
  errors: function (errs) {
    var errors = !this.require_valid() ? [this.resources.fill(this.resources.PrimaryResources.BlankIsRequired, this.field_title())] : [];
    if (_.isArray(errs) && errs.length) {
      errors = errs.concat(errors);
    }
    errors = invokeCallbacks('alterErrors', {
      val: this.val(),
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors;
    if (!this.def.children) {
      this.trigger(errors.length ? 'invalid' : 'valid');
      errors = _.map(errors, this.toError, this);
    }
    return errors;
  },
  events: {
    'change *': 'touch' // This is a hack. See: https://github.com/jashkenas/backbone/pull/2650 - Bjorn
  },
  require_valid: function () {
    // Valid if it's filled, or doesn't need to be filled
    var require_valid = (this.val() !== null) || !(this.options.definition.required);

    // TODO: Bjorn, remove or change this when you fix the recurring
    // options

    // If we think this is not valid, deal with a couple special cases
    // which we'll say are not "required" when they ought to be until
    // the recurring fixes are done.
    // After the recurring fixes there are weird chaining cases like
    //  - Make this contribution isn't required unless Recurring box is
    //    checked
    //  - "This Date" textfield isn't required unless the until select
    //    box has "This Date" selected.
    // For an example of a contrib form with all the recurring things,
    // check out this form on dev9: -1945555039024054272

    if (!require_valid && (this.field_name() === 'SelectedFrequency' || this.field_name() === 'SelectedDuration')) {
      require_valid = true;
    }

    return invokeCallbacks('alterRequireValid', {
      val: this.val(),
      field_name: this.field_name(),
      require_valid: require_valid,
      form_def: this.options.definition
    }).require_valid;
  },
  val: function () {
    return null;
  },
  setval: function () {
    return this.touch();
  },
  hideOrShow: function () {
    if (this.shouldHide()) {
      this.$el.hide();
    } else {
      this.$el.show();
    }
    return this;
  },
  shouldHide: function () {
    var form = this.options.formview;
    return (form && form.options && form.options.smart && !form.options.clickedNotMe && this.require_valid());
  },
  isVisible: function () {
    return this.$el.is(':visible');
  },
  renderFeedback: function (e) {
    if (e) {
      e.stopPropagation();
    }
    var errors = this.errors();
    return this.renderFeedbackWithErrors(errors);
  },
  clearFeedback: function () {
    this.$el.removeClass(this.error_class);
    if (this.options.formview.parent.options.inline_error_display === 'true') {
      this.$('small.' + this.error_class).remove();
    }
    this.$(':input').removeClass('noValue');
    return this;
  },
  renderFeedbackWithErrors: function (errors) {
    if (errors.length) {
      var $label = this.$el; //this.$('label:first');
      var type = this.options.definition.type;
      $label.addClass(this.error_class);
      if (this.options.formview.parent.options.inline_error_display === 'true' && type !== 'checkbox') {
        $label.find('small.' + this.error_class).remove();
        $label.append(this.error_template({ 'error_text': errors.join(', '), 'behavior_classes': this.error_class }));
      }
    } else {
      this.clearFeedback();
    }
    return errors;
  },
  getFieldPrefix: function (fieldName, subString) {
    var indexOfName = fieldName.indexOf(subString);
    return indexOfName ? fieldName.substring(0, indexOfName) : '';
  },
  dispose: function () {
    //noop
  },
  toFloat: function (value, precision) {
    return parseFloat(accounting.toFixed(value, precision));
  },
  isActive: function () {
    // is this element considered active based on the current contact mode of the form
    return !this.def.contactMode || this.options.formview.contactMode === this.def.contactMode;
  },
  getBaseId: function () {
    var parent = this.options.parent || this.options.formview;
    var parentBaseId = parent && parent.getBaseId ? parent.getBaseId() : '';
    return parentBaseId + '-' + this.name;
  }
});
