export default function placeholderAttr(name, resources) {
  var placeholders = {
    AdditionalContributionValue: '$0.00',
    EmailAddress: resources.PrimaryResources.EmailPlaceholder || '',
    TwitterHandle: resources.PrimaryResources.TwitterPlaceholder || '',
    FacebookProfileUrl: resources.PrimaryResources.FacebookPlaceholder || ''
  };
  var lookup = name.replace(/(Email)$/, '$1Address').replace(/^Work/, '');
  var placeholder = placeholders[lookup] || placeholders[lookup.replace('Organization', '')];
  return placeholder && 'placeholder="' + placeholder + '"';
}
