import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import { accounting } from 'lib/accounting';
import mailcheck from 'lib/mailcheck';
import autocomplete from 'data/autocomplete';
import placeholder from 'data/placeholder';
import assetUrl from 'app/assetUrl';

var $ = Backbone.$,
  _ = Backbone._;
var trim = (''.trim ? function (s) { return s.trim(); } : function (s) { return s.replace(/^\s+|\s+$/g, ''); });
var textInputType = /^(text|email|tel|url)$/i;
// there is a programmatically changing decimal regex in another section of this file
var patterns = {
  /* jshint -W101 */
  number: /^-?\d*(\.\d*)?$/,
  creditCard: /^([0-9]{4,6}\s?){3,4}([0-9]{2})?$/,
  twitter: /^@?[A-Za-z0-9_]{1,15}$/,
  postalCode: /^[a-zA-Z\d\s\-,#\.+]+$/,
  postalCodeUS: /^\d{5}([\-]\d{4})?$/,
  postOfficeBox: /^((?:p(?:[o|0]st|(?:\.*)))\s*(?:(?:[o|0](?:ffice?|(?:\.*)))|(?:b[o|0]x)))\s*(b[o|0]x)*\s#?(\d+)?/i,
  phone: /^(?:\+?1[\-. ]?)?(?:\(?([2-9]\d{2})\)?)[\-. ]?([2-9]\d{2})[\-. ]?(\d{4})(?:\s{0,2}(?:ext(?:ension)?|ex|x)\.?\s?(\d{1,5}))*$/,
  facebook: /^https?:\/\/(?:(?:www|m)\.)?facebook\.com\/(?:(?:profile\.php\?id=([0-9]+))|((?!profile\.php)[a-zA-Z0-9.]{5,}))$/,
  currency: /^(\$?[+\-]?\s*[0-9]{1,3}(?:[0-9]*(?:[.,][0-9]{1,2})?|(?:,[0-9]{3})*(?:\.[0-9]{1,2})?|(?:\.[0-9]{3})*(?:,[0-9]{1,2})?))$/,
  email: /^([\w!#$%&'*+\-\/=?\^`\{\|\}~]+\.)*[\w!#$%&'*+\-\/=?\^`\{\|\}~]+@((((([a-zA-Z0-9]{1}[a-zA-Z0-9\-]{0,62}[a-zA-Z0-9]{1})|[a-zA-Z])\.)+[a-zA-Z]{2,62})|(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?)$/,
  integer: /^-?\d+$/,
  positiveInteger: /^\d+$/,
  negativeInteger: /^-\d+$/
  /* jshint +W101 */
};

function patternAttr(type) {
  if (type && patterns[type]) {
    var pattern = ('' + patterns[type]).replace(/^\/|(\/([igm]+)?)$/g, '');
    return 'pattern="' + pattern + '"';
  }
  return null;
}

export default FieldView.extend({
  __name__: 'TextFieldView',
  tagName: 'label',
  className: function () {
    return 'at-text   ' + this.options.definition.name;
  },
  events: {
    'change': 'renderFeedback',
    'input input': 'hasValue',
    'change [name$="PostalCode"]': 'zipLookup',
    'change [name$="SuggestedAmount"]': 'amountChanged',
    'click .didYouMean': 'useSuggestion',
    'change *': 'touch' // This is a hack. See: https://github.com/jashkenas/backbone/pull/2650 - Bjorn
  },
  pattern: patterns,
  attr: {
    pattern: patternAttr
  },
  formatOtherAmount: function (e) {
    e.currentTarget.value = accounting.toFixed(e.currentTarget.value, 2).replace(/\.00$/, '');
  },
  renderInfo: function (e) {

    // If the validation is for a residential address do not allow user to enter a P.O. Box
    if (patterns.postOfficeBox.test(e.currentTarget.value)) {
      var text = this.resources.PrimaryResources.ResidentialAddressWarnPoBox;
      if (this.$suggestion) {
        this.$suggestion.text(text);
      } else {
        this.$suggestion = $('<small class="info">' + text + '</small>');
        this.$el.append(this.$suggestion);
      }
    } else {
      this.clearSuggestion();
    }

  },
  renderSuggestion: function (e) {
    var suggestion = mailcheck(e.currentTarget.value);
    if (suggestion && suggestion.domain.indexOf('.') !== -1) {
      if (this.$suggestion) {
        this.$suggestion.find('a').text(suggestion.full);
      } else {
        this.$suggestion = $(
          this.resources.fill(
            '<small class="didYouMean info">{{0}} <a href="#applySuggestion">{{1}}</a>{{2}}</small>',
            this.resources.PrimaryResources.DidYouMean,
            suggestion.full,
            this.resources.PrimaryResources.DidYouMeanQuestionMark
          )
        );
        this.$el.append(this.$suggestion);
      }
    } else {
      this.clearSuggestion();
    }
  },
  clearSuggestion: function () {
    if (this.$suggestion) {
      this.$suggestion.remove();
      this.$suggestion = null;
    }
  },
  useSuggestion: function (e) {
    e.preventDefault();
    this.setval($(e.currentTarget).find('a').text());
    this.clearSuggestion();
    this.clearFeedback();
    this.input.focus();
  },
  amountChanged: function () {
    var amount = this.val().Amount;
    this.contributionAmountModel.setBaseAmount(amount);
  },
  onGiftChanged: function (giftInfo) {
    var amount = this.contributionAmountModel.get('baseAmount');
    var floatAmount = parseFloat(accounting.toFixed(amount, 2));
    if (giftInfo.newAmount > floatAmount) {
      this.setval(giftInfo.newAmount);
    }
  },
  zipLookup: function (e) {
    var zip = e.currentTarget.value;
    // use prefix of the fields to find the others
    var fieldName = e.currentTarget.name;
    var sub = this.parent.subviews;
    var prefix = this.getFieldPrefix(fieldName, 'PostalCode');
    var city = sub[prefix + 'City'];
    var state = sub[prefix + 'StateProvince'];
    var country = sub[prefix + 'Country'];
    var datalist = this.options.templates.datalist;
    var isUnitedStates = !country || (country && this.def.validation !== 'postalCode');
    var hasUserInput = false;
    var toggleUserInput = function () { hasUserInput = true };
    if (zip && (city || state) && isUnitedStates && !this.errors().length) {
      if (city) {
        city.$('input').one('input', toggleUserInput);
      }
      $.jsonp({
        url: assetUrl.zip('with-callback/' + e.currentTarget.value.substring(0, 5) + '.json'),
        cache: true,
        callback: 'zipapicallback'
      }).then(function (data) {
        if (city && data.locality) {
          if (data.localities) {
            city.$el
              .find('datalist').remove().end()
              .append(datalist({
                id: 'localities',
                options: data.localities
              }))
              .find('input')
              .attr('list', 'localities');
          }
          if (!hasUserInput) {
            city.setval(data.locality).clearFeedback();
            city.$('input').off('input', toggleUserInput);
          }
        }

        if (state && data.region && data.region.abbr) {
          state.setval(data.region.abbr);
        }
      });
    }
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    _.bindAll(this, 'renderSuggestion', 'clearSuggestion', 'clearFeedback', 'renderInfo');
    this.$el.on('change', 'input', this.trim);
    if (this.name === 'SuggestedAmount') {
      this.contributionAmountModel = this.options.formview.contributionAmountModel;
      this.$el.on('blur', 'input', this.formatOtherAmount);
      window.nvtag.on('giftChanged', this.onGiftChanged, this);
    }

    if (this.name === 'MatchingWorkEmail') {
      this.listenTo(this.options.formview, 'workEmailChanged', this._onWorkEmailChanged);
    }

    var self = this;
    this.once('invalid', function onInvalidInput() {
      self.$el.on('input', 'input', _.debounce(this.renderFeedback, 150));
    });
  },
  renderFeedbackWithErrors: function (errors) {
    if (errors.length) {
      this.clearSuggestion();
    }
    return FieldView.prototype.renderFeedbackWithErrors.call(this, errors);
  },
  trim: function (e) {
    if (e.currentTarget.value && textInputType.test(e.currentTarget.type)) {
      e.currentTarget.value = trim(e.currentTarget.value);
    }
  },
  context: function () {
    var context = {},
      type = (this.def.validation || this.def.valueType);

    context = {
      name: this.name,
      labelhide: this.def.labelhide || this.options.labels === 'placeholder',
      label: this.def.label || this.title,
      title: this.title,
      required: this.def.required && 'required',
      readonly: this.def.readonly && 'readonly disabled',
      value: this.def.default_value || '',
      maxlength: this.def.maxlength || '',
      autocomplete: autocomplete(this.name),
      placeholder: placeholder(this.name, this.resources),
      type: 'text',
      precision: this.def.precision
    };

    if (type === 'postalCode' &&
      _.findIndex(this.parent.def.children,
        { name: this.getFieldPrefix(this.name, 'PostalCode') + 'Country' }) < 0) {
      type = 'postalCodeUS';
    }

    var pattern;
    if (type === 'decimal' || type === 'positiveDecimal' || type === 'negativeDecimal') {
      // decimal regex calcs here
      // Default the precision to empty string, which will allow unlimited precision.
      var precision = context.precision || '';
      var precisionPattern;
      var prefix;
      if (type === 'positiveDecimal') {
        prefix = '';
      }
      else if (type === 'negativeDecimal') {
        prefix = '-';
      }
      else {
        prefix = '-?';
      }

      precisionPattern = new RegExp('^' + prefix + '(\\d+(\\.\\d{0,' + precision + '})?)|((\\.\\d{1,' + precision + '}))$');
      patterns['decimal' + precision] = precisionPattern;
      pattern = patternAttr('decimal' + precision);
    }
    else {
      pattern = patternAttr(type);
    }

    if (pattern) {
      context.pattern = pattern;
      context.patternKey = type;
    }

    // This is a default value, but this should probably be handled by Oberon.
    if (this.name === 'AdditionalContributionValue') {
      if (this.title === this.name || this.title === '') {
        context.title = 'Additional Contribution';
      }
    }

    var parent = this.options.parent;
    if (parent.name === 'PaymentInformation' && 'required' in parent.def && !parent.def.required) {
      context.required = '';
    }

    if (context.labelhide) {
      context.placeholder = 'placeholder="' + _.escape(this.title) + (context.required ? '*"' : '"');
    }

    if (this.name === 'TwitterHandle') {
      context.pattern = patternAttr('twitter');
      context.maxlength = 16;
    } else if (this.name === 'FacebookProfileUrl') {
      context.pattern = patternAttr('facebook');
    }

    // Some special cases
    if (type === 'phone') {
      // keep to maintain compatibility with Oberon forms, but all other forms use new phone input
      context.type = 'tel';
    } else if (type === 'email') {
      context.type = 'email';
    } else if (type === 'url') {
      context.type = 'url';
    } else if (type === 'postalCodeUS') {
      context.type = 'tel';
      context.maxlength = 10;
    }

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    var context = this.context();
    this.$el.html(this.template(context));

    // Store this for later in case we need it.
    this.$input = this.$(':input');
    this.input = this.$input.get(0);

    if (context.patternKey) {
      this.$input.data('pattern', context.patternKey);
    }

    if (/Email/.test(this.name)) {
      this.$input.on('change', this.renderSuggestion);
    }

    // The employer matching section has some special logic related to the work email value
    if (this.name === 'WorkEmail') {
      var self = this;
      this.$input.on('change input', function (e) {
        self.options.formview.trigger('workEmailChanged', e.currentTarget.value);
      });
    }

    if (this.def.validation === 'residentialAddress') {
      this.$input.on('change', this.renderInfo);
    }

    return this;
  },
  _onWorkEmailChanged: function (value) {
    // When work email changes, we want to update the matching work email
    if (this.name === 'MatchingWorkEmail' && !this.touched()) {
      this.setval(value, true);
    }
  },
  errors: function () {
    // 'Other' response textbox may not be visible.  Don't bother validating field
    // if it is not visible, or the input has never rendered.
    // (Much of the validation logic here is coupled to the input already existing)
    if (!this.$el.is(':visible') || !this.input) {
      return [];
    }
    var errors = !this.require_valid() ? [this.resources.fill(this.resources.PrimaryResources.BlankIsRequired, this.title)] : [];
    var val = this.input.value;

    if (!errors.length && val) {
      if (/^(currency|number)$/.test(this.def.validation)) {
        var max = this.def.valueMax || Infinity;
        var min = this.def.valueMin || -Infinity;
        var num = accounting.unformat(val);
        var format = function (n) {
          return accounting.toFixed(n, 2).replace(/\.00$/, '');
        };

        if (_.isNaN(num)) {
          errors.push(this.resources.PrimaryResources.PleaseEnterValidAmount);
        } else if (num < min) {
          errors.push(this.resources.fill(this.resources.PrimaryResources.AmountMustBeAtLeast, format(min)));
        } else if (num > max) {
          errors.push(this.resources.fill(this.resources.PrimaryResources.AmountMustBeLessThan, format(max)));
        }
      }
      if (!errors.length) {
        var patternError = (this.options.definition.validation === 'phone') ?
          this.resources.PrimaryResources.ValidUsPhoneNumber :
          this.resources.fill(this.resources.PrimaryResources.PleaseEnterValidBlank, this.title);
        if (this.input.validity) {
          if (this.input.validationMessage) {
            errors.push(patternError);
          }
        } else if (this.input.pattern) {
          var type = this.$input.data('pattern');
          var pattern = patterns[type] || (new RegExp(this.input.pattern));
          if (!pattern.test(this.input.value)) {
            errors.push(patternError);
          }
        }
      }
    }

    this.trigger(errors.length ? 'invalid' : 'valid');

    return _.map(invokeCallbacks('alterErrors', {
      val: this.getTextFieldVal(),
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors, this.toError, this);
  },
  hasValue: function (e) {
    if (e.currentTarget.value) {
      this.$(e.currentTarget).removeClass('noValue');
    } else {
      this.$(e.currentTarget).addClass('noValue');
    }
  },
  getTextFieldVal: function () {
    var val = this.val();
    if (this.name.toLowerCase().indexOf('phone') > -1) {
      return val ? val[this.name] : '';
    } else {
      return val;
    }
  },
  val: function () {
    // If we're getting the value before the input is created, use the default value
    // otherwise, get the value from the input
    var val = this.$input ? this.$input.val() : this.def.default_value || '';
    val = trim(val);

    // Special cases
    if (this.name === 'SuggestedAmount') {
      val = { 'Amount': accounting.toFixed(val, 2) };
    } else if (this.name === 'TwitterHandle') {
      val = val.replace(/^@/, '');
    } else if (this.name === 'StateProvince') {
      val = $('[name="StateProvince"]').val();
    } else if (this.name.toLowerCase().indexOf('phone') > -1) {
      var phoneType = this.name;
      var phoneNumber = val;
      if (!phoneNumber) {
        val = null;
      } else {
        val = {};
        val[phoneType + 'CountryCode'] = 'us';
        val[phoneType + 'InternationalDialingPrefix'] = 1;
        val[phoneType] = phoneNumber;
      }
    }

    return (val !== '') ? val : null;
  },
  setval: function (value, leaveUntouched) {
    if (this.name === 'SuggestedAmount' && _.isObject(value)) {
      if (value.SuggestedAmount) {
        value = value.SuggestedAmount;
      } else if (value.Amount) {
        value = value.Amount;
      } else {
        return;
      }
    } else if (this.name.toLowerCase().indexOf('phone') > -1 && value[this.name] !== undefined) {
      value = value[this.name];
    }

    this.$('input').val(value);

    if (this.name === 'SuggestedAmount') {
      this.amountChanged();
    }

    if (this.name === 'WorkEmail') {
      this.options.formview.trigger('workEmailChanged', value);
    }

    if (!leaveUntouched) {
      return this.touch().hideOrShow();
    } else {
      return this.hideOrShow();
    }


  },
  type: 'textfield'
});
