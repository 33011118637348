import FieldView from 'app/views/FieldView';
import matchPro from 'app/matchPro';

// Handles interactions with the MatchPro streamlined input
export default FieldView.extend({
  __name__: 'MatchProView',
  tagName: 'label',
  className: function () {
    return 'at-text ' + this.options.definition.name;
  },
  isRequired: function () {
    return false;
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    this.template = this.options.templates.match_pro_input;
  },
  render: function () {
    var context = this.context();
    this.$el.html(this.template(context));
    this.initPlugin();
  },
  initPlugin: function () {
    matchPro.setupStreamlinedInput();
  },
  context: function () {
    var context = {
      name: this.name,
      labelhide: this.def.labelhide || this.options.labels === 'placeholder',
      label: this.def.label || this.title,
    };
    return context;
  },
  val: function () {
    // There are several hidden fields in the plugin
    // Specific input names include doublethedonation_status, doublethedonation_entered_text, doublethedonation_company_id, doublethedonation_company_name
    // There are also several duplicate fields with the prefix doubledonation instead of doublethedonation -- we can ignore those
    // Convert the set of all such fields to a lookup
    var dtdHiddenInputs = this.$('.dtd-streamlined-plugin input[type="hidden"]');
    var values = {};
    for (var i = 0; i < dtdHiddenInputs.length; i++) {
      var input = dtdHiddenInputs[i];
      values[input.name] = input.value;
    }

    return {
      MatchProIsFound: values.doubledonation_status === 'found',
      MatchProCompanyId: values.doubledonation_company_id,
      MatchProCompanyName: values.doublethedonation_company_name
    };
  }
});
