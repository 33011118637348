import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import otherOption from 'app/views/helpers/otherOption';

var $ = Backbone.$,
  _ = Backbone._;
export default FieldView.extend({
  __name__: 'RadiosView',
  tagName: 'div',
  events: {
    'keyup input': 'cycleOptions',
    'change': 'changeHandler'
  },
  changeHandler: function () {
    this.clearFeedback();
    this.showHideOther();
    this.updateDescription();
  },
  initialize: function () {
    FieldView.prototype.initialize.apply(this);
    _.bindAll(this, 'cycleOptions', 'updateDescription');
  },
  className: function () {
    return 'form-unit form-unit-radio form-item-' + this.options.definition.name.toLowerCase();
  },
  context: function () {
    var context = {
      name: this.name,
      name_lower: this.name.toLowerCase(),
      title: this.title,
      required: this.def.required ? 'required' : '',
      options: [],
      includeDescriptionContainer: false,
      id: this.getBaseId()
    };

    var isOrdered = this.def.ordered;
    _.each(this.def.options, function (option) {
      // if isOrdered, the options property will be an array of objects with 'display' and 'value' properties
      // if not isOrdered, the options property will be an object with a single key + value
      //  this second case is due to FormDefUtils fixing the formdef
      var display = isOrdered ? option.display : _.values(option)[0];
      var value = isOrdered ? option.value : _.keys(option)[0];

      // only add the description container if at least one option has a description
      // this only applies to the "ordered" style of options
      context.includeDescriptionContainer = !!(isOrdered && option.description) || context.includeDescriptionContainer;

      // Strip punctuation, dollar signs if present as they can't be
      // in the id
      var id = value.replace('.', '').replace('$', '');
      context.options.push({ value: value, display: display, id: id });
    });

    if (this.def.default_value) {
      var opt = _.where(context.options, { value: this.def.default_value });
      if (opt.length > 0) {
        opt[0].checked = 'checked';
      }
    }

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    var ctx = this.context();
    this.$el.html(this.template(ctx));
    var self = this;

    // 'other' textfield may need to be rendered
    // call common method to identify 'other' element and bind it to this one
    // may render an 'other' textfield depending on def and presence of 'other' option
    otherOption.render(self);

    // if at least one option has a description, be sure to check if a description needs to be added
    if (ctx.includeDescriptionContainer) {
      _.defer(function () {
        self.updateDescription();
      });
    }
    return this;
  },
  updateDescription: function () {

    // if the description container isn't present, no need to continue
    var $description = this.$el.find('.radio-description');
    if (!$description || !$description.length) {
      return;
    }

    var selectedValue = this.val();
    var selectedOpt = selectedValue === null ? null : _.find(this.def.options, { value: selectedValue });
    var description = selectedOpt && selectedOpt.description ? selectedOpt.description : null;

    var newClass = 'radio-description';
    description = description || '';
    if (selectedValue !== null && selectedValue !== undefined && selectedValue !== '') {
      newClass += ' radio-description-value-' + selectedValue;
    }
    $description.html(description);
    $description.attr('class', newClass);
  },
  val: function () {
    return this.$('input:radio:checked').val() || null;
  },
  setval: function (value) {
    this.clearFeedback();
    /* jshint -W109 */
    var oldValue = this.val();
    var predefined_option = $("input:radio[name='" + this.name + "'][value='" + value + "']");
    /* jshint +W109 */
    if (predefined_option.length) {
      predefined_option.prop('checked', true);
    }
    // If the value has actually changed, we may need to show/hide a text box associated
    // with an 'other' option
    // It must be deferred because 'render' has not happened at point when query string values
    // are applied to form fields
    if (oldValue !== this.val()) {
      this.showHideOther();
    }

    this.updateDescription();

    return this.touch();
  },
  clearFeedback: function () {
    this.$el.removeClass(this.error_class);
    this.$('.radios').children().removeClass(this.error_class);
    if (this.options.formview.parent.options.inline_error_display === 'true') {
      this.$('small.' + this.error_class).remove();
    }
    this.$(':input').removeClass('noValue');
    return this;
  },
  renderFeedbackWithErrors: function (errors) {
    if (errors.length) {
      this.$el.addClass(this.error_class);
      var $radios = this.$('.radios');
      var type = this.options.definition.type;
      $radios.find('label').addClass(this.error_class);
      if (this.options.formview.parent.options.inline_error_display === 'true' && type !== 'checkbox') {
        this.$('small.' + this.error_class).remove();
        $radios.before(this.error_template({
          'error_text': errors.join(', '),
          'behavior_classes': this.error_class
        }));
      }
    } else {
      this.clearFeedback();
    }
    return errors;
  },
  showHideOther: function () {
    // Is 'other' was selected, we might need to 'unhide' the other text field
    if (this.def.other) {
      otherOption.showHide(this);
    }
  },
  // Note: this function is used outside of implementations of RadiosViews.
  // This means that "this" will point to that specific implementation, not anything specific to RadiosView
  cycleOptions: function (event) {
    // IME users may cause some issues, this prevents accidentally detecting those keypresses
    if (event.isComposing || event.keyCode === 229) {
      return;
    }

    // event.key is the current standard, but there's no guarantee the browser will
    // support it
    var shift_r = event.shiftKey && (event.key === 'R' || event.keyCode === 82);

    var r = !event.shiftKey && (event.key === 'r' || event.keyCode === 82);

    // neither button has been pressed, bail
    if (!shift_r && !r) {
      return;
    }

    var cur = this.$('input:focus');
    cur.blur();

    // if we're on a regular radio button, we want to uncheck it
    if (cur.prop('type') === 'radio') {
      cur.prop('checked', false);
    }

    // if we're on other amount, we need to remove the required attribute so the form
    // can submit and the styles can update accordingly
    if (cur.prop('required')) {
      cur.prop('required', false);
    }

    var next;
    var siblings;

    // shift+r has been pressed, moving backwards
    if (shift_r) {
      var prevLabel = cur
        .parent()
        .prev();

      // if there's no previous input, we've reached the first one.
      // jQuery objects aren't null or undefined, though, so we need to check
      // the length to see if it actually found something
      if (prevLabel.length > 0) {
        // found something! move to it and focus it
        next = prevLabel
          .children('input:radio');
      } else {
        siblings = cur.parent().siblings();
        if (siblings.length > 0) {
          // since we're at the beginning of the list, we'll need to go to the last
          // possible input. start from the original element and just jump straight
          // to the last input
          next = siblings
            .last()
            .children('input:radio');
        } else {
          // No other radio options, so refocus on current radio
          next = cur;
        }
      }
    } else if (r) { // just r has been pressed, moving forward
      var nextLabel = cur
        .parent()
        .next();

      if (nextLabel.length > 0) {
        // we found an input. move to it and focus it
        next = nextLabel
          .children('input:radio');
      } else {
        siblings = cur.parent().siblings();
        if (siblings.length > 0) {
          // we're at the end, so jump back to the beginning
          next = siblings
            .first()
            .children('input:radio');
        } else {
          // No other radio options, so refocus on current radio
          next = cur;
        }
      }
    }

    // we've found the proper input. if it's a radio, we need to check it and focus on it
    if (next.length > 0) {
      next.prop('checked', true).focus().trigger('change');
    }
  },
  type: 'radios'
});
