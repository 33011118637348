import config from 'config/actionTagConfig';
import loadScript from 'app/loadScript';
import loadCSS from 'app/loadCSS';

var matchProScriptUrl = config.MATCH_PRO_SCRIPT_URL;
var matchProStylesUrl = config.MATCH_PRO_CSS_URL;


var loadingHasFailed = false;
var matchProLoadingPromise;

function configure(publicKey) {
  if (!publicKey) {
    throw 'MatchPro public key is required to configure MatchPro integration';
  }

  // Set up the global double donation configuration
  // Their plugin uses these values to configure itself
  // Allow clients to override the CONDENSED property if they wish
  var ddconf = window.DDCONF = window.DDCONF || {};

  if (ddconf.API_KEY && ddconf.API_KEY !== publicKey) {
    console.warn('Conflict between existing account ' + ddconf.API_KEY + ' and account ' + publicKey);
    throw 'Only one MatchPro account can be active on any given page';
  }

  ddconf.API_KEY = publicKey;
  ddconf.CONDENSED = typeof ddconf.CONDENSED === 'undefined' ? false : ddconf.CONDENSED;
}

function init() {
  if (!isConfigured()) {
    throw 'Must configure MatchPro integration before initializing';
  }

  // If the promise has succeeded or is pending then return the existing value.
  if (matchProLoadingPromise && loadingHasFailed !== false) {
    return matchProLoadingPromise;
  }

  // On the first call (or if it previously failed), try to load the assets;
  // since we're actively trying, set the failure flag to false
  loadingHasFailed = false;
  var cssLoad = loadCSS(matchProStylesUrl);
  var jsLoad = initJs();

  matchProLoadingPromise = Promise.all([cssLoad, jsLoad]).then(
    function onLoad() {
      if (window.doublethedonation) {
        return window.doublethedonation;
      }
      throw 'Loading failed silently';
    }).catch(
      function onError(e) {
        loadingHasFailed = true;
        throw e;
      }
    );

  return matchProLoadingPromise;
}

function initJs() {
  if (window.doublethedonation) {
    return Promise.resolve();
  }

  if (!isConfigured()) {
    return Promise.reject('Not configured');
  }

  return loadScript.nocache(matchProScriptUrl, 15000).promise();
}

// Checks that all necessary configuration exists
function isConfigured() {
  if (!matchProScriptUrl || !matchProStylesUrl || !window.DDCONF || !window.DDCONF.API_KEY) {
    return false;
  }
  return true;
}

// Sets up the widget that's embedded in the thank you page
// The library is looking for a div with id="dd-container"
function setupConfirmationPageWidget(donationIdentifier, companyId, campaign) {
  var dtd_selected_company = companyId;
  return init().then(function (doublethedonation) {
    doublethedonation.plugin.load_config();
    doublethedonation.plugin.load_plugin();
    if (donationIdentifier) {
      doublethedonation.plugin.set_donation_identifier(donationIdentifier);
    }
    if (campaign) {
      doublethedonation.plugin.set_donation_campaign(campaign);
    }

    if (companyId) {
      doublethedonation.plugin.set_company(dtd_selected_company);
    }
  });
}


function setupStreamlinedInput() {
  return init().then(function (doublethedonation) {
    var plugin = doublethedonation.plugin;
    plugin.load_config();
    plugin.load_streamlined_input();
  });
}

export default {
  configure: configure,
  init: init,
  isConfigured: isConfigured,
  setupStreamlinedInput: setupStreamlinedInput,
  setupConfirmationPageWidget: setupConfirmationPageWidget
};
