import _ from 'lib/lodash.custom';
import parseUrl from 'lib/parseUrl';
import urlUtilities from 'app/urlUtilities';

var socialSharePopup = function (event, socialMediaUrl, sharedUrl, doNotModifyForEmbedded, winWidth, winHeight, queryStrings, title) {
  event.stopPropagation();

  if (_.isUndefined(sharedUrl) || _.isNull(sharedUrl)) {
    console.log('socialSharePopup: No url specified for sharing window.');
    return;
  }

  // Depending on whether or not this form is embedded, we need to share a different URL than
  // what may have been returned by the OA server
  var parsedSharedURL = parseUrl(decodeURIComponent(sharedUrl));
  var parsedLocationURL = parseUrl(window.location.pathname + window.location.search);

  // Defaults if nothing passed in
  var windowTitle = title || 'Share';
  var windowWidth = winWidth || 520;
  var windowHeight = winHeight || 350;
  var queryStringDict = queryStrings || {};

  // the parseUrl function basically generates a dummy link (<a>) element
  // so we let the engine parse these strings
  if (!doNotModifyForEmbedded && parsedSharedURL.href !== parsedLocationURL.href) {
    // This form is embedded, so we need to alter the sharedUrl slightly
    // We basically add the query strings for our form to whatever is currently
    // in the browser location field
    sharedUrl = encodeURIComponent(urlUtilities.mergeUrlParameters(parsedLocationURL.href, parsedSharedURL.search, window.location.hash, queryStringDict));
  }


  // Work out the boundaries of the current browser window
  var windowLeftEdge = window.screenX;
  var windowTopEdge = window.screenY;

  // Setup the coordinates so that the social media url popup appears in the center of the current browser window
  var winTop = windowTopEdge + (Math.abs(window.innerHeight - windowHeight) / 2);
  var winLeft = windowLeftEdge + (Math.abs(window.innerWidth - windowWidth) / 2);

  // Encode the URL for the shared form (may include query parameters)
  // NOTE: sharedUrl should be url encoded
  var url = socialMediaUrl + sharedUrl;
  // Setup the position for the popup window
  var position = 'width=' + windowWidth + ',height=' + windowHeight + ',top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0';

  // Launch the windows share popup
  window.open(url, windowTitle, position);
};

export default {
  popupFunction: socialSharePopup
};
