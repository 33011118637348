import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import RadiosView from 'app/views/RadiosView';
import { accounting } from 'lib/accounting';
import currency from 'app/views/helpers/currency';

var _ = Backbone._;
export default RadiosView.extend({
  __name__: 'AmountView',
  tagName: 'div',
  className: 'form-item form-type-radios form-item-selectamount',
  events: {
    'change   *': 'touch',
    'click    [name^=OtherAmount]': 'onBogusOtherAmount',
    'focus    [name^=OtherAmount]': 'onFocusOtherAmount',
    'blur     [name^=OtherAmount]': 'onBlurOtherAmount',
    'keydown  [name^=OtherAmount]': 'onKeyDownOtherAmount',
    'keyup    [name^=OtherAmount]': 'onTypeOtherAmount',
    'focus    :radio[value="other"]': 'onFocusOtherAmountRadio',
    'keypress [name^=OtherAmount]': 'ensureAmountBeforeSubmit',
    'click    a': 'onButtonPress',
    'change   :radio': 'onSelectAmount',
    'keyup    input': 'cycleOptions'
  },
  initialize: function () {
    RadiosView.prototype.initialize.call(this);
    _.bindAll(this, 'updateForNewCurrencyOrFrequency');

    this.contributionAmountModel = this.options.formview.contributionAmountModel;
    this.contributionRecurrenceModel = this.options.formview.contributionRecurrenceModel;

    if (this.contributionRecurrenceModel.allowOneTimeFrequency) {
      // when using the new layout for frequency, we want to subscribe to changes
      // so we can swap out the amount options appropriately
      // for the legacy layout, we don't want to listen to this change at all
      this.contributionRecurrenceModel.on('change:frequency', this.updateForNewCurrencyOrFrequency);
    }

    this.listenTo(currency, 'change', this.updateForNewCurrencyOrFrequency);

    // register
    window.nvtag.on('giftChanged', this.onGiftChanged, this);
  },
  context: function () {
    var clientFormatting = !!(this.options.formview.model.get('metadata').clientAmountFormatting);
    var selectedCurrency = currency.getCurrency();

    // When supporting multiple currencies, we use an array of children for each currency
    // The child contains the correct options to display for that currency
    // To support existing form definitions we fall back to the main options object
    // In either case we clone the options before modifying them
    var activeDef = this.getActiveDefinition();
    var amountOptions = _.cloneDeep(activeDef.options);

    _.each(amountOptions, function (o) {
      if (o.val !== 'other') {
        o.isLong = '';
        var amount = currency.format(o.val);
        if (amount && activeDef.suffix) {
          amount += activeDef.suffix;
        }
        if (!clientFormatting && o.display) {
          o.display = o.display.replace(/\.00(\))?$/, '$1').replace('$', selectedCurrency.symbol);
        } else if (o.display) {
          o.display += ' (' + amount + ')';
        } else {
          o.display = amount;
        }

        var displayLength = o.display.length;
        if (displayLength >= 7) {
          o.isLong = 'long';
          if (displayLength >= 10) {
            o.isLong = 'veryLong';
            if (displayLength > 10) {
              o.isLong = 'incrediblyLong';
              if (displayLength > 20) {
                o.isLong = 'tooLong';
              }
            }
          }
        }
      }
    });

    var context = {
      name_lower: this.name.toLowerCase(),
      amount_options: amountOptions,
      currency: selectedCurrency,
      showOther: _.reduce(amountOptions, function (option, iterator, memo) {
        return (option.val === 'other') || memo;
      }),
      isUSD: selectedCurrency.code === 'USD',
      suffix: activeDef.suffix,
      default_value: activeDef.default_value
    };

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    var context = this.context();
    this.$el.html(this.template(context));
    this.$other = this.$('input[name^="OtherAmount"]');
    this.$otherAmountPrefix = this.$('span.label-otheramount-prefix');
    this.lastActive = null;
    this.setval(context.default_value);
    this.setOtherAmountPrefixVisibility();
    return this;
  },
  onButtonPress: function (e) {
    this.$(e.currentTarget).parent().trigger('click');
  },
  onSelectAmount: function (e) {
    var selectionInfo = this.getSelectedAmountInfo();


    if (e.currentTarget.value !== 'other') {
      this.$other.prop('required', false).val('');
      this.setOtherAmountPrefixVisibility();
    } else {

      this.$other.prop('required', true);

      // Prevent validation from being triggered when first going to other amount
      e.stopPropagation();
    }

    this.contributionAmountModel.setBaseAmount(selectionInfo.amount);

    return this;
  },
  onFocusOtherAmountRadio: function (e) {
    // Focus on the OtherAmount input when focusing on the other radio
    // unless coming from the OtherAmount input
    if (!e.relatedTarget || e.relatedTarget.name !== 'OtherAmount') {
      this.$other.focus();
    }
  },
  onBogusOtherAmount: function (e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  },
  onKeyDownOtherAmount: function (e) {
    this.setOtherAmountPrefixVisibility();
    // If tabbing, first go outside to the radio button to continue tabbing
    if (e.keyCode === 9) {
      this.$(':radio[value="other"]').focus();
    }
  },
  onTypeOtherAmount: function () {
    this.setOtherAmountPrefixVisibility();
    var self = this;
    setTimeout(function () {
      var selectionInfo = self.getSelectedAmountInfo();
      self.contributionAmountModel.setBaseAmount(selectionInfo.amount);
    }, 500);
    return this;
  },
  onFocusOtherAmount: function (e) {
    this.setOtherAmountPrefixVisibility();
    this.lastActive = e.currentTarget;
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.$other.on('mousewheel.disableScroll', function (e) {
      e.preventDefault();
    });
    var $radio = this.$(':radio[value="other"]');
    $radio.prop('checked', true);
    this.$other.attr('required', 'required');
  },
  onBlurOtherAmount: function (e) {
    this.setOtherAmountPrefixVisibility();
    // As we leave the input, update the formatting of the input value and make sure we set the base amount
    if (e.currentTarget.value !== '') {
      e.currentTarget.value = accounting.toFixed(e.currentTarget.value, 2).replace(/\.00$/, '');
      var selectionInfo = this.getSelectedAmountInfo();
      this.contributionAmountModel.setBaseAmount(selectionInfo.amount);
    }

    this.$other.off('mousewheel.disableScroll');

    // Defer until after blur, since the validation cares about whether the other amount field has focus
    _.defer(this.renderFeedback);

    return this;
  },
  // if the Other Amount placeholder is altered from the default "0.00" by the Other Amount Experiment,
  // hide the $ prefix if the Other Amount box is empty and unfocused
  setOtherAmountPrefixVisibility: function () {
    if (!this.$other || !this.$otherAmountPrefix) {
      return;
    }

    if (this.$other.attr('placeholder') === '0.00') {
      return;
    }

    if (this.$other.val() === '' && !this.$other.is(':focus')) {
      this.$otherAmountPrefix.hide();
    } else {
      this.$otherAmountPrefix.show();
    }
  },
  ensureAmountBeforeSubmit: function (e) {
    if (e.which !== 13) {
      return e.metaKey || // cmd/ctrl
        (e.which >= 37 && e.which <= 40) || // arrow keys
        e.which === 8 || // delete key
        /[0-9.]/.test(String.fromCharCode(e.which)); // numbers
    }
    return this.onBlurOtherAmount(e);
  },
  errors: function () {
    var errors = [], val = this.val(), amount = parseFloat(val.Amount);
    if (val.other && this.$('input[name="OtherAmount"]:focus').length === 0) {
      var activeDef = this.getActiveDefinition();
      var max = activeDef.valueMax;
      var min = activeDef.valueMin || 0.01;
      var cheapestGift = this.contributionAmountModel.get('cheapestGiftThreshold');

      if (_.isNaN(amount)) {
        errors.push(this.resources.PrimaryResources.InvalidAmount);
      } else if (!_.isFinite(amount)) {
        errors.push(this.resources.PrimaryResources.InvalidAmountInfinite);
      } else if (amount < min) {
        errors.push(this.resources.fill(this.resources.PrimaryResources.InvalidAmountMin, currency.format(min)));
      } else if (amount > max) {
        errors.push(this.resources.fill(this.resources.PrimaryResources.InvalidAmountMax, currency.format(max)));
      } else if (cheapestGift !== null && amount < cheapestGift) {
        errors.push(this.resources.fill(this.resources.PrimaryResources.InvalidAmountMin, currency.format(cheapestGift)));
      }
      // check for touched so this error doesn't occur during init
    } else if (this.touched() && amount === 0) {
      errors.push(this.resources.PrimaryResources.PleaseSelectContributionAmount);
    }

    errors = invokeCallbacks('alterErrors', {
      val: val.Amount,
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors;
    this.trigger(errors.length ? 'invalid' : 'valid');
    return _.map(errors, this.toError, this);
  },
  getSelectedAmountInfo: function () {
    var selectElement = this.$(':radio:checked')[0];
    var amount, select = this.$(':radio:checked').val();
    if (select === 'other') {
      var other = accounting.unformat(this.$other.val());
      amount = (_.isFinite(other) && other >= 0) ? other : 0;
    } else {
      amount = accounting.unformat(select) || 0;
    }

    return {
      selectElement: selectElement,
      select: select,
      amount: amount
    };
  },
  // Returns the active amount view definition, taking into account the selected currency
  getActiveDefinition: function () {
    var selectedCurrency = currency.getCurrency();
    var activeDefinition = _.cloneDeep(_.find(this.def.children, { value: selectedCurrency.code }) || this.def);
    if (activeDefinition.frequencyOptions && activeDefinition.frequencyOptions.length) {
      var frequency = this.contributionRecurrenceModel.get('frequency');
      if (frequency > 0) {
        var freqOptions = _.cloneDeep(_.find(activeDefinition.frequencyOptions, { value: frequency.toString() }));
        if (freqOptions) {
          // replace portions of active def from selected frequency options
          activeDefinition = _.extend(activeDefinition, freqOptions.options);
          activeDefinition.suffix = freqOptions.suffix;
        }
      }
    }
    return activeDefinition;
  },
  // Update the view when the currency or frequency changes
  updateForNewCurrencyOrFrequency: function () {
    // We'll need to set a value after re-rendering
    // If the current value is from the "other" box, use that; otherwise, restore the default
    var currentVal = this.val();
    var newVal = currentVal.other ? currentVal.Amount : this.getActiveDefinition().default_value;
    this.render();

    this.setval(newVal);
    this.setOtherAmountPrefixVisibility();
  },
  val: function () {
    var selectionInfo = this.getSelectedAmountInfo();
    var amount = this.contributionAmountModel.get('baseAmount');
    amount = accounting.toFixed(amount, 2);
    return { 'Amount': amount, 'other': selectionInfo.select === 'other' };
  },
  setval: function (value) {
    var amount;
    var otherAmount = this.$('input[name="OtherAmount"]');
    if (!_.isUndefined(value)) {
      if (value.SelectAmount || value.Amount) {
        amount = value.SelectAmount || value.Amount;
      }
      else if (_.isFinite(value)) {
        amount = value;
      }
    }
    if (_.isUndefined(amount)) {
      return;
    }

    // make sure the amount is within our bounds
    var activeDef = this.getActiveDefinition();
    var minAmount = parseFloat(accounting.toFixed(activeDef.valueMin, 2));
    var maxAmount = parseFloat(accounting.toFixed(activeDef.valueMax, 2));
    var amountFloat = parseFloat(accounting.toFixed(amount, 2));

    if (amountFloat < minAmount || amountFloat > maxAmount) {
      amount = activeDef.default_value;
    }

    if (_.isUndefined(amount) && this.$(':radio[value="other"]').length) {
      amount = 'other';
    }
    // Wait until we see if Amount exists before doing this. Else it'll
    // end up with 0.00 and will attempt to fill with that.
    if (amount !== 'other') {
      amount = accounting.toFixed(amount, 2);
      amount = (parseFloat(amount) <= 0) ? activeDef.options[0].val : amount;
    }

    var predefined_option = this.$('.NonRecurringButtons :radio[value="' + amount + '"]');
    if (predefined_option.length) {
      predefined_option.prop('checked', true);
      this.$other.prop('required', false).val('');
    } else {
      // Attempted to set the radio view, but if that didn't work (because it wasn't an option)
      // we need to set the other field.
      otherAmount.val(accounting.toFixed(amount, 2).replace(/\.00$/, ''));
      this.$(':radio[value="other"]').prop('checked', true).trigger('change');

    }

    var selectionInfo = this.getSelectedAmountInfo();
    this.contributionAmountModel.setBaseAmount(selectionInfo.amount);

    return this.touch();
  },
  clearFeedback: function () {
    if (this.options.formview.parent.options.inline_error_display === 'true') {
      this.$('small.' + this.error_class).remove();
    }
    this.$('.label-otheramount').removeClass('error');
    this.$('.label-otheramount input').removeClass('noValue');
    return this;
  },
  renderFeedbackWithErrors: function (errors) {
    if (errors.length) {
      var $label = this.$('.label-otheramount');

      // Handle when other amount isn't allowed on form
      if (!$label.length) {
        $label = this.$('.SelectAmount .at-radios');
      }

      if ($label.length) {
        $label.addClass('error');
        if (this.options.formview.parent.options.inline_error_display === 'true') {
          this.$('small.' + this.error_class).remove();
          $label.append(this.error_template({
            'error_text': errors.join(', '),
            'behavior_classes': this.error_class
          }));

          return errors;
        }
      }

      // If inline errors are not allowed, throw an error to the top.
      this.parent.parent.subviews.error_console.setFullWidthError(errors);
    } else {
      this.clearFeedback();
    }
    return errors;
  },
  onGiftChanged: function (giftInfo) {
    var amount = this.contributionAmountModel.get('baseAmount');
    if (giftInfo.newAmount > amount) {
      this.setval(giftInfo.newAmount);
    }
  },
  type: 'amount'
});
