import $ from 'lib/jquery.ui';
import baseUrl from 'app/baseUrl';
// fastaction_config was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'app/fastaction_config';

function pad(string, character, n) {
  var padding = new Array(n + 1).join(character);
  return (padding + string).slice(-padding.length);
}

function decimal_to_64binary(n) {
  if (!n) {
    n = 0;
  }
  return pad(n.toString(2), '0', 64);
}

function obfuscate(n) {
  var bin = decimal_to_64binary(n);
  return parseInt(bin.match(/.{1,8}/g).reverse().join(''), 2);
}

function post_form(endpoint, form_id, post_data, success_callback, error_callback) {
  var post_url = endpoint + form_id;

  // See if we need to use the XDomainRequest object for IE. If the request is on the
  // same domain, we can fall back on the normal Backbone.ajax handling.
  var useXDomainRequest = false;

  // See https://gist.github.com/jlong/2428561
  var thisDomainParser = document.createElement('a');
  thisDomainParser.href = document.URL;

  var requestDomainParser = document.createElement('a');
  requestDomainParser.href = post_url;

  if (requestDomainParser.host !== '' && (thisDomainParser.host !== requestDomainParser.host)) {
    useXDomainRequest = true;
  }

  // Add dummy field to identify submission came from ActionTag
  post_data.XFromApp = 'ActionTag';
  post_data.ActionTagBaseUrl = baseUrl;

  // Only use this if browser doesn't support CORS natively. This should
  // catch IE7/8/9 but keep IE10 using the built in XMLHttpRequest which
  // IE10 finally supports for CORS.
  if (useXDomainRequest && !$.support.cors) {
    // Helper function to determine whether protocols differ.
    var protocolsDiffer = function (thisProtocol, requestProtocol) {
      if (thisProtocol === ':' || requestProtocol === ':') {
        return false;
      } else if (thisProtocol === requestProtocol) {
        return false;
      }

      return true;
    };

    // Check if protocols differ, if so try the request with the current domain protocol
    if (protocolsDiffer(thisDomainParser.protocol, requestDomainParser.protocol)) {
      if (thisDomainParser.protocol === 'http:' && post_data.Account &&
        !window.confirm('You are attempting to submit this form over'
          + ' an insecure protocol, do you wish to continue?')) {
        return error_callback({
          responseText: '{"message":"User prevented unsecure submission"}'
        }, null, 'error');
      }
      post_url = post_url.replace(new RegExp(requestDomainParser.protocol), thisDomainParser.protocol);
    }

    var params = $.param(post_data);
    var xdr = new window.XDomainRequest();
    xdr.open('POST', post_url);
    xdr.onload = function () {
      var obj = xdr.responseText ? JSON.parse(xdr.responseText) : {};
      success_callback(obj, 'success', xdr);
      return 'success';
    };
    xdr.onerror = function () {
      error_callback(xdr, null, 'error');
      return 'error';
    };

    // Must declare these even if empty or IE will abort randomly: http://vq.io/12bnhye
    // Must do something significant inside the function or IE8 will abort randomly.
    xdr.onprogress = function () {
      return 'progress';
    };
    xdr.ontimeout = function () {
      return 'timeout';
    };
    xdr.timeout = 10000;

    // Must wrap the send method in a timeout or IE9 will abort randomly: http://vq.io/12bnhye
    // Must set the timeout value to something significant (not zero) or IE8 will abort randomly.
    setTimeout(function () {
      xdr.send(params);
      return 'send';
    }, 250);
  } else {
    $.ajax({
      type: 'POST',
      url: post_url,
      data: post_data,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      success: success_callback,
      error: error_callback,
      crossDomain: true,
      xhrFields: { withCredentials: true }
    });
  }
}

export default {
  post_form: post_form,
  obfuscate: obfuscate,
  deobfuscate: obfuscate // obfuscate is involutary
};
