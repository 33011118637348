import Handlebars from 'lib/handlebars.runtime-v2.0.0';

/* jshint -W098 */
export default Handlebars.registerHelper('subview', function subview(context, options) {
  /* jshint +W098 */
  if (context.type) {
    return '<div data-name="' + context.name + '" data-subview="' + context.type + '_view" data-subview-index="' + context.index + '" />';
  } else {
    return context;
  }
});
