import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';

var $ = Backbone.$,
  _ = Backbone._;
export default FieldView.extend({
  __name__: 'CheckboxListManagerView',
  type: 'checkbox-list-manager',
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    this.title = this.def.title;
    this.template = this.options.templates.checkbox_list_manager;
    this.childrenSelector = '#' + this.options.formview.getBaseId() + ' [name^="' + this.name + '_"]';

    // listen to fill event from NVFormView
    this.listenTo(this.options.formview, 'fill', this.postFill);
  },
  context: function () {
    var def = this.def;
    var context = {
      name: def.name,
      title: def.title,
      required: def.required,
      text: {
        TitleIsRequired: this.resources.fill(this.resources.PrimaryResources.BlankIsRequired, def.title)
      }
    };
    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    this.$el.html(this.template(this.context()));
    // this checks the required logic on checkbox check
    $('body').on('click', this.childrenSelector, this.renderFeedback);
    return this;
  },
  renderFeedback: function () {
    if (this.isCheckboxUncheckedAndRequired()) {
      return this.renderFeedbackWithErrors('error');
    } else {
      return this.clearFeedback();
    }
  },
  clearFeedback: function () {
    $('[name="' + this.name + '"]').removeClass('at-red');
    $('[name="' + this.name + '-small"]').removeClass('at-red');
    $('[name="' + this.name + '-small"]').hide();
    return this;
  },
  renderFeedbackWithErrors: function (error) {
    $('[name="' + this.name + '"]').addClass('at-red');
    $('[name="' + this.name + '-small"]').addClass('at-red');
    $('[name="' + this.name + '-small"]').show();
    return error;
  },
  focus: function () {
    // will get called if an error is present and submit is attempted
    this.getChildByIndex(0).focus();
  },
  errors: function () {
    var errors = this.isCheckboxUncheckedAndRequired() ? [this.title + ' is required.'] : [];
    errors = _.map(invokeCallbacks('alterErrors', {
      val: this.val(),
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors, this.toError, this);
    return errors;
  },
  val: function () {
    // this is mostly useless, don't want to send anything
    return undefined;
  },
  postFill: function () {
    this.setChildrenAriaLabels();
    if (this.values && this.values.length) {
      this.updateChildrenValues(this.values);
    }

  },
  setval: function (val) {

    if (val && (val = val.toString())) {
      var values = val.split('||');
      this.values = values;
      // values get pushed out to children during postFill operation
      // used to get query-string values into children
    } else {
      this.values = null;
    }
    return this.touch();
  },
  setChildrenAriaLabels: function () {
    var baseId = this.getBaseId();
    $(this.childrenSelector).each(function (idx, elem) {
      var $elem = $(elem);
      $elem.attr('aria-describedby', baseId);
    });
  },
  updateChildrenValues: function (values) {

    for (var i = 0; i < values.length; i++) {
      var childVal = values[i];
      var $child = this.getChildByValue(childVal);
      if ($child && $child.length) {
        $child.prop('checked', true);
      }
    }
  },
  getChildByIndex: function (idx) {
    var selector = '[name^="' + this.name + '_"]';
    return $($(selector)[idx]);
  },
  getChildByValue: function (childValue) {
    // starts with parent name, ends with child value
    var selector = '[name^="' + this.name + '_"][name$="_' + childValue + '"]';
    return $(selector);
  },
  isCheckboxUncheckedAndRequired: function () {
    var context = this.context();
    var childElements = $(this.childrenSelector);
    var hasCheckedChildren = childElements.filter(function () {
      return this.checked;
    }).length > 0;
    return context.required && !hasCheckedChildren;
  }
});
