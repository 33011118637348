import $ from 'lib/jquery.ui';
import { loadScript as payPalCommercePlatformLoadScript } from 'lib/PayPalCommercePlatform';
import telemetry from 'app/views/helpers/telemetry';

class PayPalCommercePlatformInstance {
  constructor(sessionId, clientId, merchantId) {
    this.formSessionId = sessionId;
    this.payPalClientId = clientId;
    this.payPalMerchantId = merchantId;
    this.initialize();
  }

  async initialize () {
    try {
      if (this.payPalInstance) {
        telemetry.trackException(new Error('PayPalCommercePlatform instance already exists'), {
          FormSessionId: this.formSessionId,
          ErrorType: 'PayPalCommercePlatform',
          Message: 'PayPalCommercePlatform instance already exists',
          ClientId: this.payPalClientId,
          MerchantId: this.payPalMerchantId
        });
        return;
      }

      this.payPalInstance = payPalCommercePlatformLoadScript({
        'client-id': this.payPalClientId,
        'merchant-id': this.payPalMerchantId,
        'locale': 'en_US',
        'disable-funding': 'card,paylater,credit,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort',
        'intent': 'capture',
        'enable-funding': 'venmo'
      });
    }
    catch(ex) {
      telemetry.trackException(ex, {
        FormSessionId: this.formSessionId,
        ErrorType: 'PayPalCommercePlatform',
        Message: 'Failed to load SDK',
        ClientId: this.payPalClientId,
        MerchantId: this.payPalMerchantId
      });
    }
  }

  async createButton (paymentMethod, orderConfig, tokenConfig, buttonMode) {
    var baseConfig = {
      fundingSource: paymentMethod,
      style: {
        shape: 'rect',
        color: 'white',
        layout: 'vertical',
        label: 'paypal',
        height: 46
      },
      onCancel: function (data) {
        telemetry.trackEvent({
          name: 'PayPalCommercePlatform: buyer canceled',
          properties: {
            FormSessionId: this.formSessionId,
            ClientId: this.payPalClientId,
            MerchantId: this.payPalMerchantId,
            FundingSource: paymentMethod,
            Data: data
          }
        });
      },
      onError: function (err) {
        telemetry.trackException(err, {
          ErrorType: 'PayPalCommercePlatform',
          Message: 'Buttons generated error',
          FormSessionId: this.formSessionId,
          ClientId: this.payPalClientId,
          MerchantId: this.payPalMerchantId,
          FundingSource: paymentMethod
        });
      }
    };

    if (paymentMethod === 'venmo') {
      var paypal = await this.payPalInstance;
      // Create Venmo button here since it does not support switching between token and order modes
      this.venmoButton = paypal.Buttons({...baseConfig,...orderConfig});
      this.venmoButton.render('.at-venmo-button-container');
      return buttonMode !== 'token' && this.venmoButton.isEligible();
    } else {
      this.tokenButtonConfig = {...baseConfig,...tokenConfig};
      this.orderButtonConfig = {...baseConfig,...orderConfig};
      return await this.createOrUpdatePayPalButton(buttonMode);
    }
  }

  // One-time and recurring donations that start immediately use the Order mode - creating an order for authorization and capture
  // Quarterly recurring frequency uses the Token mode - creating token for authorization and payment method for future installment use
  // When switching between order and token modes, we need to create a new button instance with the appropriate configuration
  async createOrUpdatePayPalButton (buttonMode) {
    var paypal = await this.payPalInstance;
    this.payPalButtonMode = buttonMode;
    if (this.payPalButton) {
      this.payPalButton.close();
    }

    this.payPalButton = paypal.Buttons(buttonMode === 'token' ? this.tokenButtonConfig : this.orderButtonConfig);
    var isEligible = this.payPalButton.isEligible();
    if (isEligible) {
      this.payPalButton.render('.at-paypal-button-container');
    }
    return isEligible;
  }

  async createOrder (formUrl, amount, isRecurring, formName, paymentMethod, data) {
    telemetry.trackEvent({
      name: 'PayPalCommercePlatform: creating order',
      properties: {
        FormSessionId: this.formSessionId,
        ClientId: this.payPalClientId,
        MerchantId: this.payPalMerchantId,
        FundingSource: paymentMethod,
        Amount: amount,
        Recurring: isRecurring,
        Data: data
      }
    });

    var result = null;
    try {
      // We call our server to create the order
      result = await $.ajax({
        url: formUrl + '/PayPalCommercePlatformOrder',
        xhrFields: { withCredentials: true },
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          amount: amount,
          fundingSource: paymentMethod,
          vault: isRecurring ? 'true' : 'false',
          message: formName
        }),
        success: function (orderId) {
          return orderId;
        },
      });
    } catch (ex) {
      return;
    }

    this.payPalOrderId = result;
    return this.payPalOrderId;
  }

  async updateOrder (formUrl, newAmount) {
    if (!this.payPalInstance || !this.payPalOrderId || newAmount === 0 || newAmount === this.authorizedAmount) {
      return;
    }

    var paypalOrder = null;
    try {
      paypalOrder = await $.ajax({
        url: formUrl + '/PayPalCommercePlatformOrder/' + this.payPalOrderId,
        xhrFields: { withCredentials: true },
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          amount: newAmount
        }),
        success: function (order) {
          return order;
        }
      });
    } catch (ex) {
      return;
    }

    return paypalOrder;
  }

  async createToken (formUrl, formName, paymentMethod, data) {
    telemetry.trackEvent({
      name: 'PayPalCommercePlatform: creating token',
      properties: {
        FormSessionId: this.formSessionId,
        ClientId: this.payPalClientId,
        MerchantId: this.payPalMerchantId,
        FundingSource: paymentMethod,
        Data: data
      }
    });

    var paypalTokenId = null;
    try {
      //  We call our server to create the token
      paypalTokenId = await $.ajax({
        url: formUrl + '/PayPalCommercePlatformToken',
        xhrFields: { withCredentials: true },
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          fundingSource: paymentMethod,
          message: formName
        }),
        success: function (tokenId) {
          return tokenId;
        }
      });
    } catch (ex) {
      return;
    }

    return paypalTokenId;
  }

  async onApproveOrder (formUrl, paymentMethod, data) {
    telemetry.trackEvent({
      name: 'PayPalCommercePlatform: order approved',
      properties: {
        FormSessionId: this.formSessionId,
        ClientId: this.payPalClientId,
        MerchantId: this.payPalMerchantId,
        FundingSource: paymentMethod,
        Data: data
      }
    });

    var paypalOrder = null;
    try {
      paypalOrder = await $.ajax({
        url: formUrl + '/PayPalCommercePlatformOrder/' + data.orderID,
        xhrFields: { withCredentials: true },
        type: 'GET',
        success: function (order) {
          return order;
        }
      });
    } catch (ex) {
      return;
    }

    this.authorizedAmount = paypalOrder?.PurchaseUnits[0]?.Amount?.Value;
    return paypalOrder;
  }

  async onApproveToken (formUrl, paymentMethod, data) {
    telemetry.trackEvent({
      name: 'PayPalCommercePlatform: token approved',
      properties: {
        FormSessionId: this.formSessionId,
        ClientId: this.payPalClientId,
        MerchantId: this.payPalMerchantId,
        FundingSource: paymentMethod,
        Data: data
      }
    });

    var paypalToken = null;
    try {
      //  We call our server to get the token
      paypalToken = await $.ajax({
        url: formUrl + '/PayPalCommercePlatformToken/' + data.vaultSetupToken,
        xhrFields: { withCredentials: true },
        type: 'POST',
        success: function (token) {
          return token;
        }
      });
    } catch (ex) {
      return;
    }

    return paypalToken;
  }

  mapOrderResponse (paypalOrder) {
    var paypalPayer = paypalOrder?.Payer;
    var purchaseUnits =  paypalOrder?.PurchaseUnits[0];

    var address = paypalPayer?.Address;
    var useBillingAddress = !!address?.AddressLine1;
    var shippingAddress = purchaseUnits?.Shipping?.Address;
    var phone = paypalPayer?.Phone;
    // For the address, we try to get it from the payer address, if we don't have it, we'll use the shipping address.
    var dict = {
      FirstName: paypalPayer?.Name?.GivenName,
      LastName: paypalPayer?.Name?.Surname,
      AddressLine1: useBillingAddress ? address.AddressLine1 : shippingAddress?.AddressLine1,
      AddressLine2: useBillingAddress ? address.AddressLine2 : shippingAddress?.AddressLine2,
      City: useBillingAddress ? address.City : shippingAddress?.City,
      StateProvince: useBillingAddress ? address.State : shippingAddress?.State,
      PostalCode: useBillingAddress ? address.PostalCode : shippingAddress?.PostalCode,
      Country: useBillingAddress ? address.CountryCode : shippingAddress?.CountryCode,
      EmailAddress: paypalPayer?.EmailAddress,
      HomePhone: phone?.PhoneNumber?.NationalNumber,
      MobilePhone: phone?.PhoneNumber?.NationalNumber
    };
    return dict;
  }

  mapTokenResponse (paypalToken) {
    // Get payer info from PayPal payment_source (Token creation is not supported for Venmo)
    var paypalPayer = paypalToken?.PaymentSource?.PayPal;
    var shippingAddress = paypalPayer?.Shipping?.Address;
    var phone = paypalPayer?.Phone;
    var dict = {
      FirstName: paypalPayer?.Name?.GivenName,
      LastName: paypalPayer?.Name?.Surname,
      AddressLine1: shippingAddress?.AddressLine1,
      AddressLine2: shippingAddress?.AddressLine2,
      City: shippingAddress?.City,
      StateProvince: shippingAddress?.State,
      PostalCode: shippingAddress?.PostalCode,
      Country: shippingAddress?.CountryCode,
      EmailAddress: paypalPayer?.EmailAddress,
      HomePhone: phone?.PhoneNumber?.NationalNumber,
      MobilePhone: phone?.PhoneNumber?.NationalNumber
    };
    return dict;
  }
}

export default PayPalCommercePlatformInstance;
