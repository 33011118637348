// A central place to coordinate changes that affect the contribution amount
// currently the properties tracked are
// - baseAmount: The base contribution, including (for ticketed events) the total for all tickets + additional
// - coverCostEnabled: Whether cover costs are enabled
// - coverCost:  0 if disabled; otherwise the additional amount paid.  Calculated
// - totalCost: The total to be paid, including cover cost.  Calculated
// Calling `set` directly from outside the model will put things in an inconsistent state, so use the specific helper methods.

import Backbone from 'lib/backbone';
import { accounting } from 'lib/accounting';

export default Backbone.Model.extend({
  __name__: 'ContributionAmount',

  toFloat: function (value, precision) {
    return parseFloat(accounting.toFixed(value, precision));
  },
  setBaseAmount: function (amount) {
    if (typeof amount !== 'number') {
      amount = this.toFloat(amount, 2);
    }
    var coverCostEnabled = this.get('coverCostEnabled');
    this.updateModel(amount, coverCostEnabled);
  },
  setCoverCostState: function (enabled) {
    var baseAmount = this.get('baseAmount');
    this.updateModel(baseAmount, !!enabled);
  },
  // Note that the proposed values don't have any other impact on the form
  setProposedUpsellState: function (recurringAmount, oneTimeAmount) {
    oneTimeAmount = +oneTimeAmount || 0;
    recurringAmount = +recurringAmount || 0;
    this.set({
      'proposedOneTimeAmount': oneTimeAmount,
      'proposedRecurringAmount': recurringAmount,
      'proposedTotalAmount': oneTimeAmount + recurringAmount,
    });
  },
  updateModel: function (baseAmount, coverCostEnabled) {
    var coverCost = this.computeFormula(baseAmount, coverCostEnabled);
    var newTotalAmount = this.toFloat(baseAmount + coverCost, 2);
    this.set({
      'baseAmount': baseAmount,
      'coverCostEnabled': coverCostEnabled,
      'coverCost': coverCost,
      'totalAmount': newTotalAmount
    });
  },
  // Cover costs formula
  computeFormula: function (amount, coverCostEnabled) {
    amount = amount || 0;
    if (!this.formulaArgs || !coverCostEnabled || amount <= 0) {
      return 0;
    }

    // percentage is not passed as a decimal, so we need to convert 3.25 --> 0.0325
    var percentage = this.formulaArgs.percentage || 0;
    if (percentage !== 0) {
      percentage = percentage / 100;
    }
    var flatAmount = this.formulaArgs.amount || 0;

    var additional = percentage * amount + flatAmount;

    // maximize additional amount without passing the max
    if (amount + additional > this.maxAmount) {
      additional = this.maxAmount - amount;
    }
    var coverCost = this.toFloat(additional, 2);
    return coverCost > 0 ? coverCost : 0;
  },
  initialize: function (opts) {
    // Here we pretend that this value never changes -- however, for multi-currency forms it can
    // The solution would be to set this as a property on the model, and update it when necessary
    // See VAN-88138 for details.
    this.maxAmount = opts.maxValue;
    this.formulaArgs = opts.coverCostFormula;
    this.updateModel(opts.baseAmount, opts.coverCostEnabled);
  }
});
