
import _ from 'lib/lodash.custom';
import $ from 'lib/jquery';
import loadScript from 'app/loadScript';
import loadCss from 'app/loadCSS';

function ensureMapboxJsLoaded() {
  if (!window.mapboxgl) {
    return loadScript('//api.tiles.mapbox.com/mapbox-gl-js/v0.44.1/mapbox-gl.js');
  }
  return $.Deferred().resolve().promise();
}
function ensureGeojsonExtentJsLoaded() {
  if (!window.geojsonExtent) {
    return loadScript('//api.mapbox.com/mapbox.js/plugins/geojson-extent/v0.0.1/geojson-extent.js');
  }
  return $.Deferred().resolve().promise();
}

function displayMaps(mapElements) {
  // load mapbox css
  loadCss('//api.tiles.mapbox.com/mapbox-gl-js/v0.44.1/mapbox-gl.css');

  $.when(ensureMapboxJsLoaded(), ensureGeojsonExtentJsLoaded())
    .then(function () {
      var mapboxgl = window.mapboxgl;
      var geojsonExtent = window.geojsonExtent;

      _.forEach(mapElements, function (mapElement) {
        var locationDataStr = mapElement.getAttribute('data-map-locations');
        var locationData = locationDataStr ? JSON.parse(locationDataStr) : [];
        if (!locationData || !locationData.length) {
          return true;
        }
        // render map properly
        mapElement.classList.add('at-map-render');
        var features = _.reduce(locationData, function (memo, location) {
          if (location.Longitude && location.Latitude) {
            var point = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [location.Longitude, location.Latitude]
              },
              properties: {
                title: location.Title,
                description: location.Description
              }
            };
            memo.push(point);
          }
          return memo;
        }, []);
        var geojson = {
          type: 'FeatureCollection',
          features: features
        };

        // init map
        mapboxgl.accessToken = 'pk.eyJ1IjoibmdwdmFuMSIsImEiOiJjamRjNzA5MnMwNGUwMnduMWpiYXpjMXV2In0.O1tZ7lyukJGf1_XAiNV9eA';
        var map = new mapboxgl.Map({
          container: mapElement,
          style: 'mapbox://styles/mapbox/outdoors-v10?optimize=true'
        });

        // Add zoom control to bottom right
        map.addControl(new mapboxgl.NavigationControl({
          // Hide rotation control
          showCompass: false
        }), 'bottom-right');

        // add data points and markers
        geojson.features.forEach(function (marker) {
          // create a HTML element for each feature
          var el = document.createElement('div');
          el.className = 'glyphicons glyphicons-map-marker';

          // make a marker for each feature and add to the map
          new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
              .setHTML('<p class="mapboxgl-popup-title">' + marker.properties.title + '</p>' +
                '<p class="mapboxgl-popup-description">' + marker.properties.description + '</p>'))
            .addTo(map);
        });

        // zoom
        map.fitBounds(geojsonExtent(geojson), { padding: 20, duration: 0, maxZoom: 15 });
      });
    });
}

function loadMap(mapContainerClassName) {
  // if map doesn't exist, exit
  var mapElements = document.getElementsByClassName(mapContainerClassName);
  if (!mapElements || !mapElements.length) {
    return;
  }

  displayMaps(mapElements);
}

export default {
  loadMap: loadMap
};
