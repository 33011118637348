import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';

var _ = Backbone._;
export default FieldView.extend({
  __name__: 'TextAreaView',
  tagName: 'label',
  events: {
    'change': 'renderFeedback'
  },
  className: function () {
    return 'at-area   ' + this.options.definition.name;
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    var self = this;
    this.once('invalid', function onInvalidInput() {
      self.$el.on('input', ':input', _.debounce(this.renderFeedback, 150));
    });
  },
  context: function () {
    var context = {
      name: this.name,
      title: this.title,
      label: this.def.label || this.title,
      labelhide: this.options.labels === 'placeholder',
      default_value: this.def.default_value,
      required: this.def.required && 'required',
      readonly: this.def.readonly && 'readonly disabled',
      maxlength: this.def.maxlength ? 'maxlength="' + this.def.maxlength + '"' : ''
    };

    if (context.labelhide) {
      context.placeholder = 'placeholder="' + _.escape(this.title) + '"';
    }

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    this.$el.html(this.template(this.context()));
    this.$textarea = this.$('textarea');
    if (_.isPlainObject(this.def.attr)) {
      this.$textarea.attr(this.def.attr);
    }
    return this;
  },
  val: function () {
    return this.$textarea.val() || null;
  },
  setval: function (value) {
    if (_.isNull(value)) {
      return this;
    }
    this.$textarea.val(value);
    return this.touch().hideOrShow();
  },
  type: 'textarea'
});
