import Backbone from 'lib/backbone';
import FieldView from 'app/views/FieldView';
// accounting was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'lib/accounting';

var _ = Backbone._;
export default FieldView.extend({
  __name__: 'TicketingLevelView',
  id: function () {
    return 'ticket-level-' + this.model.get('id');
  },
  tagName: 'tr',
  className: function () {
    return 'ticket-level-info ticket-level';
  },
  stripingClass: function () {
    return ((this.$el.data('index') % 2) ? 'even' : 'odd');
  },
  isRequired: function () {
    return false;
  },
  events: { 'change select': 'changeQuantity' },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    this.name = this.options.definition.name = 'TicketLevel' + this.cid;
    this.options.requireTicketHolders = this.options.parent.options.requireTicketHolders;
  },
  render: function () {
    this.def.required = false;
    this.$el.addClass(this.stripingClass()).html(this.template(this.model.toJSON()));

    var ticket_quantity = this.options.ticket_holders.holders_by_level(this.model.get('id')).length;
    if (ticket_quantity === 0) {
      this.$('select').selectedIndex = 0;
    } else {
      this.$('select').val(ticket_quantity);
    }

    return this;
  },
  changeQuantity: function (event) { // jshint ignore:line
    var val = this.$('select').val(),
      quantity = val ? parseInt(val, 10) : 0,
      ticket_holders = this.options.ticket_holders,
      ticket_holders_at_level = ticket_holders.holders_by_level(this.model.get('id'));

    // First, store the current values on the TicketingView
    // Note: Arguably there should be a model to hold this state...

    // The number selected is less than the current amount, we'll want
    // to add some ticket holders

    var difference = quantity - ticket_holders_at_level.length;

    if (difference > 0) {
      // Add elements to get up to the selected size
      _.times(difference, function () {
        ticket_holders.add({ required: this.options.requireTicketHolders, level: this.model.clone() });
      }, this);
    } else if (difference < 0) {
      difference = Math.abs(difference);
      var nameless = _.filter(ticket_holders_at_level, function (holder) {
        return !(holder.get('first') && holder.get('last'));
      });

      var to_be_removed = [];

      if (nameless.length >= difference) {
        to_be_removed = nameless.slice(0, difference);
      } else {
        to_be_removed = nameless;
        /*jshint -W109 */
        alert("You've attempted to remove tickets for which names have been specified. " +
          "Please delete the names you'd like to remove.");
        /*jshint +W109 */
      }
      _.each(to_be_removed, function (dead) {
        ticket_holders.remove(dead);
      });
    }

    this.spawn('changeQuantityOrAdditional', {
      ticket_total: this.options.parent.stats.total
    });
    return this;
  },
  field_name: function () {
    return 'TicketLevel';
  },
  type: 'ticketing_level'
});
