import Backbone from 'lib/backbone';
import FieldView from 'app/views/FieldView';

var _ = Backbone._;
export default Backbone.View.extend({
  __name__: 'HiddenView',
  type: 'hidden',
  tagName: 'input',
  initialize: function () {
    this.def = this.options.definition;
    this.name = this.def.name;
  },
  attributes: function () {
    var def = this.options.definition;
    return {
      id: def.name + '_Value',
      type: def.type,
      name: def.name + '.Value',
      value: def.value
    };
  },
  render: function () {
    return this;
  },
  isRequired: function () {
    return false;
  },
  field_name: function () {
    return this.options.definition.name;
  },
  errors: function () {
    return [];
  },
  renderFeedback: function () {
    return this.errors();
  },
  require_valid: function () {
    return true;
  },
  field_title: function () {
    return '';
  },
  clearFeedback: function () {
    return this;
  },
  val: function () {
    var value = this.el.value !== '' ? this.el.value : null;
    if (_.isString(value) && /^(true|false)$/i.test(value)) {
      return (/^true$/i).test(value);
    } else {
      return value;
    }
  },
  setval: function (value) {
    if (!_.isNull(value)) {
      this.el.value = value;
    }
    return this;
  },
  isActive: function () {
    return FieldView.prototype.isActive.apply(this);
  }
});
