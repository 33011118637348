function FormError(text, field, errorId) {
  if (typeof text === 'string') {
    this.text = text || '';
    this.field = field || null;
  } else if (text) {
    this.text = text.text || '';
    this.field = text.field || null;
  }
  this.errorId = errorId;
}

FormError.prototype.toString = function () {
  return this.text;
};
FormError.prototype.valueOf = function () {
  return this.text;
};
FormError.prototype.toJSON = function () {
  return { text: this.text, field: this.field.name, errorId: this.errorId };
};
export default FormError;
