
export default window['@rows'] || {
  ContactInformation: [
    ['GotYourMessage'],
    ['OrganizationToggle'],
    ['OrganizationName'],
    ['OrganizationAddressLine1', 'OrganizationAddressLine2'],
    ['OrganizationCountry', 'OrganizationPostalCode', 'OrganizationCity', 'OrganizationStateProvince'],
    ['OrganizationEmailAddress', 'OrganizationPhone'],
    ['OrganizationTwitterHandle', 'OrganizationFacebookProfileUrl'],
    ['OrganizationFooterHtml'],
    ['Prefix', 'FirstName', 'MiddleName', 'LastName', 'Suffix'],
    ['DateOfBirth', 'PreferredLanguage', 'PreferredLanguages', 'Pronoun'],
    ['Genders', 'SexualOrientation', 'Race', 'Ethnicity'],
    ['AddressLine1', 'AddressLine2'],
    ['Country', 'PostalCode', 'City', 'StateProvince'],
    ['EmailAddress', 'HomePhone', 'MobilePhone'],
    ['WorkEmail', 'WorkPhone'],
    ['TwitterHandle', 'FacebookProfileUrl'],
    ['SmsSubscribeMobilePhone'],
    ['SmsLegalDisclaimer'],
    ['YesSignMeUpForUpdatesForBinder'],
    ['CardSignEmployers'],
    ['UpdateMyProfile']
  ],
  EmployerInformation: [
    ['LegalHeaderHtml'],
    ['Occupation', 'Employer', 'SpouseName'],
    ['EmployerOccupationIsRetiredCheckbox'],
    ['WorkAddressLine1', 'WorkAddressLine2'],
    ['WorkCountry', 'WorkPostalCode', 'WorkCity', 'WorkStateProvince'],
    ['WorkEmail', 'WorkPhone']
  ],
  PaymentInformation: [
    ['Account', 'ExpirationDate', 'SecurityCode']
  ],
  RecipientInformation: [
    ['RecipientInfoHeaderHtml'],
    ['RecipientFirstName', 'RecipientLastName'],
    ['RecipientStreetAddress', 'RecipientAddressLine1', 'RecipientAddressLine2'],
    ['RecipientCountry', 'RecipientPostalCode', 'RecipientCity', 'RecipientStateProvince'],
    ['RecipientEmailAddress', 'RecipientHomePhone'],
    ['NotifyRecipient'],
    ['NotificationHeaderHtml'],
    ['Ecard'],
    ['NotificationSendDate'],
    ['NotificationSendCopy'],
    ['NotificationMessage']
  ],
  ShippingInformation: [
    ['ShippingPrefix', 'ShippingFirstName', 'ShippingMiddleName', 'ShippingLastName', 'ShippingSuffix'],
    ['ShippingAddressLine1', 'ShippingAddressLine2'],
    ['ShippingCountry', 'ShippingPostalCode', 'ShippingCity', 'ShippingStateProvince']
  ],
  EventInformation: [
    ['EventName', 'EventType'],
    ['EventDate', 'EventStartTime', 'EventEndTime', 'EventTimeZone'],
    ['EventDescription'],
    ['EventIsPrivate'],
    ['EventIsPrivateReason']
  ],
  EventLocation: [
    ['EventLocationName'],
    ['EventAddressLine1', 'EventAddressLine2'],
    ['EventPostalCode', 'EventCity', 'EventStateProvince']
  ]
};
