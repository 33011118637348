import $ from 'lib/jquery.ui';
import telemetry from 'app/views/helpers/telemetry';

// not 100% foolproof, but it should be triggered by most adblockers
export default function checkAds() {
  var $form = $('.ngp-form');
  if ($form) {
    var adBlockTester = document.createElement('div');
    adBlockTester.innerHTML = '&nbsp;';
    adBlockTester.className = 'adsbox';
    $form.append(adBlockTester);
    window.setTimeout(function () {
      var msg = adBlockTester.offsetHeight === 0 ? 'Ad blocker detected' :
        'Ad blocker not detected';
      telemetry.trackTrace({ message: msg });

      $form.find('.adsbox').remove();
    }, 60);
  }
}
