import config from 'config/actionTagConfig';
import loadScript from 'app/loadScript';
import $ from 'lib/jquery';
import telemetry from 'app/views/helpers/telemetry';
import _ from 'lib/lodash.custom';

// VGS = VeryGoodSecurity
function init() {
  if (!isConfigured()) {
    return $.Deferred().resolve(false);
  }
  if (window.VGSCollect) {
    return $.Deferred().resolve(true);
  }

  return loadScript.nocache(config.VGS_JS_URL, 15000)
    .then(function () {
      if (!window.VGSCollect) {
        return loadScript.nocache(config.VGS_JS_URL, 15000);
      }
    }, function () {
      if (!window.VGSCollect) {
        return loadScript.nocache(config.VGS_JS_URL, 15000);
      }
    });
}

function isConfigured() {
  if (!config.VGS_JS_URL || !config.VGS_VAULT_ID) {
    return false;
  }
  return true;
}

function isLoaded() {
  if (!isConfigured()) {
    return false;
  }

  return !!window.VGSCollect;
}
function waitUntilLoaded() {
  return init().then(isLoaded, isLoaded);
}

function createForm() {
  if (!isLoaded()) {
    throw 'Cannot create VGS form, VGS is not loaded.';
  }
  // passthrough args
  var args = [config.VGS_VAULT_ID, config.VGS_ENVIRONMENT].concat(Array.prototype.slice.call(arguments));
  return window.VGSCollect.create.apply(window.VGSCollect, args);
}

function submitForm(form, correlationProperties) {
  var dfd = $.Deferred();

  if (!form || !form.state || Object.keys(form.state) <= 0) {
    telemetry.trackEvent({
      name: 'VGS Request Skipped',
      properties: correlationProperties
    });
    return dfd.resolve({});
  }

  telemetry.trackEvent({
    name: 'VGS Request Start',
    properties: _.defaults({
      VgsFormId: form.formId,
      VgsEnvironment: form.environment,
      VgsDebugId: form.debugId,
      VgsVaultId: form.tntId,
      VgsCname: form.cname
    }, correlationProperties)
  });

  // If too much time has passed, assume we won't get a response from VGS
  var vgsSubmitTimeout = config.VGS_POST_TIMEOUT_SECONDS * 1000;
  window.setTimeout(function () {
    if (dfd.state() === 'pending') {
      telemetry.trackEvent({
        name: 'VGS Request Result',
        properties: _.defaults({ Status: 'abandoned', WaitTimeMs: vgsSubmitTimeout }, correlationProperties)
      });
      dfd.reject('No response from VGS');
    }
  }, vgsSubmitTimeout);
  form.submit('/post', {
    //opts
  },
    function (status, res) {
      // This callback can be triggered in cases where the request did not succeed
      // Check the status to figure out the status
      var numericStatus = parseInt(status, 10);
      var requestSucceeded = numericStatus && (numericStatus >= 200 && numericStatus < 400);

      // the HA server does not package the result within a json property
      var payload = res.json || res;
      var payloadKeys = _.keys(payload);
      telemetry.trackEvent({
        name: 'VGS Request Result',
        properties: _.defaults({
          Status: requestSucceeded ? 'success' : 'failure',
          VgsStatus: status,
          VgsProps: payloadKeys
        }, correlationProperties)
      });
      if (requestSucceeded) {
        dfd.resolve(payload);
      } else {
        dfd.reject('Failed with status ' + status);
      }

    },
    function (errors) {
      telemetry.trackEvent({
        name: 'VGS Request Result',
        properties: _.defaults({ Status: 'failure', VgsErrors: errors }, correlationProperties)
      });
      dfd.reject(errors);
    });

  return dfd.promise();
}

export default {
  init: init,
  waitUntilLoaded: waitUntilLoaded,
  isLoaded: isLoaded,
  createForm: createForm,
  submitForm: submitForm,
  isConfigured: isConfigured
};
