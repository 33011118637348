import fastaction from 'app/fastaction_config';
import Backbone from 'lib/backbone';
import cookieConsentConfig from 'app/CookieConsentConfiguration';
import databag_config from 'app/databag_config';
import actionTagConfig from 'config/actionTagConfig';

/*
5/22/24

Example FastActionUser model returned by the FastAction server from the api/v3/profile/* routes.
We flatten the `metadata` and `user` fields

Logged in:
{
    "metadata": {
        "dnt": false,
        "authed": false,
        "fastActionSessionId": "hrCWR5nB32025NH81Sc8GZnACmsW"
    },
    "user": {
        "savedCards": [
            {
                "tokenPool": "DemeterTest",
                "ccLastFour": "1111",
                "ccType": "Visa"
            }
        ],
        "profiles": {},
        "profileData": {
            "AddressLine1": "Foobar",
            "City": "Schenectady",
            "StateProvince": "NY",
            "PostalCode": "12345",
            "EmailAddress": "jskdlfjklsdj@jksljdlsf.com",
            "FirstName": "Liam",
            "LastName": "Hunt",
            "last-modified": "2024-05-22T18:29:16.000Z"
        },
        "id": "hedYX4_uQxiwG33M2qNM2GD0JuCu"
    }
}

Not logged in:
{
    "metadata": {
        "fastActionSessionId": "foo",
        "dnt": false
    },
    "errors": [
        {
            "text": "User not logged in.",
            "code": "USER_NOT_LOGGED_IN"
        }
    ]
}
*/

var $ = Backbone.$;
var _ = Backbone._;


export default (new (Backbone.Model.extend({
  __name__: 'FastActionUser',
  initialize: function () {
    _.bindAll(this, 'nameFromProfile', 'nameFromActionId', 'onSyncError', 'getCardForPool', 'getProfileData',
      'fetch', 'clear', 'loginAndFetchProfile', 'fetchProfileWithoutLoggingIn', 'persistSessionId',
      'clearPersistedSessionId', 'getPersistedSessionId'
    );
  },
  nameFromProfile: function () {
    let profileData = this.get('profileData');
    return [profileData?.FirstName, profileData?.MiddleName, profileData?.LastName].filter(s => !!s).join(' ');
  },
  nameFromActionId: function () {
    var name = '', get = this.get('profiles');
    if (get?.actionid) {
      name = get.actionid.displayName;
      // Users registering through the fastAction workflow get a displayName that is
      // all whitespace.  Since we only have an email address from them, use that.
      if (!name || /^\s*$/.test(name)) {
        name = get.actionid.emails[0].value;
      }
    }
    return name;
  },
  onSyncError: function (model, response, options) {
    this.trigger('syncError', model, response, options);
  },
  getCardForPool: function (tokenPool) {
    return _(this.get('savedCards')).find({ tokenPool: tokenPool });
  },
  // equivalent of Databag.js get_dict(), Databag.js was killed in EVA-250.
  // Used for prefill and to construct the autoprocessing confirm account modal
  getProfileData: function () {
    let dict = this.get('profileData') || {};

    // it's probably OK to include this in the prefill data but we didn't do so when this model
    // was set from databag
    dict = _.omit(dict, 'last-modified');

    // Databag.js GetDict() did this, I'm not 100% sure it's necessary
    let fullName = this.nameFromProfile();
    if (fullName) {
      dict.FullName = fullName;
    }

    return dict;
  },
  // Fetch FA profile info, using the FastActionSessionId from localStorage if present
  // Persist the response's FastActionSessionId if a user is returned
  fetch: function (options = {}) {
    let self = this,
      deferred = $.Deferred();

    options = {
      dataType: 'json',
      type: 'GET',
      url: fastaction.urls.profile(),
      timeout: 2000,
      crossDomain: true,
      xhrFields: { withCredentials: true },
      error: this.onSyncError,
      cache: false,
      // options last so it takes priority for e.g. timeout
      ...options,
    };

    let fastActionSessionId = options.fastActionSessionIdOverride || this.getPersistedSessionId();
    if (fastActionSessionId) {
      options.data = { fastActionSessionId: fastActionSessionId };
    }

    if (cookieConsentConfig.areFunctionalCookiesAccepted()) {
      // resp is the non-parsed (non-flattened) version of the model since Backbone calls
      // model.set(model.parse(resp, options), options) in a separate callback
      Backbone.Model.prototype.fetch.call(self, options).then((resp) => {
        if (resp.metadata?.dnt) {
          $('.at input[name="updateMyProfile"]').prop('checked', false);
        }
        if (resp.user?.id) {
          // we expect resp.metadata.fastActionSessionId to always be set if the response returns a user
          // checking anyway just in case
          if (resp.metadata?.fastActionSessionId) {
            this.persistSessionId(resp.metadata.fastActionSessionId);
          }
          deferred.resolve(resp);
        } else {
          deferred.reject(self, resp, 'error', 'Unauthorized');
        }
      },
        deferred.reject);
    } else {
      deferred.reject('Functional cookies not accepted');
    }

    return deferred.promise();
  },
  clear: function (options = {}) {
    if (options.clearPersistedSessionId) {
      this.clearPersistedSessionId();
    }
    // dispatch a custom "clear" event
    Backbone.Model.prototype.clear.call(this, options);
    return this.trigger('clear');
  },
  // fetch the FA info associated with the account id. persist the returned FastActionSessionId if a user is found
  loginAndFetchProfile: function (fastActionAccountId) {
    var options = {
      url: fastaction.urls.profile_with_id_login(fastActionAccountId),
      cache: false,
      xhrFields: { withCredentials: true },
      error: this.onSyncError,
      timeout: 2000
    };

    // Fetch new properties and assign to attributes
    // resp is the non-parsed (non-flattened) version of the model since Backbone calls
    // model.set(model.parse(resp, options), options) in a separate callback
    return Backbone.Model.prototype.fetch.call(this, options).then((resp) => {
      // we expect this if statement to be true on all success responses. Checking just in case
      if (resp?.user?.id && resp?.metadata?.fastActionSessionId) {
        this.persistSessionId(resp.metadata.fastActionSessionId);
      }
    });
  },
  fetchProfileWithoutLoggingIn: function (fastActionAccountId) {
    var options = {
      url: fastaction.urls.profile_with_id(fastActionAccountId),
      cache: false,
      xhrFields: { withCredentials: true },
      error: this.onSyncError,
      timeout: 2000
    };

    // Fetch new properties and assign to attributes
    return Backbone.Model.prototype.fetch.call(this, options);
  },
  // overload of native Backbone.js method that is called on fetch. Does some data sanitation on bag data
  // before setting it on the model. Copied over from Databag.js in EVA-250, not 100% sure if we need this
  // we believe it's Oberon related
  parse: function (resp, options) { // jshint ignore:line
    // flatten
    resp = {
      ...resp.user,
      ...resp.metadata,
      errors: resp.errors
    };

    // Hack from https://github.com/jashkenas/backbone/issues/1069#issuecomment-17511573
    // to reset the model entirely rather than merging in what's in the server
    // This makes sure we don't get into an inconsistent state if any top-level properties have been
    // unset on the model since the last fetch, e.g. if we were previously logged in (`attributes.errors` set)
    // and now we're logging in (`attributes.errors` should be undefined)
    _.keys(this.attributes).forEach(function (key) {
      if (resp[key] === undefined) {
        resp[key] = undefined;
      }
    });

    let profileData = resp?.profileData;
    if (!profileData) {
      return resp;
    }

    let dotvaluededupe = {};
    let dotValue = {
      regex: /\.Value$/,
      value: '.Value'
    };

    // Trying to de-dupe multiple values like FirstName and FirstName.Value
    // without throwing out keys that don't have a .Value counterpart.
    _.each(profileData, function (value, key, list) {
      key = databag_config.canonical[key] || key;
      if (!dotValue.regex.test(key)) {
        dotvaluededupe[key] = value;
      } else {
        // See if no key with this and .Value is in the response
        // If this is the only one, we want to ignore it.
        var newKey = key.replace(dotValue.regex, '');
        if (_.isUndefined(list[newKey])) {
          dotvaluededupe[newKey] = value;
        }
      }
    });

    let denylist = databag_config.denylist.slice()
      .concat(['id', 'name']).concat(databag_config.pushPullDenylist);

    resp.profileData = _.omit(dotvaluededupe, denylist);

    return resp;
  },
  setProfileDataWithFormState: function (form_state) {
    var state_copy;

    // Denylist values going into the profileData attribute
    state_copy = _.omit(form_state, databag_config.denylist.slice());

    state_copy = _.reduce(state_copy, function (memo, value, key) {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }
      memo[key] = value;
      return memo;
    }, {});

    // Per [DIGITAL-1249], if AddressLine1 is changed, then always overwrite the
    // value of AddressLine2, even if its new value is blank.
    if (this.get('profileData')?.AddressLine1 !== state_copy.AddressLine1) {
      state_copy.AddressLine2 = form_state.AddressLine2 || '';
    }

    let profileData = {
      ...this.get('profileData'),
      ...state_copy
    };
    // Set silent because we don't want to trigger fill events
    this.set('profileData', profileData, { silent: true });
    return this;
  },
  setSavedCardWithPostResponse: function (post_response) {
    if (!post_response?.response?.cc4Digit || !post_response?.response?.fastActionTokenPool || !post_response?.response?.ccType || !post_response?.response?.contributionAmount)
    {
      return;
    }

    let savedCard = {
      ccLastFour: post_response.response.cc4Digit,
      tokenPool: post_response.response.fastActionTokenPool,
      ccType: post_response.response.ccType
    };

    let savedCards = this.get('savedCards');
    if (!savedCards)
    {
      savedCards = [savedCard];
    } 
    else if (savedCards.filter((card) => card.tokenPool === savedCard.tokenPool).length === 0)
    {
      savedCards = savedCards.concat(savedCard);
    } else 
    {
      savedCards = savedCards.map((card) => card.tokenPool === savedCard.tokenPool ? savedCard : card);
    }

    this.set('savedCards', savedCards, { silent: true });
  },
  /*
  Helper methods to set fastActionSessionId in localStorage, and as a first party cookie
  (1st party cookie only if on an OA-hosted form). This is used to persist session state
  between visits to forms in the same domain.

  localStorage sessionId is sent in FastActionUser.fetch, and calls to load the
  formDefinition. In the latter case, it will bust the OA Imperva cache. To avoid cache
  busting, the sessionId should only be set in localStorage if it is associated with
  a FastActionUser with profile data. model sessionId is used in auth_popup calls, e.g. logout,
  and sent on submission

  Note the localStorage sessionId may be different from the sessionId set on the FastActionUser model.
  LocalStorage and Model sessionIds should differ in the following scenarios
  * Our session is not connected to a FastAction user: session set on model but not LocalStorage
  * Awaiting supporter feedback on Confirm Account Modal for smartlink + autoprocessing
      (FastActionUser.fetchProfileWithoutLoggingIn) In this case the sessionIds may be entirely
      different. We expect the UI outside of the confirm modal is not interactable in this state
  * FastAction fetch methods fail: This may lead to various inconsistent states - the model may be
      missing a sessionId entirely (if we clear() then fail the fetch), or it may be stuck with a sessionId
      that's different from what's in localStorage. If this happens, we may have buggy / unexpected behavior
  */
  persistSessionId: function (fastActionSessionId) {
    localStorage.setItem('fastActionSessionId', fastActionSessionId);

    if (actionTagConfig.FORMBUILDER_HOSTED)
    {
      const yearsOut = 10;
      let expireDate = new Date();
      expireDate.setFullYear(new Date().getFullYear() + yearsOut);
      document.cookie = 'FormPageLoadFastActionSessionId=' + fastActionSessionId + '; domain='+ window.location.hostname +'; expires='+ expireDate.toUTCString() +'; SameSite=Lax; Secure';
    }
  },

  clearPersistedSessionId: function () {
    localStorage.removeItem('fastActionSessionId');

    // if this cookie is set, then it was at one point an oa hosted domain, even if its not now due to some edge case
    // (for example, client grabbed the embed code from an oa hosted form instead of from the ui in formbuilder),
    // so we always want to unset it on the current domain when clearing the session id.
    document.cookie = 'FormPageLoadFastActionSessionId=;' + ' domain='+ window.location.hostname + '; expires=Thu, 01 Jan 1970 00:00:00 UTC;' + '; SameSite=Lax; Secure';

  },

  getPersistedSessionId: function () {
    return localStorage.getItem('fastActionSessionId');
  }
}))());
