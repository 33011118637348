// Handlers, eligibility checks, and other logic for Forms Optimization experiments.
// Each array entry has data for handling one experiment. Each entry requires the following fields:
// experimentName: Name of the experiment, must match what OA serves in the form definition
// variants: array of objects, each objects has
//    variantName, must match what OA serves in the form definition
//    treatmentFunction, takes a formDef and mutates it, returning void (NB: Many experiments have a specific control variant which must be handled by doing nothing!)
// eligibilityCheck: (formDef, query_string) => bool. Return true if the form load is eligible to be an experiment sample, else return false
import _ from 'lib/lodash.custom';
import formDefUtils from 'app/FormDefUtils';

export default [
  {
    experimentName: 'Covered Costs Default Setting',
    variants: [
      {
        variantName: 'Treatment (Default Checked)',
        treatmentFunction: function (formDef) {
          // Alter the "cover cost" checkbox to have a default value of true
          var coverCostElement = formDefUtils.find_field(formDef.form_elements, 'CoverCostsAmount', false);

          if (coverCostElement) {
            coverCostElement.default_value = true;
          }
        },
      },
      {
        variantName: 'Control (Default Unchecked)',
        treatmentFunction: function () { return; } // control does nothing
      }
    ],
    eligibilityCheck: function (formDef, query_string) {
      // Form not eligible if cover costs query param exists
      if (query_string.hasOwnProperty('covercosts')) {
        return false;
      }

      // Form not eligible if cover costs element does not exist or does not have a default value of false
      var coverCostElement = formDefUtils.find_field(formDef.form_elements, 'CoverCostsAmount', false);
      return !!coverCostElement && !coverCostElement.default_value;
    }
  },
  {
    experimentName: 'Other Amount Placeholder Text',
    variants: [
      {
        variantName: 'Treatment (Other Amount)',
        treatmentFunction: function (formDef) {
          var selectAmount = formDefUtils.find_field(formDef.form_elements, 'SelectAmount', false);
          var frequencyOptions = selectAmount && selectAmount.frequencyOptions;
          var mainOptionsOther = selectAmount && selectAmount.options && selectAmount.options.find(function (e) { return e.other });
          var frequencyOptionsOther = frequencyOptions
            ? _.compact(_.map(frequencyOptions, function (arr) { return arr.options && arr.options.options && arr.options.options.find(function (e) { return e.other }) }))
            : [];

          if (mainOptionsOther) {
            mainOptionsOther.otherAmountPlaceholderOverride = 'Other Amount';
          }

          _.map(frequencyOptionsOther, function (o) { o.otherAmountPlaceholderOverride = 'Other Amount' });
        }
      },
      {
        variantName: 'Control ($0.00)',
        treatmentFunction: function () { return; }
      }
    ],
    eligibilityCheck: function (formDef, query_string) {
      // Form load not eligible if query strings interact with the amount array
      if (query_string.hasOwnProperty('am') || query_string.hasOwnProperty('amtopts') ||
        query_string.hasOwnProperty('recurringam') || query_string.hasOwnProperty('recurringamtopts')) {
        return false;
      }

      // Form load not eligible if FA auto-processing is active
      if (formDef.autoFastAction && query_string.hasOwnProperty('tknfa')) {
        return false;
      }

      // Form load not eligible if Optimized Ask is active. OA checks this qs for a value of 'true'
      // but no known workflows ever set it 'false' so here we just check if it exists
      if (query_string.hasOwnProperty('is_optimized_ask')) {
        return false;
      }

      // Form not eligible if any non-USD currencies supported
      var processingCurrency = formDefUtils.find_field(formDef.form_elements, 'ProcessingCurrency', false);
      if (!processingCurrency || processingCurrency.value !== 'USD' || processingCurrency.options) {
        return false;
      }

      // Form not eligible unless amount options are enabled and other amount is enabled
      // If the form has separate amount arrays for recurring and non-recurring, it's eligible if either includes other
      var selectAmount = formDefUtils.find_field(formDef.form_elements, 'SelectAmount', false);
      var frequencyOptions = selectAmount && selectAmount.frequencyOptions;
      // non-recurring amount array lives in selectAmount.options
      var mainOptionsOther = selectAmount && selectAmount.options && selectAmount.options.find(function (e) { return e.other });
      // when using separate recurring amounts, recurring amount array lives in
      // selectAmount.frequencyOptions[n].options.options for some n
      var frequencyOptionsOther = frequencyOptions
        ? _.compact(_.map(frequencyOptions, function (arr) { return arr.options && arr.options.options && arr.options.options.find(function (e) { return e.other }) }))
        : [];
      if (!mainOptionsOther && frequencyOptionsOther.length === 0) {
        return false;
      }

      return true;
    }
  },
  {
    experimentName: 'Eligibility Checkbox Default Setting',
    variants: [
      {
        variantName: 'Treatment (Default Checked)',
        treatmentFunction: function (formDef) {
          var eligibilityCheckbox = formDefUtils.find_field(formDef.form_elements, 'ConfirmedEligibililty', false);
          if (eligibilityCheckbox) {
            eligibilityCheckbox.default_value = true;
          }
        }
      },
      {
        variantName: 'Control (Default Unchecked)',
        treatmentFunction: function () { return; }
      }
    ],
    eligibilityCheck: function (formDef, query_string) {
      // Form not eligible if ConfirmedEligibility query param exists
      if (query_string.hasOwnProperty('confirmedeligibility')) {
        return false;
      }

      // Form not eligible if eligibility element does not exist or does not have a default value of false
      var eligibilityCheckbox = formDefUtils.find_field(formDef.form_elements, 'ConfirmedEligibililty', false);
      return !!eligibilityCheckbox && !eligibilityCheckbox.default_value;
    }
  },
  {
    experimentName: 'FastAction Autofill Setting',
    variants: [
      {
        variantName: 'Control (Autofill Enabled)',
        treatmentFunction: function () {
          return;
        },
      },
      {
        variantName: 'Treatment (Autofill Disabled)',
        treatmentFunction: function (formDef, formModel) {
          formDef.fastAction = false;
          formModel.set('fastAction', false);
        },
      }
    ],
    eligibilityCheck: function (formDef) {
      return formDef.fastAction === true;
    }
  }
];
