import Backbone from 'lib/backbone';
import FieldView from 'app/views/FieldView';
import invokeCallbacks from 'app/NVTagCallbacks';
import _ from 'lib/lodash.custom';

function _configureCaptchaWithGrid(captcha) {
  if (captcha.simpleCaptcha) {
    return captcha;
  }

  //pseudo-responsive sizing
  var body = Backbone.$('body');
  var maxWidth = Math.min(350, (body.width() - 75));
  var maxHeight = Math.min(400, (body.height() - 25));

  var newCaptcha = _.clone(captcha);
  // determine grid.
  // gridHeight determine the number of blocks from left to right
  // gridWidth determine the number of blocks from top to bottom
  // pixelHeight and pixelWidth is how big the original image is in pixels
  // gridHeight, gridWidth, pixelHeight, and pixelWidth are given to us.
  var gridHeight = captcha.gridHeight;
  var gridWidth = captcha.gridWidth;
  var imgHeight = captcha.pixelHeight;
  var imgWidth = captcha.pixelWidth;


  var imageRatio = imgHeight / imgWidth;
  var gridRatio = gridHeight / gridWidth;
  var dimRatio = imageRatio * gridRatio;

  if (imgWidth > maxWidth) {
    imgWidth = maxWidth;
    imgHeight = imageRatio * imgWidth;
  }
  if (imgHeight > maxHeight) {
    imgHeight = maxHeight;
    imgWidth = imgWidth / imageRatio;
  }

  var cellSize = imgHeight / gridHeight;

  var cellHeight = cellSize;
  var cellWidth = cellSize * dimRatio;

  newCaptcha.cellHeight = cellHeight + 'px';
  newCaptcha.cellWidth = cellWidth + 'px';
  newCaptcha.height = imgHeight + 'px';
  newCaptcha.width = imgWidth + 'px';

  newCaptcha.gridRow = [];
  var cellCount = 0;
  for (var i = 0; i < gridHeight; i++) {
    var row = [];
    for (var j = 0; j < gridWidth; j++) {
      row.push({
        idx: ++cellCount,
        bkgPos: '-' + (cellWidth * j) + 'px ' + '-' + (cellHeight * i) + 'px',
        bkgSize: imgWidth + 'px ' + imgHeight + 'px'
      });
    }
    newCaptcha.gridRow.push(row);
  }

  return newCaptcha;
}

export default FieldView.extend({
  __name__: 'ReCaptchaView',
  type: 'recaptcha_grid',
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    this.template = this.options.templates.recaptcha_grid;
  },
  context: function () {
    var context = _configureCaptchaWithGrid(this.def.captcha);
    return invokeCallbacks('alterContext', { context: context }).context;
  },
  render: function () {
    this.$el.html(this.template(this.context()));
    return this;
  }
});
