// Configs related to fields on the FastActionUser profileData, previously called the Databag

var canonical = {
  'email': 'EmailAddress',
  'firstName': 'FirstName',
  'lastName': 'LastName'
};

var allowlist = [
  // Contact Information
  'Prefix', 'FullName', 'FirstName', 'MiddleName', 'LastName', 'Suffix', 'DateOfBirth',
  'Pronoun', 'Genders', 'Race', 'Ethnicity', 'SexualOrientation', 'PreferredLanguage', 'PreferredLanguages',
  'AddressLine1', 'AddressLine2', 'City',
  'StateProvince', 'PostalCode', 'Country', 'HomePhone', 'MobilePhone', 'WorkPhone', 'EmailAddress', 'WorkEmail',
  'TwitterHandle', 'FacebookProfileUrl',
  'HomePhoneCountryCode', 'MobilePhoneCountryCode', 'WorkPhoneCountryCode',
  // Org Contact info
  'OrganizationName', 'OrganizationEmailAddress', 'OrganizationPhone', 'OrganizationPhoneCountryCode',
  // Employer Information
  'Employer', 'Occupation', 'WorkAddressLine1', 'WorkAddressLine2', 'WorkCity', 'WorkStateProvince',
  'WorkPostalCode', 'SpouseName',
  'email', 'firstName', 'lastName'
];

// These items are always on the Databag, these keys are absolutely
// denylisted and can never be set on a Databag under any circumstance.
var denylist = [
  'Comment', 'version', 'Account', 'ExpirationMonth', 'ExpirationYear', 'formType', 'TenantName', 'Cvv2',
  'ProvideAmountOptions', 'SelectAmount', 'OtherAmount', 'SuggestedAmount', 'Amount', 'MarketSource',
  'AttributedContactIds', 'ContributionDate', 'ContributionProcessedOn', 'IsRecurring', 'MailingId',
  'SelectedFrequency', 'SourceCodeIds', 'ngpvanuser', 'TicketHolders', 'Tickets', 'ConfirmedEligibility',
  'TicketHolders.Index', 'TicketHoldersJson', 'CampaignEventId', 'AdditionalContributionValue',
  'YesSignMeUpForEmailUpdates', 'YesSignMeUpForUpdatesForBinder', 'ProcessingCurrency',
  // Drupal items
  'form_build_id', 'form_id', 'op', 'type', 'oberon_form_id', 'form_token',
  // Event items
  'AttendeeFirstName', 'AttendeeLastName', 'TicketLevel', 'TicketCount',
  // Databag specific
  'indexed_on', 'modified_on',
  // Card Sign items
  'formCaptureImage',
  //captcha
  'CaptchaResponseToken', 'CaptchaSecureToken', 'CaptchaTimeElaspedMs',
  // device info
  'BrowserName', 'DeviceType'
];

var pushPullDenylist = [ // PULL/PUSH items
  // These are items that are in the Server-side bag that we never want to pull or push.
  'CCType', 'PnRef', 'ContributionDateTimeStamp', 'ContributionType', 'FullName', 'ConfirmationID',
  'confirmationID', 'ContributionAmount', 'CC4Digit'
];

export default {
  canonical: canonical,
  allowlist: allowlist,
  denylist: denylist,
  pushPullDenylist: pushPullDenylist
};
