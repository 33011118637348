import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import TextFieldView from 'app/views/TextFieldView';
import autocomplete from 'data/autocomplete';
import 'lib/select2';
import otherOption from 'app/views/helpers/otherOption';

var $ = Backbone.$,
  _ = Backbone._;

var isTruthyOrZero = function (val) {
  return !!(val || val === 0);
};
var SelectView = FieldView.extend({
  __name__: 'SelectView',
  tagName: 'label',
  events: {
    'change select': 'renderFeedback'
  },
  className: function () {
    var def = this.options.definition;
    var classes = 'at-select ' + def.name;
    if (def.options && def.options.length <= 2) {
      classes = classes.replace('form-item ', '') + ' select-collapse';
    }
    return classes;
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    _.bindAll(this, 'changeCityIfNotUSA', 'showHideOther', 'focusOnSelect2Parent');
    if (/Country/.test(this.name)) {
      this.$el.on('change', 'select', this.changeCityIfNotUSA);
    }
    var def = this.def;
    // If an 'other' text field is enabled, we register a handler to show/hide it on select changing
    if (def.other) {
      this.$el.on('change', 'select', this.showHideOther);
    }

    // Select2 single-select typeaheads are bad at focusing, this fixes that so supporters can keep tabbing through
    if (!def.allowMultipleValues && def.typeahead) {
      this.$el.on('change', 'select', this.focusOnSelect2Parent);
    }

    if (/StateProvince/.test(this.name) && !def.option_groups) {
      // is a state province dropdown, with options from formdef
      def.option_groups = {};
      _.each(def.options, function (opt) {
        if (!opt.value) return; // skip these empty ones, they will get re-added when the options update
        var group = opt.group || 'US';
        def.option_groups[group] = def.option_groups[group] || [];
        def.option_groups[group].push(opt);
      });
      if (def.option_groups.US) {
        // this ensures the US States are shown, even if there is no country input
        // if the default country is non-US, the country change handler will change the options being used
        this.setOptions(this, 'US');
      }
    }

  },
  setOptions: function (selectView, key) {
    var options = this.hasOptions(selectView, key) ? selectView.def.option_groups[key] : [];
    selectView.def.options_key = key;
    var firstOptDisplayName = this.resources.PrimaryResources.DefaultSelectOption;
    if (/StateProvince/.test(selectView.name)) {
      if (key === 'CA') {
        firstOptDisplayName = this.resources.PrimaryResources.DefaultProvinceSelectOption;
      } else {
        firstOptDisplayName = this.resources.PrimaryResources.DefaultStateSelectOption;
      }
    }
    selectView.def.options = [{ value: '', display: firstOptDisplayName }].concat(options);
  },
  hasOptions: function (selectView, key) {
    return !!(selectView.def.option_groups && selectView.def.option_groups[key]);
  },
  isValueUSA: function (value) {
    return (value === 'United States' || value === 'US');
  },
  normalizeCountry: function (value) {
    return this.isValueUSA(value) || !value ? 'US' : value;
  },
  changeCityIfNotUSA: function (e) {
    if (/Country/.test(this.name)) {
      var self = this;
      var country = e || '';
      if (_.isObject(e) && e.currentTarget) {
        country = e.currentTarget.value;
      }
      var countryKey = this.normalizeCountry(country);
      // use field prefix to get the rest of the fields
      var prefix = this.getFieldPrefix(this.name, 'Country');
      var stateIndex = _.findIndex(this.parent.def.children, { name: prefix + 'StateProvince' });
      var stateView = this.parent.subviews[prefix + 'StateProvince'];
      var zipIndex = _.findIndex(this.parent.def.children, { name: prefix + 'PostalCode' });
      var zipView = this.parent.subviews[prefix + 'PostalCode'];
      if (stateView) {
        // The defers are very important in here since we may end up detecting changes to the country before the
        // state province values have finished being updated
        // the defers give all of the updates a chance to finish before proceeding
        // within each defer is a DOM-attachment check to ensure we aren't doing throwaway operations and
        // assuming the in-memory representation of the form is accurate
        if (stateView.type === 'textfield' && this.hasOptions(stateView, countryKey)) {
          _.defer(function () {
            if (!stateView.$el.closest(document.documentElement).length) {
              // stateView is no longer in the DOM
              // the swap has probably already happened and been deferred twice
              return;
            }
            self.parent.def.children[stateIndex].type = 'select';
            var selectView = new SelectView(self.parent.subviewOptions(stateIndex));
            self.setOptions(selectView, countryKey);
            selectView.render().setval('').$el.insertBefore(stateView.$el);
            if (stateView.el.style.width) {
              selectView.el.style.width = stateView.el.style.width;
            }
            stateView.remove();
            self.parent.subviews[prefix + 'StateProvince'] = selectView;
          });
        } else if (stateView.type === 'select' && !this.hasOptions(stateView, countryKey)) {
          _.defer(function () {
            if (!stateView.$el.closest(document.documentElement).length) {
              // stateView is no longer in the DOM
              // the swap has probably already happened and been deferred twice
              return;
            }
            var textValue = stateView.def.text_value || '';
            delete stateView.def.text_value;
            self.parent.def.children[stateIndex].type = 'textfield';
            var textView = new TextFieldView(self.parent.subviewOptions(stateIndex));
            textView.render().setval(textValue).$el.insertBefore(stateView.$el);
            if (stateView.el.style.width) {
              textView.el.style.width = stateView.el.style.width;
            }
            stateView.remove();
            self.parent.subviews[prefix + 'StateProvince'] = textView;
          });
        } else if (stateView.type === 'select' && this.hasOptions(stateView, countryKey) && countryKey !== stateView.def.options_key) {
          this.setOptions(stateView, countryKey);
          stateView.render().setval(''); // re-render to get the options update, and reset value
        }
      }
      if (zipView || zipIndex >= 0) {
        var validation;
        var def = zipView ? zipView.def : self.parent.def.children[zipIndex];
        if (!this.isValueUSA(country) && def.validation === 'postalCodeUS') {
          validation = 'postalCode';
        } else if (this.isValueUSA(country) && def.validation === 'postalCode') {
          validation = 'postalCodeUS';
        }
        if (validation) {
          _.defer(function () {
            self.parent.def.children[zipIndex].validation = validation;
            if (zipView) {
              var zip = zipView.val();
              zipView.def.validation = validation;
              zipView.render().setval(zip);
            }
          });
        }
      }
    }
    return this;
  },
  render: function () {
    this.el.className = this.className();
    this.collapsedTemplate = this.options.templates.span;
    var self = this,
      def = _.clone(this.def),
      opt = {};

    if (this.options.labels === 'placeholder') {
      def.labelhide = true;
    }

    // Assign a class that we use in css to assign z-index so that when multi selects are stacked on top of each other, the
    //dropdown options from the first select are over the main field for the second select.
    if (def.allowMultipleValues) {
      this.el.className += ' multi-select';
    }

    var context = {
      name: this.name,
      selectId: this.getBaseId() + '-select',
      name_lower: this.name.toLowerCase(),
      classes: this.className(),
      label: this.def.label || this.title,
      title: this.title,
      required: def.required ? 'required' : '',
      labelhide: this.options.labels === 'placeholder',
      autocomplete: autocomplete(this.name),
      options: def.options,
      multiple: def.allowMultipleValues ? 'multiple' : ''
    };

    var parent = this.options.parent;
    if (parent.name === 'PaymentInformation'
      && 'required' in parent.options.definition && !parent.options.definition.required) {
      context.required = '';
    }

    if (/^Selected(Frequency|Duration)$/.test(this.name)) {
      context.labelhide = true;
    }

    // Collapse Selects with only 1 value unless it's "HostCommittee" or "CardSignEmployers"
    // (has length of 2 because of the NoSelection placeholder.)
    // only want to collapse if there is one value and it is required.  Also, SelectedFrequency and selected duration are not
    // saved as required so we still want to collapse in the case of a single selected frequency/duration on a recurring contribution
    if (context.options && context.options.length <= 2 && context.name !== 'HostCommittee' && context.name !== 'CardSignEmployers' &&
      (context.required === 'required' || context.name === 'SelectedFrequency' || context.name === 'SelectedDuration')) {
      context.value = context.options[1].value;
      context.display = context.options[1].display;
      this.options.collapseValue = context.value;

      context = invokeCallbacks('alterContext', { element: this.type, context: context, def: def }).context;
      this.$el.html(this.collapsedTemplate(context));
      this.$el.find('span.select-collapse').attr('id', this.getBaseId() + '-label');
    } else {
      // If labels are placeholders, we want to put the label into the
      // select as the default option.
      if (context.labelhide) {
        opt = _.where(context.options, { value: '' });
        if (opt.length > 0) {
          opt[0].display = '- ' + context.title + (context.required ? '* -' : ' -');
        }
      }
      if (def.required) {
        context.options[0].disabled = 'disabled';
      }
      def.default_value = isTruthyOrZero(def.default_value) ? def.default_value : null;
      if (context.name === 'EventType' && def.default_value === '0') {
        def.default_value = null;
      }

      if (context.name === 'EventTimeZone' && window.Intl) {
        // if the time zone component has no default value, we auto-detect a time zone based on the EMCA Internationalization API
        if (!def.default_value) {
          def.default_value = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      }

      context = invokeCallbacks('alterContext', { element: this.type, context: context, def: def }).context;
      this.$el.html(this.template(context));
      this.setval(def.default_value);
    }
    if (this.shouldHide()) {
      this.$el.hide();
    }

    // 'other' textfield may need to be rendered
    // call common method to identify 'other' element and bind it to this one
    // may render an 'other' textfield depending on def and presence of 'other' option
    otherOption.render(this);

    if (context.multiple || def.typeahead) {
      // this will treated as multi-select if the "multiple" HTML attribute is on the select element
      // which this "context.multiple" will end causing to be there
      self.$('select').select2({
        placeholder: context.multiple ? this.resources.PrimaryResources.DefaultSelectOption : undefined,
        dropdownParent: this.$el
      });

      if (def.optionsUrl) {
        self.$('select').select2({
          ajax: {
            url: def.optionsUrl,
            dataType: 'json',
            type: 'GET',
            delay: 500,
            data: function (param) {
              return {
                query: param.term,
                top: 50
              };
            },
            processResults: function (data) {
              return {
                results: $.map(data, function (option) {
                  return {
                    id: option.value,
                    text: option.display
                  };
                })
              };
            }
          }
        });
      }
    }

    if (/Country/.test(this.name) && def.default_value) {
      _.defer(function () {
        self.$('select').trigger('change');
      });
    }

    return this;
  },
  focusOnSelect2Parent: function () {
    if (!this.def.allowMultipleValues && this.def.typeahead && $(document.activeElement).is('input[class*="select2"]')) {
      // If a user has changed the value of a select2 using the keyboard, we want to focus on the parent input so they can keep tabbing
      this.$(':tabbable:first').focus();
    }
  },
  showHideOther: function () {
    // Is 'other' was selected, we might need to 'unhide' the other text field
    if (this.def.other) {
      otherOption.showHide(this);
    }
  },
  val: function () {
    var val = this.$('select').val();

    // Ugh, special cases...
    if (/^Selected(Frequency|Duration)$/.test(this.name)) {
      val = $('select[name^="' + this.name + '"]').val();
      val = isNaN(val) ? val : +val;
    }

    // If this select was collapsed, we want that value
    val = this.options.collapseValue ? this.options.collapseValue : val;

    return _.isArray(val) && val.length > 0 ? val.join('||') : val || val === 0 ? val : null;
  },
  renderFeedback: function () {
    return this.renderFeedbackWithErrors(this.errors());
  },
  errors: function () {
    var errors = !this.require_valid() ? [this.resources.fill(this.resources.PrimaryResources.BlankIsRequired, this.title)] : [];
    errors = _.map(invokeCallbacks('alterErrors', {
      val: this.val(),
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors, this.toError, this);
    return errors;
  },
  setval: function (value) {
    // In jquery 1.10 if the value being set on the select control doesn't exist,
    // jquery does the following selectedIndex = -1, which results in an empty value
    // being created and then selected.
    var newValNum = isNaN(value) ? value : +value;
    var currValNum = this.val();
    currValNum = isNaN(currValNum) ? currValNum : +currValNum;
    if (currValNum !== newValNum) {
      var $select = this.$('select');
      if ($select.length) {
        delete this.def.text_value; // in case this was populated already, clear it

        if (this.def.allowMultipleValues) {
          var vals = value ? newValNum.toString().split(/\|\||,/) : [];
          $('[name="' + this.name + '"]').select2({
            placeholder: this.resources.PrimaryResources.DefaultSelectOption
          }).val(vals).trigger('change');
        } else if (value === null || value === '') {
          $select.val($select.find('option:first').val());
        } else if ($select.find('option[value="' + value + '"]').length) {
          $select.val(value).trigger('change');
        } else {
          this.def.text_value = value; // hang on to it in case this gets converted to a textfield
        }
      }
    }
    return this.touch().hideOrShow();
  },
  type: 'select'
});
export default SelectView;
