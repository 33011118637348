import Braintree from 'lib/braintree';
import PayPal from 'lib/paypal';
import $ from 'lib/jquery.ui';
import telemetry from 'app/views/helpers/telemetry';

function initPayPalBraintreeInstance(formUrl) {
  return new window.Promise(function (resolve, reject) {

    $.ajax({
      url: formUrl,
      xhrFields: { withCredentials: true },
      type: 'GET'
    }).done(function (token) {
      // Create a Braintree Client instance.
      Braintree.create({
        authorization: token
      }).then(function (clientInstance) {
        telemetry.trackEvent({
          name: 'PayPal Braintree: created Braintree client instance',
          properties: {}
        });

        // Create a PayPal Checkout instance.
        return PayPal.create({
          client: clientInstance
        }).then(function (paypalInstance) {
          telemetry.trackEvent({
            name: 'PayPal Braintree: created PayPal client instance',
            properties: {}
          });

          resolve(paypalInstance);
        }).catch(function (e) {
          telemetry.trackException(e, {
            ErrorType: 'PayPal Braintree',
            Message: 'Failed to create PayPal client instance'
          });

          throw e;
        });
      }).catch(function (err) {
        telemetry.trackException(err, {
          ErrorType: 'PayPal Braintree',
          Message: 'Failed to create Braintree client instance'
        });

        var payPalInstanceFailed = true;
        reject(payPalInstanceFailed);
      });
    }).fail(function (xhr, status, err) {
      telemetry.trackException(err, {
        ErrorType: 'PayPal Braintree',
        Message: 'Failed to get token during init',
        HttpStatus: status
      });

      var payPalInstanceFailed = true;
      reject(payPalInstanceFailed);
    });
  });
}


function mapPayPalBraintreeResponse(paypalDetails) {
  // If we get the billing address, use that.  Otherwise, use shipping.
  // (Accounts need a special permission from PayPal to get the billing address)
  // We sometimes only get a country code in billing address, so ensure there's a real address there.
  var address = isValidAddress(paypalDetails.billingAddress) ? paypalDetails.billingAddress : paypalDetails.shippingAddress;
  var dict = {
    FirstName: paypalDetails.firstName,
    LastName: paypalDetails.lastName,
    AddressLine1: address && address.line1,
    AddressLine2: address && address.line2,
    City: address && address.city,
    StateProvince: address && address.state,
    PostalCode: address && address.postalCode,
    Country: address ? address.countryCode : paypalDetails.countryCode,
    EmailAddress: paypalDetails.email,
    HomePhone: paypalDetails.phone,
    MobilePhone: paypalDetails.phone
  };
  return dict;
}

function isValidAddress(address) {
  return address && address.line1 && address.city && address.postalCode;
}

export default {
  initPayPalBraintreeInstance: initPayPalBraintreeInstance,
  mapPayPalBraintreeResponse: mapPayPalBraintreeResponse
};
