import $ from 'lib/jquery';
import loadScript from 'app/loadScript';
import assetUrl from 'app/assetUrl';
import deviceInfo from 'app/deviceInfo';

function ensureHtml2CanvasLoaded() {

  if (!window.html2canvas) {
    var html2canvasUrl = assetUrl('js/html2canvas.min.js');
    return loadScript(html2canvasUrl);
  }
  return $.Deferred().resolve().promise();
}

function htmlToCanvas(html) {
  var dfd = $.Deferred();

  $.when(ensureHtml2CanvasLoaded()).then(function () {

    // When the canvas for the image is first rendered it is set to the
    // width and height of the html passed in.
    // Some forms may have a header affixed to them which adjusts the
    // relative position of the start of the form. When a user is scrolled
    // within the form we want to tell the rendering library to "start"
    // from the actual position of the form on the page vs a relative one.
    var clientRect = html.getBoundingClientRect();
    var scrollY = window.pageYOffset + clientRect.top;

    // Use the full height of the page when rendering the canvas.
    // Some mobile browsers will render the canvas with extra padding
    // and margins which will cut off content at the bottom without
    // the height being explicitly defined. We are adding an extra 25%
    // of the max-height to account for any anomolies that can occur.
    var minViewPortWidth = '500';
    var body = document.body;
    var docElement = document.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight, docElement.clientHeight, docElement.scrollHeight, docElement.offsetHeight) * 1.25;
    var width = Math.max(body.scrollWidth, body.offsetWidth, docElement.clientWidth, docElement.scrollWidth, docElement.offsetWidth, minViewPortWidth);

    var config = {
      scale: 1,
      scrollX: 0,
      scrollY: scrollY,
      height: height,
      width: width,
      useCORS: true,
      onclone: function (document) {
        if (deviceInfo.browser === 'safari' || deviceInfo.mobile) {
          var selectWrappers = document.getElementsByClassName('at-select');
          Array.from(selectWrappers).forEach(hideAndConvertSelectElements);
        }
      },
      ignoreElements: function (element) {
        // internet explorer, safari and mobile browsers don't like rendering the SVG images/background-images
        // as they are a vector for cross-site scripting. The canvas img will be generated but the supporter
        // will be unable to finish submitting the form. Because of this we're just going to ignore those all
        // the time for consistency.
        // SVGs can be hidden in CSS, glyphicons, or can be explicitly rendered on the form.
        // If this feature is rolled out to more form types (other than signup card signs) make sure to update this list
        // to include any new elements that could be an issue.
        // explicitly check the classList because IE didn't like any other solution...
        var shouldIgnore = element.classList.contains('FastAction') ||
          element.classList.contains('UpdateMyProfile') ||
          element.classList.contains('flag-container');

        return shouldIgnore;
      }
    };

    // Safari throws a security exception when <select> inputs are drawn on the canvas
    // presumably because it "taints" the canvas and thinks there is malicious SVG content.
    // To get past that we will hide all <select> inputs and render values that live
    // within single-selects in an <input>.
    // Multi Select2 behave differently than regular <select> elements for displaying
    // their values, so just hiding their backing <select> is sufficient.
    // This only alters the rendering of the cloned DOM.
    function hideAndConvertSelectElements(selectWrapper) {
      var selectObj = selectWrapper.getElementsByTagName('select')[0];

      // if this is a single-select select2 object
      if (selectObj.classList.contains('select2-hidden-accessible')) {
        var selectionContainer = selectWrapper.getElementsByClassName('select2-selection')[0];

        if (selectionContainer.classList.contains('select2-selection--single')) {
          convertToInput(selectWrapper, selectionContainer.textContent);
          // hide the actual select input shown on the forms
          $(selectionContainer).hide();
        }
      } else {
        convertToInput(selectWrapper, $(selectObj).children(':selected')[0].text);
      }

      // we have to hide the all <select>s from showing on the canvas
      // or else the exception will be thrown
      $(selectObj).hide();
    }

    function convertToInput(selectWrapper, displayText) {
      var inputField = document.createElement('input');
      inputField.type = 'text';
      inputField.value = displayText;
      selectWrapper.appendChild(inputField);
    }

    window.html2canvas(html, config).then(function (canvas) {
      dfd.resolve(canvas);
    });
  });
  return dfd.promise();
}

export default {
  htmlToCanvas: htmlToCanvas
};
