import $ from 'lib/jquery.ui';
import _ from 'lib/lodash.custom';
import FastActionUser from 'app/models/FastActionUser';

var tknfa = /&tknfa=[^&]+/;

function loadImg(url) {
  var dfd = new $.Deferred(), i = new Image();
  var done = function () {
    dfd.resolve(url);
  };
  var src = url + '?_=' + _.now();
  i.onload = done;
  i.onerror = done;
  _.defer(function () {
    i.src = src;
  });
  return dfd.promise();
}

function logoutProviders(urls) {
  var providers = _(urls);
  return (providers.isArray() && !providers.isEmpty()) ?
    $.when.apply($, providers.map(loadImg).value()) :
    (new $.Deferred()).resolve(urls).promise();
}

export default function (sessionId) {
  $('a[data-popup]').unbind().click(function (e) {
    e.preventDefault();
    var data = $(this).data(),
      href = $(this).attr('href'),
      l = window.location,
      popupWindow,
      popup,
      pop = {
        left: 0,
        top: 0,
        width: data.popupWidth || 1025,
        height: data.popupHeight || 640
      };

    // Pass the FastActionSessionId from the FastActionUser model (not from LocalStorage)
    // on all auth popup FastAction requests. We need to pass from the model because if we are not logged
    // in localStorage won't have a sessionId. Note: if FastAction fetch calls error, we may fail to have
    // a sessionId on the model. In this case we get weird behavior like login popups that fail to do anything
    // because ActionTag doesn't have knowledge of the logged-in FastActionSessionId
    if (sessionId) {
      const url = new URL(href);
      url.searchParams.set('fastActionSessionId', sessionId);
      href = url.toString();
    }

    if (/logout/.test(href)) {
      return $.jsonp({
        url: href,
        cache: false
      }).then(function (urls) {
        logoutProviders(urls);
        // clear local storage and user state
        FastActionUser.clear({ clearPersistedSessionId: true });
        if (tknfa.test(l.search)) {
          // triggers a new document load
          window.location = l.protocol + '//' + l.host + l.pathname + l.search.replace(tknfa, '');
        } else {
          // fetch fresh after logout so the model has a new sessionId
          FastActionUser.fetch();
        }
      });
    }

    if ('popupTop' in data && 'popupLeft' in data) {
      pop.top = ~~data.popupTop;
      pop.left = ~~data.popupLeft;
    } else if (screen && screen.width && screen.height) {
      pop.top = ~~((screen.height / 2) - (pop.height / 2));
      pop.left = ~~((screen.width / 2) - (pop.width / 2));
    }

    popup = {
      url: href,
      name: 'FastAction',
      options: 'toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=0,' + $.param(pop).replace(/&/g, ',')
    };

    popupWindow = window.open(popup.url, popup.name, popup.options);

    var onPopupClosed = function (callback) {
      var closePopup = function () {
        if (popupWindow.closed) {
          if (window.location.hash) {
            window.location.hash = '!';
          }
          callback();
        } else {
          setTimeout(closePopup, 1000 / 60);
        }
      };
      setTimeout(closePopup, 1000 / 60);
    };

    if (/log_in|sign_up|auth/.test(popup.url)) {
      onPopupClosed(function () {
        // explicitly pass the sessionId into the fetch request to re-fetch any profile info
        // now associated with this session. We can't persist the sessionId in localStorage until
        // after we get a user back from this fetch because it is possible the supporter closed
        // the popup without logging in
        FastActionUser.fetch({ fastActionSessionIdOverride: sessionId });
      });
    } else if (/profile/.test(popup.url)) {
      onPopupClosed(function () {
        FastActionUser.fetch({ fastActionSessionIdOverride: sessionId });
      });
    }
  });
}
