export default {
  translatedPrefixes: function (resources) {
    return [
      resources.PrimaryResources.Dr,
      resources.PrimaryResources.Miss,
      resources.PrimaryResources.Mr,
      resources.PrimaryResources.Mrs,
      resources.PrimaryResources.Ms,
      resources.PrimaryResources.Mx
    ];
  }
};
