import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import otherOption from 'app/views/helpers/otherOption';
import _ from 'lib/lodash.custom';

var $ = Backbone.$;
export default FieldView.extend({
  __name__: 'CheckboxView',
  tagName: 'label',
  events: {
    'change input': 'renderFeedback' // This is a hack. See: https://github.com/jashkenas/backbone/pull/2650 - Bjørn
  },
  className: function () {
    return 'at-check  ' + this.options.definition.name;
  },
  val: function () {
    var val = this.$(':input').prop('checked');

    if (this.options.definition.name === 'IsRecurring') {
      val = $('[name="IsRecurring"]').prop('checked');
    }
    return val;
  },
  setval: function (value) {
    var oldValue = this.$(':input').prop('checked');
    this.$(':input').prop('checked', value);
    if (this.options.definition.name === 'IsRecurring') {
      $('[name="IsRecurring"]').prop('checked', value);
    }

    this.updateContactMode();

    // If the value has actually changed, we may need to show/hide a text box associated
    // with an 'other' option
    // It must be deferred because 'render' has not happened at point when query string values
    // are applied to form fields
    if (oldValue !== this.$(':input').prop('checked')) {
      this.showHideOther();
    }
    return this.touch().hideOrShow();
  },
  context: function () {
    var def = this.options.definition;

    var context = {
      name: def.name,
      title: def.title,
      checked: def.default_value ? 'checked' : '',
      required: def.required ? 'required' : '',
      labelId: this.getBaseId() + '-label'
    };

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: def }).context;
  },
  render: function () {
    this.$el.html(this.template(this.context()));
    var self = this;

    // may render an 'other' textfield depending on def and presence of 'other' option
    otherOption.render(self);

    return this;
  },
  errors: function () {
    var errors = !this.require_valid() ? [this.field_title() + ' is a required field.'] : [];

    // If this is the confirm eligibility checkbox and NotMe is enabled, we don't want to
    // require this checkbox.
    if (this.options.definition.name === 'ConfirmedEligibility' && this.options.formview.options.showNotMe) {
      errors = [];
    }

    errors = invokeCallbacks('alterErrors', {
      val: this.val(),
      field_name: this.field_name(),
      errors: errors,
      def: this.options.definition
    }).errors;

    this.trigger(errors.length ? 'invalid' : 'valid');
    return _.map(errors, this.toError, this);
  },
  notEmpty: function () {
    return !!this.val();
  },
  require_valid: function () {
    // Valid if it's filled, or doesn't need to be filled
    return (this.notEmpty() || !(this.options.definition.required));
  },
  renderFeedback: function () {
    this.showHideOther();
    FieldView.prototype.renderFeedback.apply(this);
    this.updateContactMode();
  },
  showHideOther: function () {
    // Is 'other' was selected, we might need to 'unhide' the other text field
    if (this.def.other) {
      otherOption.showHide(this);
    }
  },
  updateContactMode: function () {
    if (this.name === 'OrganizationToggle') {
      var contactMode = this.val() ? 'org' : 'person';
      this.options.formview.updateContactMode(contactMode);
    }
  },
  type: 'checkbox'
});
