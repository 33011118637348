import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import VgsTextFieldView from 'app/views/VgsTextFieldView';
import 'lib/jquery.payment';

var $ = Backbone.$,
  _ = Backbone._;
export default VgsTextFieldView.extend({
  __name__: 'CVV2View',
  className: 'at-text at-cc-csc',
  vgsConfig: {
    type: 'card-security-code',
    validations: ['validCardSecurityCode'],
    autoComplete: 'cc-csc',
    css: {
      fontSize: '.875rem',
      fontFamily: 'monospace',
      lineHeight: '1',
      backgroundColor: '#fff',
      '&::placeholder': {
        color: '#ced4da',
        fontWeight: 'bold'
      }
    }
  },
  context: function () {
    var context = {
      name: this.name,
      title: this.title,
      label: this.def.label || this.title,
      labelhide: this.options.labels === 'placeholder',
      required: this.def.required ? 'required' : '',
      value: this.def.default_value || '',
      vgsContainerId: this.vgs.containerId,
      vgsLoaded: this.vgs.loaded
    };

    context.placeholder = this.def.placeholder || (context.labelhide ? 'Card CVV' : '•••');

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  fallbackRender: function () {
    this.input = this.$('input').payment('formatCardCVC').get(0);
    return this;
  },
  val: function () {
    if (this.vgs.loaded) {
      return null;
    }
    return this.input.value || null;
  },
  setval: function () {
    // can't do this
  },
  errors: function () {
    var errors = [], cvv = this.val();

    if (this.vgs.loaded) {
      cvv = !this.vgs.state || this.vgs.state.isEmpty ? '' : true;
    }

    if (!cvv) {
      errors.push(this.resources.PrimaryResources.SecurityCodeRequired);
    } else if (this.vgs.loaded) {
      if (!this.vgs.state.isValid) {
        errors.push(this.resources.PrimaryResources.InvalidSecurityCode);
      }
    } else {
      var type = this.parent.cc_type;
      if (!type) {
        var card = this.parent.subviews.Account.val();
        type = card ? $.payment.cardType(card) : null;
      }
      if (!$.payment.validateCardCVC(cvv, type)) {
        var formalType = this.parent.accepted[type];
        var errMsg = formalType ?
          this.resources.fill(this.resources.PrimaryResources.InvalidSecurityCodeForBlank, formalType.name) :
          this.resources.PrimaryResources.InvalidSecurityCode;
        errors.push(errMsg);
      }
    }

    errors = invokeCallbacks('alterErrors', {
      val: cvv,
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors;
    this.trigger(errors.length ? 'invalid' : 'valid');
    return _.map(errors, this.toError, this);
  },
  type: 'cvv2'
});
