import _ from 'lib/lodash.custom';
import telemetry from 'app/views/helpers/telemetry';
import FormOptimizationExperimentsLogic from 'app/FormOptimizationExperimentsLogic';

// Eligibility criteria shared by all experiments; in order for a sample to be recorded
// for any form load in any experiment, this check must return true
function isGenericExperimentEligible(formDef, query_string) {
  if (!formDef.hasOwnProperty('experiment')) {
    return false;
  }

  // Form not eligible if kiosk query param exists
  if (!!query_string.kiosk) {
    return false;
  }

  // Form not eligible if language is not English. metadata.languageCode is missing when
  // the committee only allows english. In this case it's en-US by default and considered eligible
  if (formDef.metadata && formDef.metadata.languageCode && formDef.metadata.languageCode !== 'en-US') {
    return false;
  }

  // Form not eligible if the type of our form is not equal to "ContributionForm"
  if (formDef.type !== 'ContributionForm') {
    return false;
  }

  if (!formDef.experiment.hasOwnProperty('variants')) {
    return false;
  }

  // Form not eligible if the sum of the probabilities of the variants is not equal to exactly 1
  var sum = 0;
  _.each(formDef.experiment.variants, function (variant) { sum += variant.probability; });
  // Handle js floating point issues
  sum = Math.round(sum * 10000) / 10000;
  return sum === 1;
}

// Choose a variant by iterating through the variant array, with a random number between 0.0 and 1.0
function weightedVariantChoice(variants, randomNumber) {
  var accumulator = 0;

  // iterate thru variant array
  for (var i = 0; i < variants.length; i++) {
    var variant = variants[i];

    // accumulate the probability, if we get above the randomNumber,
    // choose this variant, otherwise continue
    accumulator += variant.probability;
    if (accumulator >= randomNumber) {
      return variant;
    }
  }
}

// supporting forcing a variant selection with the query param `overrideVariantName`
function getOverrideVariant(variants, overrideVariantName) {
  for (var i = 0; i < variants.length; i++) {
    var variant = variants[i];
    if (variant.variantName === overrideVariantName) {
      return variant;
    }
  }

  // if we got here and didn't choose a variant, that means the overrideVariantName was not a valid variant name. Log the error and return null
  telemetry.trackTrace({
    message: 'FormOptimizationExperiments.getOverrideVariant: Could not choose override variant\n\tOverrideVariantName querystring value: ' + overrideVariantName + '\n\tVariants: {' + variants.join(', ') + '}',
  });
  return null;
}


function treatFormDef(formDef, query_string, formModel) {
  try {
    // check if this form + form load is eligible to be an experiment sample at all
    if (!isGenericExperimentEligible(formDef, query_string)) {
      return;
    }

    // load the specific experiment by its name
    var experiment = FormOptimizationExperimentsLogic.find(function (e) { return e.experimentName === formDef.experiment.experimentName });
    if (!experiment) {
      telemetry.trackException({
        message: 'Error parsing experiment info. ActionTag does not recognize experimentName ' + formDef.experiment.experimentName
          + ' for experimentId ' + formDef.experiment.experimentId
      });
      return;
    }

    // check if this form + form load is eligible to be a sample for this particular experiment
    if (!experiment.eligibilityCheck(formDef, query_string)) {
      return;
    }

    var shouldOverrideVariant = !!query_string.overridevariantname;

    // allow QA to override the variant choice process to manually test variant functionality
    // if query params includes `overrideVariantName` param, otherwise choose variant through weighted random choice
    var chosenVariant = shouldOverrideVariant ?
      getOverrideVariant(formDef.experiment.variants, query_string.overridevariantname) :
      weightedVariantChoice(formDef.experiment.variants, Math.random());

    // if there was a problem choosing a variant, do nothing and return
    if (!chosenVariant) {
      // we don't actually have an exception here, either this is a malformed experiment or the overridevariantname query string was bad
      telemetry.trackTrace({
        message: 'Error choosing experiment variant\n\tExperiment ID: ' + formDef.experiment.experimentId,
      });
      return;
    }

    telemetry.trackEvent({
      name: 'Experiment Variant Chosen',
      properties: {
        FormId: formDef.formId,
        ChosenVariantName: chosenVariant.name,
        FormVersion: formDef.formVersion,
        VariantChoiceOverride: shouldOverrideVariant
      }
    });

    // alter the form definition in accordance with the selected variant
    var variant = experiment.variants.find(function (v) { return v.variantName === chosenVariant.variantName });
    if (!variant) {
      telemetry.trackException({
        message: 'Error parsing experiment info. ActionTag does not recognize variantName ' + chosenVariant.variantName +
          'for experimentId ' + formDef.experiment.experimetntId + ' and variantId  ' + chosenVariant.variantId
      });
      return;
    }

    variant.treatmentFunction(formDef, formModel);

    // after we've successfully confirmed eligibility and treated the form def,
    // we are sure we want to record an experiment sample. Add the chosen variant to the
    // form def where it will be passed to OA on tracked hit and submission
    formDef.experiment.chosenVariantId = chosenVariant.variantId;
  }
  catch (ex) {
    telemetry.trackException({ exception: ex, properties: { ErrorType: 'Form Optimization Experiment Error' } });
  }
}

export default {
  treatFormDef: treatFormDef
};
