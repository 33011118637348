import Backbone from 'lib/backbone';
import templates from 'generated/compiled_handlebars_templates';
import fastaction_config from 'app/fastaction_config';
import user from 'app/models/FastActionUser';
import FastActionView from 'app/views/FastActionView';
import auth_popups from 'app/auth_popups';
import currency from 'app/views/helpers/currency';
import cookieConsentConfig from 'app/CookieConsentConfiguration';
import ActionTagConfig from 'config/actionTagConfig';

var $ = Backbone.$,
  _ = Backbone._,
  user_view = null,
  fastActionEnabled = null,
  fastActionConnectionFailure = false,
  updateMyProfile = false,
  nologin = false,
  loggedIn = false,
  kiosk = false,
  fastActionHtml = '<i>Fast</i><b>Action</b>',
  FASTACTION_PROFILE_BLOCK_HTML = '<div class="fastaction-block"></div>',
  autoProcessPostData = null;

function isNotEnabled() {
  return !ActionTagConfig.FASTACTION_ENABLED
    || !fastActionEnabled
    || (!loggedIn && !!fastActionConnectionFailure);
}

function hasPayment(def) {
  return _(def).pluck('name').contains('PaymentInformation');
}

// cc block should be its own view
function swap_credit_card_block(fastActionUser) {
  if (isNotEnabled()) {
    return;
  }
  // This is kind of ugly, but it can fortunately go away when the fastAction plugin's
  // functionality is wholly subsumbed into NVTag proper.
  _.each(window.nvtag.tags, function (tag) {
    var formview = tag.formviews.current;
    formview.setFastActionState(fastActionUser);
  });
}

function build_confirm_account_modal(currentFormView, fastActionUser, onConfirm) {
  let savedCard = fastActionUser.getCardForPool(currentFormView.getAcceptedTokenPool());
  let fastActionProfileData = fastActionUser.getProfileData();

  // Build contact info
  var userName = '';
  if (fastActionProfileData.FirstName) {
    userName = fastActionProfileData.FirstName;
    if (fastActionProfileData.LastName) {
      userName += ' ' + fastActionProfileData.LastName[0];
      if (fastActionProfileData.LastName.length > 1) {
        userName += '.';
      }
    }
  }

  var userLocationDetails = [];
  if (fastActionProfileData.City) {
    userLocationDetails.push(fastActionProfileData.City);
  }

  if (fastActionProfileData.StateProvince) {
    userLocationDetails.push(fastActionProfileData.StateProvince);
  }

  var userLocation = '';
  if (userLocationDetails.length) {
    if (userName) {
      userLocation += ' \u2022 ';
    }
    userLocation += userLocationDetails.join(', ');
  }

  var userCardDigits = '';
  if (savedCard.ccType) {
    userCardDigits += ' \u2022 ';
  }
  userCardDigits += '****' + savedCard.ccLastFour;

  var resources = currentFormView.options.form_definition.resources;

  var currentFormVals = currentFormView.val();
  var faUrl = fastaction_config.urls.base();

  currentFormView.$el.find('#fastaction-confirm-account').remove();
  currentFormView.$el.append(templates.fa_confirm_account_modal({
    text: {
      UserName: userName,
      UserLocation: userLocation,
      UserCardType: savedCard.ccType,
      UserCardDigits: userCardDigits,
      YesCompleteDonation: resources.fill(resources.PrimaryResources.YesCompleteDonation, currency.format(currentFormVals.Amount))
    },
    ShowContactDetails: userName || userLocation,
    ShowCardDetails: savedCard.ccType || savedCard.ccLastFour,
    fastaction_url: faUrl,
    resources: resources
  }));
  $('a.fastaction-confirm-account-button').unbind()
    .click(onConfirm);
}

// As far as I know clients don't use this and it's only here for ease of debugging
window.nvtag.user = user;

var FastAction = (function () {
  // To handle auto-submit logic, we need to know when the form has rendered, when the fastaction databag is available,
  //  and when the fastaction profile is ready and logged in
  var deferredRender = $.Deferred();
  var deferredFastActionFill = $.Deferred();
  var deferredFastActionProfileReady = $.Deferred();
  var deferredFormdefSync = $.Deferred();
  var deferredFastActionCookies = $.Deferred();

  // FastAction will get attached to the first form on the page to trigger a sync event
  // Multiple forms on the same page are not handled gracefully, especially with autoprocessing links
  window.nvtag.once('sync:formdef', deferredFormdefSync.resolve);

  cookieConsentConfig.on('change:functionalAccepted', deferredFastActionCookies.resolve);

  if (cookieConsentConfig.areFunctionalCookiesAccepted()) {
    deferredFastActionCookies.resolve();
  }

  // Fetch fastaction information once the form definition has loaded and fastAction cookies
  // are either accepted or we are not in Cookie Consent Mode
  $.when(deferredFormdefSync, deferredFastActionCookies).done(function (formView) {
    var formModel = formView.model;
    if (formModel.get('fastAction')) {
      var metadata = formModel.get('metadata');
      var acceptedTokenPool = formView.getAcceptedTokenPool();
      var autoFastAction = formModel.get('autoFastAction');
      var qs = formView.options.query_string;

      // We'll attempt to autoprocess the form if a token is present, and its enabled on the form definition
      var attemptAutoProcess = autoFastAction && formView.isFirstForm() && qs.tknfa && formview.contactMode !== 'org';

      // If the form def has autoprocessing enabled, set it up to autoprocess
      // once all the necessary information has been loaded.
      if (attemptAutoProcess) {
        $.when(deferredRender, deferredFastActionProfileReady, deferredFastActionFill)
          .done(function () {
            var savedCard = user.getCardForPool(acceptedTokenPool);
            if (savedCard) {
              processAutoSubmit(formView, qs.tknfa, qs);
            }
          });
      }

      // When the form definition tells us to, log in to the specified fastaction profile
      // OA includes `metadata.fastActionLoginId` in the formDef if the url includes a contact
      // smartlink and that smartlink wins in OA's formDef fill precedence logic
      if (metadata && metadata.fastActionLoginId) {
        var fastActionFormDefId = metadata.fastActionLoginId;

        var fastActionLogin = function fastactionLogin() {
          user.loginAndFetchProfile(fastActionFormDefId)
            .then(function () {
              deferredFastActionProfileReady.resolve();
            });
        };

        // When the supporter says this isn't them, we need to reload the page in a way that
        // (1) busts the cache in OA
        // (2) doesn't try to autoprocess
        // The simplest approach here is just to replace the 'tknfa' parameter with a 'notme' flag
        // Since IE11 doesn't have any URL helpers, use regex to do the replacment
        var fastActionNotMe = function fastActionNotMe() {
          deferredFastActionProfileReady.reject();
          // Clear the FastActionUser model but do not logout: we want to keep FastActionSessionId in localStorage
          // or OA 1st party cookie. if the supporter was already logged into a different FastAction profile, they
          // will be logged into that profile if they hit "not me" on the modal
          user.clear({ clearPersistedSessionId: false });
          var re = /tknfa=[^&#]*/;
          var url = window.location.href;
          var newUrl = url.replace(re, 'notme=1');
          window.location = newUrl;
        };

        if (attemptAutoProcess) {
          // Wait for the FastAction profile info to load. if it fails to load, do nothing
          // If the FastAction profile info has a saved card, show the confirmation modal
          // If confirmed, login as that fastaction profile; otherwise, clear out info as necessary
          // If the FastAction profile has no saved card, just login as that profile
          var deferredFetchProfile = user.fetchProfileWithoutLoggingIn(fastActionFormDefId);

          $.when(deferredFastActionFill, deferredFetchProfile)
            .then(function () {
              // If everything is ready, check we have a saved card and then confirm its usage, otherwise proceed with a normal login
              var savedCard = user.getCardForPool(acceptedTokenPool);

              if (savedCard) {
                _.defer(function () {
                  confirmFastActionUser(formView, user)
                    .then(fastActionLogin, fastActionNotMe);
                });
              } else {
                fastActionLogin();
              }
            });
        } else {
          fastActionLogin();
        }
      } else {
        user.fetch()
          .then(function () {
            deferredFastActionProfileReady.resolve();
          });
      }
    }
  });

  // Submit the form, and make the autoprocessing data available to the alterPost method
  function processAutoSubmit(formView, token, qs) {
    formView.trigger('preAutoProcess');
    autoProcessPostData = {
      token: token,
      qs: qs
    };
    formView.focusOnNextRequiredValueOrSubmit({
      shouldRenderFeedback: true,
      submissionType: 'fastActionAutoSubmit'
    });
  }

  // Open the account confirmation modal, and return a promise that represents the users confirmation
  // Rejects the promise if the user does not confirm and closes the modal
  function confirmFastActionUser(formView, fastActionUser) {
    var deferredConfirmation = $.Deferred();
    var hasConfirmed = false;

    function onConfirm() {
      hasConfirmed = true;
      deferredConfirmation.resolve();
    }

    build_confirm_account_modal(formView, fastActionUser, onConfirm);
    window.location.hash = '#fastaction-confirm-account';

    // The next time the modal hide event is triggered, if the user didn't confirm, they must have rejected
    // We can't listen for a specific rejection because the modal library doesn't let you run a callback when the user hits 'ESC'
    $(document).one('cssmodal:hide', function () {
      if (!hasConfirmed) {
        deferredConfirmation.reject();
      }
    });

    return deferredConfirmation.promise();
  }

  user.on('syncError', function () {
    fastActionConnectionFailure = true;
  });

  user.on('change:id', function (user, id) {
    if (id) {
      $('.at input[name="updateMyProfile"]').prop('checked', true);
      loggedIn = true;
      fastActionConnectionFailure = false;
    } else {
      loggedIn = false;

      _.each(window.nvtag.tags, function (tag) {
        var formview = tag.formviews.current;
        var resources = formview.options.form_definition.resources;
        formview.clear().fill();
        if (nologin) {
          formview.$('.updateMyProfileSection').show().find('.text').html(
            resources.fill(resources.PrimaryResources.RememberMeFastActionNextTime, fastActionHtml));
        } else {
          formview.$('.updateMyProfileSection').hide();
        }
        if (formview.multistep && formview.getStepIndex()) {
          formview.setStep(1);
        }
      });
    }
  });

  function FastAction() {
  }

  FastAction.prototype.alterFormDefinition = function (args) {
    nologin = !!args.form_definition.fastActionNologin;
    kiosk = !!args.query_string.kiosk;
    fastActionEnabled = !kiosk && !!args.form_definition.fastAction;
    var showUpdateMyProfile = !args.form_definition.hideUpdateMyFastActionProfile;

    // Only show the FastAction block if this form is FastAction enabled
    if (isNotEnabled()) {
      return args;
    }

    var fastActionBlock = {
      type: 'markup',
      // This should probably be updated to use the View's markup rather
      // than generating a new one. This breaks event binding.
      markup: FASTACTION_PROFILE_BLOCK_HTML,
      name: 'FastAction'
    };
    // Unshift to push this in as the first item
    args.form_definition.form_elements.unshift(fastActionBlock);

    var resources = args.form_definition.resources;

    if (showUpdateMyProfile) {
      var updateMyProfile = {
        type: 'markup',
        // This should probably be updated to use the View's markup rather
        // than generating a new one. This breaks event binding.
        /*jshint -W101 */
        markup: '<div class="updateMyProfileSection" style="display: none"><label style="display:inline;"><input type="checkbox" name="updateMyProfile" checked="checked"><span><span class="text">' + resources.fill(resources.PrimaryResources.UpdateMyFastActionProfile, fastActionHtml) + '</span></span></label></div>',
        /*jshint +W101 */
        name: 'UpdateMyProfile',
        contactMode: 'person'
      };
      var fieldsets = _(args.form_definition.form_elements).filter(function (def) {
        return def.hasOwnProperty('children');
      });
      var fieldset = null;
      if (args.form_definition.type === 'EventForm') {
        fieldset = fieldsets.first();
      } else {
        fieldset = fieldsets.find({ name: 'PaymentInformation' }) || fieldsets.first();
      }
      if (fieldset) {
        fieldset.children.push(updateMyProfile);
      }
    }

    // See if user is logged in
    return args;
  };

  FastAction.prototype.postFill = function (args) {
    if (isNotEnabled()) {
      return args;
    }
    var formView = args.view,
      source = args.fill_source,
      dict = args.fill_dict;

    // If we have a populated fastaction fill dictionary, indicate that fastaction fill has ocurred
    if (source === 'FastAction' && dict) {
      formView.clearFeedback();
      deferredFastActionFill.resolve();
    }

    this.adjustCheckbox(formView);

    return args;
  };

  FastAction.prototype.adjustCheckbox = function (view) {
    var resources;
    if (view.options.form_definition &&
      view.options.form_definition.resources) {
      resources = view.options.form_definition.resources;
    } else if (view.options.formview &&
      view.options.formview.options.form_definition &&
      view.options.formview.options.form_definition.resources) {
      resources = view.options.formview.options.form_definition.resources;
    }
    if (!resources) {
      return;
    }

    var loginLabel = resources.fill(resources.PrimaryResources.UpdateMyFastActionProfile, fastActionHtml);
    if (nologin) {
      var label = view.$('.updateMyProfileSection').show().find('.text');
      if (loggedIn) {
        label.html(loginLabel);
      } else {
        label.html(resources.fill(resources.PrimaryResources.RememberMeFastActionNextTime, fastActionHtml));
      }
    } else {
      if (loggedIn) {
        view.$('.updateMyProfileSection').show().find('.text').html(loginLabel);
      } else {
        view.$('.updateMyProfileSection').hide();
      }
    }

    if (!cookieConsentConfig.areFunctionalCookiesAccepted()) {
      view.$('.updateMyProfileSection').hide();
    }
  };

  FastAction.prototype.postRender = function (args) {
    if (isNotEnabled()) {
      return args;
    }

    $(args.options.el).find('input[name="updateMyProfile"]').on('change', function (e) {
      e.stopImmediatePropagation();
    });

    // TODO: Remove this args hack.  It should always exist; this is just a temporary work-around for a Drupal bug.
    if (args && args.thank) {
      $(args.options.el).prepend(FASTACTION_PROFILE_BLOCK_HTML);
    }
    // initialize once
    if (!user_view) {
      user_view = new FastActionView({
        el: '.fastaction-block',
        model: user,
        nologin: nologin,
        resources: args.form_definition.resources || {}
      });
    }

    if (user.get('savedCards')) {
      swap_credit_card_block(user);
    }

    user.on('change:savedCards', function () {
      swap_credit_card_block(user);
    });

    deferredRender.resolve();
    return args;
  };

  FastAction.prototype.segue = function (args) {
    // Are any of the forms in the current chain fastAction forms?
    var fastAction = !kiosk && _.find(args.formviews, function (form) {
      return form.model.has('fastAction') && form.model.get('fastAction');
    });

    var form = args.formviews.current;
    var resources = form.options.form_definition.resources;

    if (fastAction) {
      var text = {
        AutofillQuickly: resources.fill(resources.PrimaryResources.AutofillQuickly, fastActionHtml),
        SingleClick: resources.fill(resources.PrimaryResources.SingleClick, '<br>')
      };

      var nologinSetting = args.thank && nologin && updateMyProfile;

      var faUrl = fastaction_config.urls.base();
      args.calling_tag.$el.find('.fastaction-modal').remove();

      args.calling_tag.$el.append(templates.fa_modal({
        isSignup: true,
        nologin: nologinSetting,
        faBaseUrl: faUrl,
        authRoute: 'sign_up',
        prefix: 'signup',
        other: 'login',
        text: _.extend({
          StoredOnThisDevice: resources.PrimaryResources.StoredOnThisDevice,
          SignUpForAnAccount: resources.fill(resources.PrimaryResources.SignupForAnAccount, fastActionHtml),
          ActionText: nologinSetting ? resources.PrimaryResources.SignUpNow : resources.PrimaryResources.SignUpWithYourEmail,
          OrBlankUsing: resources.fill(resources.PrimaryResources.OrBlankUsing,
            resources.PrimaryResources.SignUp.toLowerCase()),
          OtherOptionText: resources.fill(resources.PrimaryResources.AlreadyHaveFastAction, fastActionHtml),
          ByClickingAgree: resources.fill(resources.PrimaryResources.ByClickingAgree,
            resources.PrimaryResources.LogIn, fastActionHtml)
        }, text),
        resources: resources
      })).append(templates.fa_modal({
        faBaseUrl: faUrl,
        authRoute: 'log_in',
        prefix: 'login',
        other: 'signup',
        text: _.extend({
          ActionText: resources.PrimaryResources.LogInWithYourEmail,
          OrBlankUsing: resources.fill(resources.PrimaryResources.OrBlankUsing,
            resources.PrimaryResources.LogIn.toLowerCase()),
          OtherOptionText: resources.fill(resources.PrimaryResources.DontHaveFastAction, fastActionHtml),
          ByClickingAgree: resources.fill(resources.PrimaryResources.ByClickingAgree,
            resources.PrimaryResources.SignUp, fastActionHtml),
        }, text),
        resources: resources
      })).append(templates.fa_modal({
        isSignup: true,
        nologin: nologinSetting,
        faBaseUrl: faUrl,
        authRoute: 'auth/actionid_signup',
        prefix: 'signup-hustle',
        other: 'login-hustle',
        text: _.extend({
          StoredOnThisDevice: resources.PrimaryResources.StoredOnThisDevice,
          SignUpForAnAccount: resources.fill(resources.PrimaryResources.SignupForAnAccount, fastActionHtml),
          ActionText: nologinSetting ? resources.PrimaryResources.SignUpNow : resources.PrimaryResources.SignUpWithYourEmail,
          OrBlankUsing: resources.fill(resources.PrimaryResources.OrBlankUsing,
            resources.PrimaryResources.SignUp.toLowerCase()),
          OtherOptionText: resources.fill(resources.PrimaryResources.AlreadyHaveFastAction, fastActionHtml),
          ByClickingAgree: resources.fill(resources.PrimaryResources.ByClickingAgree,
            resources.PrimaryResources.LogIn, fastActionHtml)
        }, text),
        resources: resources
      })).append(templates.fa_modal({
        faBaseUrl: faUrl,
        authRoute: 'auth/actionid',
        prefix: 'login-hustle',
        other: 'signup-hustle',
        text: _.extend({
          ActionText: resources.PrimaryResources.LogInWithYourEmail,
          OrBlankUsing: resources.fill(resources.PrimaryResources.OrBlankUsing,
            resources.PrimaryResources.LogIn.toLowerCase()),
          OtherOptionText: resources.fill(resources.PrimaryResources.DontHaveFastAction, fastActionHtml),
          ByClickingAgree: resources.fill(resources.PrimaryResources.ByClickingAgree,
            resources.PrimaryResources.SignUp, fastActionHtml),
        }, text),
        resources: resources
      }));

      // EVA-250: Rob, Justin, and Liam aren't sure what this does but we're keeping it in just in case
      if (args.thank && nologin && !loggedIn && cookieConsentConfig.areFunctionalCookiesAccepted()) {
        user.fetch();
      }
    }

    if (cookieConsentConfig.areFunctionalCookiesAccepted()) {
      auth_popups(user.get('fastActionSessionId'));
    }

    var hasAsked = user_view && user_view.asked;
    var shouldAsk = args.thank || !(form.isFirstForm() || hasPayment(form.model.get('form_elements')));

    if (fastAction && cookieConsentConfig.areFunctionalCookiesAccepted() && updateMyProfile && (!loggedIn || !user.get('authed')) && !hasAsked && shouldAsk) {
      // Fire up the hustle modal
      window.location.hash = 'fastaction-signup-hustle';
      user_view.asked = true;
      // EVA-250: Rob, Justin, and Liam aren't sure what this does but we're keeping it in just in case
      if (nologin && updateMyProfile) {
        user.fetch();
      }
    }
  };

  FastAction.prototype.onSubmit = function (args) {
    var checkUpdateMyProfileInputs = args.currentTarget
      ? $(args.currentTarget).find('input[name="updateMyProfile"]:checked')
      : args.formview.$('input[name="updateMyProfile"]:checked');

    var isOrgMode = args.formview && args.formview.contactMode === 'org';
    updateMyProfile = !isOrgMode && checkUpdateMyProfileInputs.length > 0;
    return args;
  };

  FastAction.prototype.alterPost = function (args) {
    // Detect if we're past step one of a multistep advocacy form,
    // and treat it like a form without fastaction enabled - don't post on form submit.
    // You should also not alter the post if the form is in consent mode and the supporter has not consented.
    if (isNotEnabled() || args.form_definition.hideUpdateMyFastActionProfile ||
      !cookieConsentConfig.areFunctionalCookiesAccepted()) {
      return args;
    }

    if (updateMyProfile) {
      if (nologin && !loggedIn) {
        args.data.autoCreateAccount = 'true';
      } else if (loggedIn) {
        // Flag for EVA-250 + EVA-2234 backwards compatibility. Somewhat redundant with ignorePaymentInfo
        // so we'll want to clean this up at some point
        args.data.updateProfile = 'true';
      }
    } else {
      args.data.ignorePaymentInfo = 'true';
    }

    if (user.get('id')) {
      args.data.fastActionId = user.get('id');
    }

    if (user.get('fastActionSessionId')) {
      args.data.fastActionSessionId = user.get('fastActionSessionId');
    }

    args.data.endpoint = args.endpoint;
    args.url = fastaction_config.urls.forms();

    // If autoprocess data exists, add it to the form submission
    if (autoProcessPostData) {
      args.data.autoProcessToken = autoProcessPostData.token;
      args.data.qs = JSON.stringify(autoProcessPostData.qs);
      autoProcessPostData = null;
    }

    return args;
  };

  FastAction.prototype.user = function () {
    return user;
  };

  return FastAction;
})();

export default new FastAction();
