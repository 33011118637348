import Backbone from 'lib/backbone';
import deviceInfo from 'app/deviceInfo';
import FastActionUser from 'app/models/FastActionUser';

// Ensure this is on or IE will fail.
Backbone.emulateHTTP = !!deviceInfo.ie;
var $ = Backbone.$,
  _ = Backbone._;
export default Backbone.Model.extend({
  __name__: 'NVForm',
  urlRoot: function () {
    var options = this.get('options');
    var endpoint = options.formdefEndpoint || options.endpoint;
    // Replace actions.ngpvan.com with secure.ngpvan.com (or equivalent)
    return endpoint.replace(/(actions)(\.(ngpvan|everyaction)\.com)/, 'secure$2');
  },
  initialize: function (options) { // jshint ignore:line
    this.set('obfuscatedId', this.id, { silent: true });
    this.s3Failed = false;
    if (this.get('options').formUrl) {
      this.url = this.get('options').formUrl;

      // if the URL ends in a question mark or ampersand, remove it
      // it causes issues with the constructed URL for ASP.NET handling
      var endsInQuestionMarkOrAmp = this.url.match(/(.+)[?&]+$/);
      if (endsInQuestionMarkOrAmp && endsInQuestionMarkOrAmp.length) {
        this.url = endsInQuestionMarkOrAmp[1];
      }
    }
  },
  fetch: function (options) {
    options = options || {};
    let qsDict = {};
    if (this.get('options').formUrl) {
      options.xhrFields = { withCredentials: true };
    }
    if (this.get('options').query_string_case_preserved) {
      qsDict = this.get('options').query_string_case_preserved;
    }
    if (FastActionUser.getPersistedSessionId()) {
      qsDict.fastActionSessionId = FastActionUser.getPersistedSessionId();
    }
    if (!_.isEmpty(qsDict)) {
      options.data = $.param(qsDict);
    }
    var deferred = $.Deferred(), refetch = _.bind(function () {
      this.s3Failed = true;
      this.urlRoot = this.get('options').endpoint;
      Backbone.Model.prototype.fetch.call(this, options).then(deferred.resolve, deferred.reject);
    }, this);
    Backbone.Model.prototype.fetch.call(this, options)
      .then(deferred.resolve, (this.s3Failed ? deferred.reject : refetch));
    return deferred.promise();
  }
});
