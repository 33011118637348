import _ from 'lib/lodash.custom';
import gaHelper from 'app/googleAnalyticsHelper';

export default function trackTransaction(formview, response) {
  var tenant = formview.model.get('designation');
  var meta = formview.model.get('metadata');
  var formid = formview.model.get('formId');
  var transType = formview.model.get('type');
  var transId = response.confirmationID;
  var postVals = formview.val();
  var amount = postVals.Amount;
  var coverCostsAmount = postVals.CoverCostsAmount || 0;
  var transAmount = response.upsellAmount ? parseFloat(response.upsellAmount) : parseFloat(amount) + coverCostsAmount;
  var transItems = [];

  if (tenant && tenant.name && transId && transAmount) {
    var transVariant = response.upsellFrequency ? response.upsellFrequency : getVariant();
    tenant.sku = tenant.designationId + '_' + formid;
    if (transType === 'EventForm') {
      var additional = postVals.AdditionalContributionValue;
      var tickets = _.reduce(meta.tickets, function (memo, ticket) {
        memo[ticket.id] = {
          price: ticket.price,
          title: ticket.title
        };
        return memo;
      }, {});
      transItems = _.chain(postVals)
        .transform(function (ticket, val, key) {
          var id = key.match(/Ticket_(\d+)/);
          if (id) {
            ticket[id[1]] = val;
          }
        })
        .map(function (count, id) {
          var ticket = tickets[id];
          return {
            id: tenant.sku + '_' + id,
            price: ticket.price.toFixed(2),
            name: ticket.title,
            type: 'Ticket',
            quantity: count
          };
        })
        .value();
      if (additional && additional !== '0.00') {
        transItems.push({
          id: tenant.sku + '_A',
          price: additional,
          name: 'Additional',
          type: 'Donation',
          quantity: 1
        });
      }
    }

    var trans = {
      // We must do this because Oberon responds with
      // a bogus contributionAmount. If you contribute
      // $12.34, Oberon response will say the amount
      // was '12.3400' for EventForms.
      total: transAmount.toFixed(2),
      id: transId,
      tenant: tenant,
      type: transType,
      city: postVals.City,
      state: postVals.StateProvince,
      country: postVals.Country,
      items: transItems,
      method: postVals.PaymentMethod,
      currency: postVals.ProcessingCurrency,
      variant: transVariant
    };

    if (!_.isObject(trans) || !_.isObject(trans.tenant)) {
      return false;
    }
    var gaTrans = ['nv._addTrans', trans.id, trans.tenant.name, '0', '0', '0'];
    var gaItem = ['nv._addItem', trans.id, trans.tenant.sku, 'Contribution', '', '0', '1'];

    var total;
    if (parseFloat(trans.total) > 0) {
      total = _.isString(trans.total) ? trans.total : '' + trans.total;
    } else {
      return false;
    }

    gaTrans[3] = gaItem[5] = total;

    if (trans.type && _.isString(trans.type)) {
      gaItem[4] = trans.type;
    }

    if (trans.city && _.isString(trans.city)) {
      gaTrans[6] = trans.city;
      if (trans.state && _.isString(trans.state)) {
        gaTrans[7] = trans.state;
        if (trans.country && _.isString(trans.country)) {
          gaTrans[8] = trans.country;
        }
      }
    }

    var gtm = {
      event: 'transaction',
      ecommerce: {
        currencyCode: trans.currency,
        purchase: {
          actionField: {
            id: trans.id,
            affiliation: 'NGPVAN',
            revenue: total,
            option: trans.method
          },
          products: [{
            id: trans.tenant.sku,
            price: total,
            brand: trans.tenant.name,
            category: trans.type,
            quantity: 1,
            variant: trans.variant
          }]
        }
      }
    };

    var ga4Purchase = {
      ecommerce: {
        transaction_id: trans.id,
        value: total,
        currency: trans.currency,
        items: [{
          item_id: trans.tenant.sku,
          item_name: 'Contribution',
          price: total,
          item_brand: trans.tenant.name,
          item_category: trans.type,
          quantity: 1,
          affiliation: 'NGPVAN',
          item_variant: trans.variant
        }]
      }
    };

    _gaq.push(gaTrans);
    if (trans.items && trans.items.length) {
      _gaq.push.apply(_gaq, _.map(trans.items, function (item) {
        return ['nv._addItem',
          trans.id,
          item.id,
          item.name,
          item.type,
          item.price,
          item.quantity + ''
        ];
      }));
      gtm.ecommerce.purchase.products = _.map(trans.items, function (item) {
        return {
          id: item.id,
          name: item.name,
          price: item.price,
          brand: tenant.name,
          category: item.type,
          quantity: item.quantity
        };
      });
      ga4Purchase.ecommerce.items = _.map(trans.items, function (item) {
        return {
          item_id: item.id,
          item_name: item.name,
          price: item.price,
          item_brand: tenant.name,
          item_category: item.type,
          quantity: item.quantity,
          affiliation: 'NGPVAN',
          item_variant: transVariant
        };
      });
    } else {
      _gaq.push(gaItem);
    }

    _gaq.push(['nv._trackTrans']);

    if (gaHelper.shouldUseGtag()) {
      // GA4 via gtag.js so only push the ecommerce event using the GA4 schema
      window.gtag('event', 'purchase', ga4Purchase);
    } else {
      ga4Purchase.event = 'purchase';
      window.dataLayer.push(ga4Purchase);

      // The legacy 'transaction' event should only be of interest in GTM use-cases
      window.dataLayer.push(gtm);
    }

    return gtm.ecommerce.purchase;
  }
}

function getVariant() {
  // check special cases
  // return 'One Time' for unchecked recurring checkbox and non-recurring contribution form submissions
  var isRecurringElement = document.getElementsByName('IsRecurring')[0];
  var recurringCheckboxUnchecked = isRecurringElement && !isRecurringElement.checked;
  var frequencyElements = document.getElementsByName('SelectedFrequency');
  var missingFrequencyElement = !frequencyElements || frequencyElements.length <= 0;
  if (missingFrequencyElement || recurringCheckboxUnchecked) {
    return 'One Time';
  }

  // get frequency + radio value if applicable
  var frequency = frequencyElements[0];
  var isRadio = frequency && frequency.type === 'radio';
  if (isRadio) {
    for (var count = 0; count < frequencyElements.length; count++) {
      var element = frequencyElements[count];
      if (element.checked) {
        frequency = element;
        break;
      }
    }
  }

  // return frequency variant
  switch (frequency.value) {
    case '0':
      return 'One Time';
    case '1':
      return 'Weekly';
    case '2':
      return 'Every Two Weeks';
    case '3':
      return 'Every Four Weeks';
    case '4':
      return 'Monthly';
    case '5':
      return 'Quarterly';
    case '6':
      return 'Yearly';
    case '7':
      return 'Twice a Year';
    default:
      var singleOptionFrequency = frequency.innerHTML.trim();
      var transVariant = (singleOptionFrequency && singleOptionFrequency.length <= 100) ? singleOptionFrequency : transVariant;
      return transVariant;
  }
}
