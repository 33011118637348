import parseUrl from 'lib/parseUrl';

// Merges url in the currently pointed to by the browser with the 'newSearchParams' and
// returns a new url with the query strings merged.  Any hash is also preseved.
// Query strings that are not part of AtQueryStrings are treated as 'client query strings'
// and are not affected.  AtQueryStrings query strings present in the location URL will
// be replaced by the AT query strings in the newSearchParams
function mergeLocationUrlParameters(newSearchParams) {
  var destUrl = parseUrl(window.location.pathname + '?' + window.location.search);
  var hash = window.location.hash;
  return mergeUrlParameters(destUrl, newSearchParams, hash);
}

function mergeUrlParameters(destUrl, newSearchParams, hash, queryStringDict) {
  var key;
  var destUrlParts = destUrl.split('?');
  var destBasePart = null, destSearchPart = null;
  if (destUrlParts.length > 0) {
    destBasePart = destUrlParts[0];
  }
  if (destUrlParts.length > 1) {
    destSearchPart = destUrlParts[1];
  }


  var destDict = {};
  // We now go through and replace the query strings relevant to
  // social network tracking and remove any strings that pertain
  // to other form fields
  if (destSearchPart) {
    destSearchPart.split('&').forEach(function (param) {
      var splitParam = param.split('=');
      // Only propagate the keys from src url if they are not part of
      // known Forms/AT query string set
      if (splitParam[0].length > 0) {
        if (queryStringDict && !(queryStringDict.hasOwnProperty(splitParam[0]))) {
          if (splitParam.length > 1) {
            destDict[splitParam[0]] = splitParam[1];
          } else {
            destDict[splitParam[0]] = null;
          }
        }
      }
    });
  }

  var srcDict = {};
  // This sets up the dictionary with query strings we want to merge into existing url
  if (newSearchParams) {
    newSearchParams.substr(1).split('&').forEach(function (param) {
      var splitParam = param.split('=');
      if (splitParam.length > 1) {
        srcDict[splitParam[0]] = splitParam[1];
      } else {
        srcDict[splitParam[0]] = null;
      }
    });
  }

  // Now merge the social sharing parameters in the filtered src dict
  // ie.  copy the values in srcDict to their corresponding entries
  //      in destDict
  for (key in srcDict) {
    if (srcDict.hasOwnProperty(key)) {
      destDict[key] = srcDict[key];
    }
  }
  // Rebuild the url.  Remove any trailing slash
  if (destBasePart.endsWith('/')) {
    destBasePart = destBasePart.substring(0, destBasePart.length - 1);
  }
  // add start of search string
  var mergedUrl = destBasePart + '?';
  var init = true;
  for (key in destDict) {
    if (destDict.hasOwnProperty(key)) {
      if (init) {
        // don't prepend anything if it's the first parameter
        init = false;
      } else {
        mergedUrl += '&';
      }
      mergedUrl += key;
      if (destDict[key] !== null) {
        mergedUrl += '=' + destDict[key];
      }
    }
  }

  // Add back any hash that was stripped off prior to merging
  if (hash) {
    mergedUrl += hash;
  }
  return mergedUrl;
}

export default {
  mergeLocationUrlParameters: mergeLocationUrlParameters,
  mergeUrlParameters: mergeUrlParameters
};
