// NVTagCallbacks.js
//
// This is a pretty generic and elegant way of adding callbacks anywhere within
// our codebase.
//
// Given this, adding a callback is as simple as adding the following snippet anywhere
// in our code:
//
// invokeCallbacks('myCallbackName', { any: 1, local: 2, variables: 3 });
//
// where myCallbackName is the name of your callback (preRender, postRender, etc.) and
// "{ any: 1, local: 2, variables: 3 }" are any local variables you want sent to this callback. This
// allows the page hosting this NVTag (such as a CMS like Drupal) to have a TON of power
// over the internals of this tag without dirtying it unnecessarily.

import _ from 'lib/lodash.custom';

export default function invokeCallbacks(name, args) {
  // Call nvtag_callbacks
  if ('nvtag_callbacks' in window && name in window.nvtag_callbacks && _.isArray(window.nvtag_callbacks[name])) {
    var callbackResult = null;
    args = _.reduce(window.nvtag_callbacks[name], function (memo, cb) {
      callbackResult = _.isFunction(cb) && cb(memo);
      return _.isObject(callbackResult) ? callbackResult : memo;
    }, args);
  }

  // Iterate over nvtag_plugins, call the callbacks
  if ('nvtag_plugins' in window && _.isArray(window.nvtag_plugins)) {
    var pluginResult = null;
    args = _.reduce(window.nvtag_plugins, function (memo, plugin) {
      pluginResult = _.isObject(plugin) && _.isFunction(plugin[name]) && plugin[name](memo);
      return _.isObject(pluginResult) ? pluginResult : memo;
    }, args);
  }

  // Clone at the end to prevent side effects such as setTimeout in callbacks/plugins.
  return args;
}
