import $ from 'lib/jquery.ui';
import _ from 'lib/lodash.custom';

export default _.memoize(function loadScript(url) {
  var dfd = $.Deferred();

  $.ajax({
    method: 'GET',
    url: url
  }).done(function (data) {
    return dfd.resolve(data);
  });

  // in case the file never loads
  setTimeout(dfd.resolve, 5000);
  return dfd.promise();
});
