import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import moment from 'lib/moment';
import _ from 'lib/lodash.custom';

export default FieldView.extend({
  __name__: 'TimeView',
  tagName: 'label',
  type: 'time',
  events: {
    'change [name$="StartTime"]': 'updateTime',
    'change': 'renderFeedback'
  },
  className: function () {
    return 'at-text ' + this.options.definition.name;
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
  },
  context: function () {
    var context = {
      name: this.name,
      title: this.title,
      required: this.def.required && 'required',
      value: this.def.default_value || '',
      type: 'text',
      placeholder: 'hh:mm AM/PM'
    };
    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    var self = this;
    this.$el.html(this.template(this.context()));
    this.$timePicker = this.$('input.timepicker');
    if (this.$timePicker) {
      this.$timePicker.timepicker({
        timeFormat: 'hh:mm p',
        interval: 30,
        minTime: '12:00am',
        maxTime: '11:30pm',
        dynamic: false,
        dropdown: true,
        scrollbar: true,
        change: function (time) {
          self.updateEndTime(time);
          self.renderFeedback();
        }
      });
    }
    return this;
  },
  setval: function (val) {
    this.clearFeedback();
    var time = val || this.def.default_value;
    var picker = this.$timePicker.timepicker();
    var parsed = picker.parse(time);
    if (parsed) {
      var formatted = picker.format(parsed);
      picker.setTime(parsed);
      this.$timePicker.val(formatted);
      return this.touch();
    }
    return this;
  },
  val: function () {
    var val = this.$timePicker && this.$timePicker.val();
    return val || null;
  },
  renderFeedbackWithErrors: function (errors) {
    if (errors.length) {
      this.clearFeedback();
    }
    return FieldView.prototype.renderFeedbackWithErrors.call(this, errors);
  },
  errors: function () {
    if (!this.$el.is(':visible')) {
      return [];
    }
    var errors = FieldView.prototype.errors.call(this);
    if (!errors.length) {
      var pattern = new RegExp(/^(?:(?:0?\d|1[0-2]):[0-5]\d) (AM|PM)$/i);
      if (!pattern.test(this.$timePicker.val())) {
        errors.push('Please enter a valid time (ex: 9:00 PM)');
      }
    }

    return _.map(invokeCallbacks('alterErrors', {
      val: this.val(),
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors, this.toError, this);
  },
  updateTime: function (e) {
    this.updateEndTime(e.currentTarget.value);
  },
  updateEndTime: function (time) {
    var subviews = this.parent.subviews;
    var prefix = this.getFieldPrefix(this.def.name, 'StartTime');
    var eventEndTime = subviews[prefix + 'EndTime'];
    if (!eventEndTime || !time || this.def.name.indexOf('EndTime') >= 0) {
      return;
    }
    var startTime = moment(time);
    if (moment(startTime).isValid() && !eventEndTime.val()) {
      eventEndTime.setval(startTime.add(1, 'hour').format('hh:mm A'));
    }
  }
});
