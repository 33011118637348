import countries from 'data/countries';

export default function requestAutocomplete(callback) {
  var form = document.createElement('form');

  if (!form.requestAutocomplete) {
    callback({
      err: 'unsupported',
      data: {}
    });
    return;
  }

  var field = {
    'email': 'EmailAddress',
    'name': 'FullName',
    'tel': 'HomePhone',
    'address-line1': 'AddressLine1',
    'address-line2': 'AddressLine2',
    'locality': 'City',
    'region': 'StateProvince',
    'postal-code': 'PostalCode',
    'country': 'Country'
  };

  var dataToRequest = Object.keys(field).map(function (item) {
    return 'billing ' + item;
  });

  field.number = 'Account';
  field['exp-month'] = 'ExpirationMonth';
  field['exp-year'] = 'ExpirationYear';
  field.type = 'AccountType';
  field.csc = 'SecurityCode';
  dataToRequest.push(
    'cc-number',
    'cc-exp-month',
    'cc-exp-year',
    'cc-type',
    'cc-csc'
  );

  var inputs = dataToRequest.map(function (autocompleteVal) {
    var input = document.createElement('input');
    input.autocomplete = autocompleteVal;
    form.appendChild(input);
    return input;
  });

  function getData() {
    var data = {};
    inputs.forEach(function (input) {
      var name = '';
      var type = input.autocomplete;
      if (type.slice(0, 2) === 'cc') {
        name = field[type.slice(3)];
        data[name] = input.value;
      } else if (type.slice(0, 7) === 'billing') {
        name = field[type.slice(8)];
        data[name] = input.value;
      }
    });

    if (data.ExpirationYear) {
      data.ExpirationYear = data.ExpirationYear.slice(2);
    }

    if (data.FullName) {
      var name = data.FullName.split(' ');
      if (name.length) {
        data.FirstName = name[0];
        if (name.length > 2) {
          data.MiddleName = name[1];
          data.LastName = name.slice(2).join(' ');
        } else {
          data.LastName = name[1];
        }
      }
    }

    if (data.AccountType) {
      data.AccountType = data.AccountType.toLowerCase().replace(/\s/, '');
      if (data.AccountType === 'americanexpress') {
        data.AccountType = 'amex';
      }
    }

    if (data.Country) {
      if (data.Country in countries) {
        data.Country = countries[data.Country];
      } else {
        delete data.Country;
      }
    }

    return data;
  }

  form.addEventListener('autocomplete', function () {
    callback({
      data: getData()
    });
  });

  form.addEventListener('autocompleteerror', function (event) {
    callback({
      err: event.reason,
      data: getData()
    });
  });

  form.requestAutocomplete();
}
