import Backbone from 'lib/backbone';
import fastaction_config from 'app/fastaction_config';
import auth_popups from 'app/auth_popups';
import templates from 'generated/compiled_handlebars_templates';
import ActionTagConfig from 'config/actionTagConfig';
import cookieConsentConfig from 'app/CookieConsentConfiguration';

var $ = Backbone.$,
  _ = Backbone._,
  fastActionHtml = '<i>Fast</i><b>Action</b>';

export default Backbone.View.extend({
  __name__: 'FastActionView',
  events: {
    'click .toggle-menu': 'toggleMenu',
    'click #profile-menu a': 'closeMenu'
  },
  initialize: function (options) {
    _.bindAll(this, 'render');
    this.asked = false;
    this.options = options;
    this.resources = this.options.resources || {};
    this.listenTo(this.model, 'sync', this.render);
    this.listenTo(this.model, 'clear', this.render);
    this.listenTo(this.model, 'syncError', this.render);
    this.listenTo(cookieConsentConfig, 'change:functionalAccepted', this.render);
    return this.render();
  },
  toggleMenu: function (e) {
    e.preventDefault();
    var that = $(e.currentTarget);
    if (/^https?/.test(that.attr('href'))) {
      return;
    }
    var position = that.position();
    position.top += that.height() - 2;
    this.$('#profile-menu').toggle().css(position);
    that.toggleClass('menu-open');
    return this;
  },
  closeMenu: function (e) {
    e.preventDefault();
    this.$('#profile-menu').hide();
    this.$('.profile-link').removeClass('menu-open');
    return this;
  },
  clearFeedback: function () {
    return this;
  },
  render: function () {
    var context = {
      fastaction_url: fastaction_config.urls.base(),
      resources: this.resources || {},
      config: ActionTagConfig || {},
      cookieCompliance: false
    };

    let fastActionSessionId = this.model.get('fastActionSessionId') || null;
    context.authed = !!this.model.get('authed');
    context.name = this.model.nameFromProfile() || this.model.nameFromActionId();
    context.id = this.model.get('id');

    if (!this.options.nologin) {
      context.authed = true;
    }

    context.text = {
      EditMyBlankProfile: this.resources.fill(this.resources.PrimaryResources.EditMyBlankProfile, fastActionHtml),
      ForBlank: this.resources.fill(this.resources.PrimaryResources.ForBlank, fastActionHtml)
    };

    if (cookieConsentConfig.areFunctionalCookiesAccepted()) {
      context.cookieCompliance = true;
    }

    this.$el.html(templates.fa_main(context));

    auth_popups(fastActionSessionId);

    return this;
  }
});
