import _ from 'lib/lodash.custom';
import $ from 'lib/jquery.ui';
import telemetry from 'app/views/helpers/telemetry';

function loadScriptInner(url, timeout) {
  var dfd = $.Deferred();
  timeout = timeout || 2000;
  var script = document.createElement('script');
  script.type = 'text/javascript';
  var start, setTimeoutId;

  function handleResult(result) {
    try {
      if (setTimeoutId) {
        window.clearTimeout(setTimeoutId);
        setTimeoutId = null;
      }

      var end = new Date();
      var metadata = {
        Timeout: timeout,
        FetchStart: start.toISOString(),
        FetchEnd: end.toISOString(),
        ScriptUrl: url,
        IsActionTagDynamicScript: true,
        Result: result
      };
      var success = (result === 'success');
      var duration = end - start;
      var responseCode = success ? 200 : 400;
      telemetry.trackDependencyData({
        data: url, target: url,
        id: '0', method: 'GET', responseCode: responseCode,
        success: success, duration: duration, properties: metadata
      });

    } catch (err) {
      //swallow
    }
    dfd.resolve();
  }

  script.src = url;
  if (script.readyState) {  //IE
    script.onreadystatechange = function scriptOnReadyStateChange() {
      if (script.readyState === 'loaded' ||
        script.readyState === 'complete') {
        script.onreadystatechange = null;
        handleResult('success');
      }
    };
  } else {  //Others
    script.onload = function scriptOnLoad() {
      handleResult('success');
    };
  }
  script.onerror = function loadScriptError() {
    handleResult('error');
  };

  start = new Date();
  document.getElementsByTagName('head')[0].appendChild(script);

  // in case the file never loads
  setTimeoutId = window.setTimeout(function () {
    handleResult('timeout');
  }, timeout);
  return dfd.promise();
}

var loadScript = _.memoize(loadScriptInner, /* jslint unused: false */ function (url, timeout) {
  return url;
});
loadScript.nocache = loadScriptInner;
export default loadScript;
