import $ from 'lib/jquery.ui';
import parseUrl from 'lib/parseUrl';

// Will attempt to post back an object indicating that the user clicked on a social media button
var recordActivity = function (formUrl, trackingId, socialNetwork, callback) {
  // Regardless of what happens in the share dialog.  Record that the user clicked on the button
  var parsedUrl = parseUrl(formUrl);
  var recruiterActivityUrl = parsedUrl.origin + parsedUrl.pathname.replace('Forms', 'RecruiterActivity');
  $.ajax({
    type: 'POST',
    url: recruiterActivityUrl,
    data: {
      SocialNetwork: socialNetwork,
      SubmissionTrackingId: trackingId
    },
    success: function () {
      // Form was clicked and activity recorded successfully on the server
      if (callback) {
        callback();
      }
    },
    error: function () {
      // Could also mean that the click was already recorded.
      // DB constraint prevents server from recording click from same button twice.
      if (callback) {
        callback();
      }
      console.log('Activity logging failure.');
    }
  });
};

export default {
  recordActivity: recordActivity
};
