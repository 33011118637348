import Backbone from 'lib/backbone';
import TextFieldView from 'app/views/TextFieldView';

var _ = Backbone._;
export default TextFieldView.extend({
  __name__: 'VgsTextFieldView',
  initialize: function () {
    TextFieldView.prototype.initialize.call(this);
    _.bindAll(this, 'initVgsElement', 'vgsStateChange', 'fallbackRender', 'handleVgsStateChange', 'updateContainerClasses', 'recreateVgsElement', 'focus');
    this.vgs = _.extend(this.vgs || {}, {
      loaded: !!this.options.formview.vgs.form,
      state: this.options.formview.vgs.state[this.name] || {},
      form: this.options.formview.vgs.form,
      containerId: 'vgs-' + this.name + '-' + this.options.formview.form_definition().formId,
      field: null,
      $container: null
    });

    //allow style overwriting
    this.vgsConfig = _.cloneDeep(this.vgsConfig || {});
    _.merge(this.vgsConfig.css, this.def.styles || {});

    // A hack for EVA-4488 to hide the card icon on the CC input. this.def.styles doesn't really help
    if (this.def.hideCardIcon) {
      delete this.vgsConfig.showCardIcon;
    }

    if (this.vgs.loaded) {
      this.listenTo(this.options.formview, 'vgsStateChange', this.vgsStateChange);
      this.listenTo(this.options.formview, 'recreateVgsElement', this.recreateVgsElement);
    }
  },
  recreateVgsElement: function (newForm) {
    if (this.vgs.loaded && this.vgs.field) {
      if (newForm) {
        this.vgs.form = newForm;
      }

      this.vgs.field = null;
      this.$('.vgs-loading-placeholder').show();
      if (this.vgs.$container) {
        this.vgs.$container.addClass('loading');
        this.vgs.$container.html('');
        this.vgs.$container = null;
      }
      this.initVgsElement();
    }
  },
  clearDeferredVgsInitIfExists: function () {
    // If we've deferred a vgs init, cancel it before doing any sort of cleanup/reload
    if (this.deferredVgsInitTimoutId) {
      window.clearTimeout(this.deferredVgsInitTimoutId);
      delete this.deferredVgsInitTimoutId;
    }
  },
  initVgsElement: function () {
    // Make sure there are no currently scheduled inits
    this.clearDeferredVgsInitIfExists();

    if (this.vgs.field) {
      return;
    }
    var containerSelector = '#' + this.vgs.containerId;
    this.vgs.$container = this.$(containerSelector);
    // use the js selector as jquery returns the dom element even after it's removed
    if (!this.vgs.$container || !this.vgs.$container.length || !document.querySelector(containerSelector)) {
      return;
    }

    // make sure the container is empty
    this.vgs.$container.html('');

    var context = this.context();
    var fieldConfig = _.merge({
      name: context.name,
      placeholder: context.placeholder
    }, this.vgsConfig);
    fieldConfig.validations = fieldConfig.validations || [];
    if (this.def.required) {
      fieldConfig.validations.push('required');
    }
    fieldConfig.css = _.merge({}, this.vgsConfig.css, context.styles || {});
    this.vgs.field = this.vgs.form.field(containerSelector, fieldConfig);

    this.updateContainerClasses();

    this.vgs.$container.focus(this.focus);
    this.$el.click(this.focus);
  },
  focus: function () {
    if (this.vgs.loaded && this.vgs.field) {
      try {
        this.vgs.field.focus();
        return this;
      } catch (focusErr) {
        // swallow and fallback on default focus handling
      }
    }
    return TextFieldView.prototype.focus.call(this);
  },
  updateContainerClasses: function () {
    if (!this.vgs.state || !this.vgs.$container) {
      return;
    }
    // If these state flags are true, apply them as classes to the container to handle styling of the container
    // Important one: isFocused -> mimics normal focus state for an input
    var data = _.pick(this.vgs.state, 'isDirty', 'isEmpty', 'isFocused', 'isValid');
    for (var key in data) {
      this.vgs.$container.toggleClass(key, data[key]);
    }
  },
  handleVgsStateChange: function () {
    //default: do nothing
  },
  vgsStateChange: function (newState) {
    newState = newState[this.name];
    if (newState && this.vgs.$container && this.vgs.$container.hasClass('loading')) {
      // once we have state for our element for the first time, hide the placeholder and show the real thing
      // give it a slight delay so the transition is less jarring in case VGS isn't 100% ready to go
      setTimeout(function () {
        this.$('.vgs-loading-placeholder').hide();
        if (this.vgs.$container) {
          this.vgs.$container.removeClass('loading');
        }
      }.bind(this), 100);
    }

    var prevState = this.vgs.state;
    var hasChanged = this.vgs.state && newState && newState.isDirty && !_.isEqual(newState, this.vgs.state);
    this.vgs.state = newState || {};
    this.updateContainerClasses();
    // Given the state flags that VGS provides, we have to try and map that back to typical browser events
    // such blur, keyup, etc
    var isBlur = newState && prevState && !newState.isFocused && prevState.isFocused;
    if (isBlur) {
      // In order to mimic ActionTag behavior for normal inputs, after you have entered a value into a textbox
      // and blurred, the input will not report all errors immediately (rather than waiting for you to blur)
      this.vgs.hasBlurred = true;
    }
    var isError = newState && !newState.isValid;
    var wasError = prevState && !prevState.isValid && newState && newState.isValid;
    if (isBlur) {
      this.$el.trigger('blur');
    }
    if (hasChanged) {
      this.touch();
      if (isBlur || (isError && this.vgs.hasBlurred) || wasError) {
        // This will indirectly result in renderFeedback getting called
        // but provides a more generic event for other stuff to hook into
        this.$el.trigger('change');
      }
    }

    // Call optional additional handler that specific implementations can overwrite (example: CreditCardView)
    this.handleVgsStateChange(newState, prevState, {
      hasChanged: hasChanged,
      isBlur: isBlur,
      isError: isError,
      wasError: wasError
    });
  },
  render: function () {
    var context = this.context();
    this.$el.html(this.template(context));

    if (!this.vgs.loaded) {
      return this.fallbackRender(context);
    }

    this.clearDeferredVgsInitIfExists();
    this.deferredVgsInitTimoutId = _.defer(this.initVgsElement);
    return this;
  },
  fallbackRender: function () {
    return this;
  },
  dispose: function () {
    this.clearDeferredVgsInitIfExists();

    if (this.vgs.loaded && this.vgs.field) {
      // hack VGS field to prevent out of order issues
      this.vgs.field._iframe = {
        contentWindow: {
          postMessage: _.noop
        }
      };

      // clear our references
      this.vgs.$container.html(''); //empty container
      // clear our references
      this.vgs.field = null;
      this.vgs.$container = null;
      // trigger new VGS form
      this.options.formview.recreateVgsForm(this.vgs.form);
    }
  }
});
