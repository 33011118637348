import Backbone from 'lib/backbone';
import RadiosView from 'app/views/RadiosView';
import invokeCallbacks from 'app/NVTagCallbacks';
import { accounting } from 'lib/accounting';

var $ = Backbone.$;
var _ = Backbone._;

export default RadiosView.extend({
  __name__: 'PremiumGiftsView',
  type: 'gifts',
  events: {
    'keyup input[name="gift"]': 'cycleOptions',
    'change   :radio': 'onGiftSelected'
  },
  initialize: function () {
    RadiosView.prototype.initialize.call(this);
    this.template = this.options.templates.premium_gifts;


    this.contributionAmountModel = this.options.formview.contributionAmountModel;

    var defaultGift = this.def.gifts[0];
    this.setGift(defaultGift);

    // Only the base amount is applicable to premium gifts, so listen for when it changes
    // There are cases where the base amount can change without the total amount changing,
    // such as when base amount and coverCosts hit the max value
    this.listenTo(this.contributionAmountModel, 'change:baseAmount', this.amountChanged);
  },
  context: function () {
    var self = this;
    var def = self.def;
    var context = {
      gifts: []
    };

    var currentAmount = this.contributionAmountModel.get('baseAmount');
    var currentSelection = this.selectedValue;

    context.gifts = _.map(def.gifts, function (gift) {
      var mappedGift = _.extend({}, gift);
      // ensure precision
      if (!gift.no_gift) {
        if (gift.threshold) {
          mappedGift.threshold = accounting.formatMoney(gift.threshold);
          mappedGift.floatThreshold = self.toFloat(mappedGift.threshold, 2);
        }
        if (currentAmount < mappedGift.floatThreshold) {
          mappedGift.willIncrease = true;
        }
      }

      // check the current gift if selected
      if (gift.val === currentSelection) {
        mappedGift.checked = 'checked';
      }
      return mappedGift;
    });

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: def }).context;
  },
  render: function () {
    var context = this.context();
    var template = this.template(context);
    var self = this;

    self.$el.html(template);

    $('.gift-img-container').each(function (index, container) {
      var giftImgContainer = $(container).find('.gift-img');
      var img = $(giftImgContainer).find('img');
      // listen to the load event only once.
      // after the load event triggers, stop listening
      $(img).one('load', function () {
        $(container).css('background-image', 'none');
      });
    });

    return self;
  },
  val: function () {
    var elm = $('input[name=gift]:checked');
    if (elm && elm[0]) {
      var val = elm[0].value;
      return val;
    }
    return null;
  },
  setGift: function (gift) {
    // gift can be empty when 'no gift' is not configured as an option
    if (!gift) {
      delete this.selectedValue;
      window.nvtag.trigger('giftChanged', { newAmount: 0 });
      return;
    }

    this.selectedValue = gift.val;
    var floatThreshold = this.toFloat(gift.threshold || 0, 2);
    window.nvtag.trigger('giftChanged', { newGift: gift, newAmount: floatThreshold });
  },
  onGiftSelected: function (e) {
    var gift = this.getGiftFromVal(e.currentTarget.value);
    var currentAmount = this.contributionAmountModel.get('baseAmount');
    var thresholdValue = gift.threshold || 0;


    thresholdValue = this.toFloat(thresholdValue, 2);
    if (thresholdValue > currentAmount) {
      this.options.formview.setPageInfoAlert([this.resources.PrimaryResources.PremiumGiftAmountIncreaseAlert]);
    }

    this.setGift(gift);
    // update text
    this.render();

    // Element was re-rendered, so focus removed. This gets it back.
    $('#' + e.target.id).focus();


    this.renderFeedback();
  },
  amountChanged: function () {
    var currentAmount = this.contributionAmountModel.get('baseAmount');
    var currentGift = this.getSelectedGift();
    if (currentGift && currentAmount < this.toFloat(currentGift.threshold, 2)) {
      this.options.formview.setPageInfoAlert([this.resources.PrimaryResources.ContributionAmountTooSmall]);

      // The "no gift" option has val 0 if it exists
      // Set the gift to either the 'noGift' option or null if it doesn't exist
      var noGift = this.getGiftFromVal(0);
      this.setGift(noGift);
    }
    this.render();
  },
  clearFeedback: function () {
    $('.Premiums').removeClass('error');
    $('.Premiums > small.error').remove();
    return this;
  },
  renderFeedbackWithErrors: function (errors) {
    if (errors.length) {
      var premiums = $('.Premiums');
      premiums.addClass('error');
      premiums.append(this.error_template({ 'error_text': errors, 'behavior_classes': 'error' }));
    } else {
      this.clearFeedback();
    }
    return errors;
  },
  errors: function () {
    var errors = [];
    var val = this.val();
    if (_.isNull(val) || _.isUndefined(val)) {
      // clear current feedback so that it doesn't appear more than once
      this.clearFeedback();
      errors.push(this.resources.PrimaryResources.PleaseSelectGift);
    }

    errors = invokeCallbacks('alterErrors', {
      val: val,
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors;
    this.trigger(errors.length ? 'invalid' : 'valid');
    return _.map(errors, this.toError, this);
  },
  getGiftFromVal: function (val) {
    return _.find(this.def.gifts, { val: parseInt(val) });
  },
  getSelectedGift: function () {
    var val = this.selectedValue;
    if (_.isNull(val) || _.isUndefined(val)) {
      return undefined;
    }
    return this.getGiftFromVal(val);
  }
});
