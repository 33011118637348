// this is here for backwards compatibility
import CheckboxListManagerView from 'app/views/CheckboxListManagerView';

export default CheckboxListManagerView.extend({
  __name__: 'ActivistCodeQuestionView',
  type: 'activistCodeQuestion',
  initialize: function () {
    CheckboxListManagerView.prototype.initialize.call(this);
    this.template = this.options.templates.question;
  }
});
