import _ from 'lib/lodash.custom';
import invokeCallbacks from 'app/NVTagCallbacks';
import states from 'data/states';
import countries from 'data/countries';
import countryKeys from 'data/country.keys';
import { accounting } from 'lib/accounting';
import moment from 'lib/moment';

function fields(tree) {
  return _.compact(_.reduce(tree, function (memo, field) {
    return _.isObject(field)
      ? memo.concat(field.children ? fields(field) : field)
      : memo;
  }, []));
}

// Given a form definition, return a querystring -> FieldName dictionary, e.g. {fn: FirstName, ln: LastName, ...}
function querystring_dict(def, sought_key) {
  return _.reduce(fields(def), function (memo, field) {
    if (field[sought_key]) {
      memo[field[sought_key]] = field.name;
    }
    return memo;
  }, {});
}

// This logic is a little surprising -- when isFlat == false, it skips the top level of the tree!
function find(tree, name, isFlat) {
  return _(isFlat ? tree : fields(tree)).find({ 'name': name });
}

function fields_of_type(tree, type, isFlat) {
  return _.filter(isFlat ? tree : fields(tree), { 'type': type });
}

function options_of_type(tree, options, isFlat) {
  return _.filter(isFlat ? tree : fields(tree), { 'options': options });
}

function startsWith(str, search, pos) {
  return str.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
}

function getParsedAmountIfValid(amount, min, max, amountOptions) {
  var minFloat = parseFloat(accounting.toFixed(min, 2));
  var maxFloat = parseFloat(accounting.toFixed(max, 2));
  var amountValue = accounting.toFixed(amount, 2);
  var amountFloat = parseFloat(amountValue);

  // verify amount is within range
  if (amountFloat >= minFloat && amountFloat <= maxFloat) {
    // prohibit custom amounts if other option is disabled
    if (!amountOptions || _.find(amountOptions, { other: true }) || _.find(amountOptions, { val: amountValue })) {
      return amountValue;
    }
  }

  return null;
}

function getQueryStringAmountOptions(queryStringValue, baseOptions, min, max) {
  // need a query string
  if (!queryStringValue) {
    return null;
  }

  // get the valid options
  var overrideOptions = _.reduce(_.uniq(queryStringValue.split(',')), function (result, amount) {
    var validAmount = getParsedAmountIfValid(amount, min, max);
    if (validAmount) {
      result.push({ val: validAmount, display: null });
    }
    return result;
  }, []);

  // only override if we have valid options left
  if (!overrideOptions.length) {
    return null;
  }

  // preserve the other amount field
  var otherField = _.find(baseOptions, { other: true });
  if (otherField) {
    var otherFieldCopy = _.clone(otherField);
    overrideOptions.push(otherFieldCopy);
  }

  return overrideOptions;
}

function fix(formdef, query_string, nologin, form_view_model) {
  var resourcesClone = _.cloneDeep(formdef.resources);
  formdef = invokeCallbacks('preAlterFormDefinition', { form_definition: formdef, query_string: query_string, form_view_model: form_view_model }).form_definition;
  // handle the preAlterFormDefinition removing resources object from the formdef - OA preview
  if (!formdef.resources) {
    formdef.resources = {};
  }
  formdef.resources = _.extend(resourcesClone, formdef.resources);
  var form_fields = fields(formdef.form_elements);

  // Default select options
  var defaultSelect = formdef.resources.PrimaryResources.DefaultSelectOption;
  var stateSelect = formdef.resources.PrimaryResources.DefaultStateSelectOption;
  var countrySelect = formdef.resources.PrimaryResources.DefaultCountryOption;

  // Make "textfields" that have "valueType" of date into "date" fields
  var date_fields = _.where(form_fields, { valueType: 'date' });
  _.each(date_fields, function (f) {
    f.type = 'date';
  });


  // Supplant Oberon amount options with those in querystring (e.g. ?amtOpts=3,4,5) if applicable
  var amount_field = find(form_fields, 'SelectAmount', true);
  var normalizeAmountFieldOptions = function (amount_field) {
    // Now convert amount options to a better object format for rendering downstream
    // Before: options: [{'20.00': '$20.00'}, {'35.00': '$35.00'}, ...]
    // After: options: [{val: '20.00', display: '$20.00'}, {val: '35.00', display: '$35.00'}, ...]
    amount_field.options = _.map(amount_field.options, function (value, key) {
      var option = { val: key, display: value };
      if (option.val === 'other') {
        option.other = true;
      }
      return option;
    });
    return amount_field;
  };

  if (amount_field) {
    // With all this customization, the amount field is not really of type radio anymore, so override it.
    amount_field.type = 'amount';
    normalizeAmountFieldOptions(amount_field);

    // We handle query string overrides to the amount directly, so prevent AT's default behavior
    delete amount_field.queryString;

    // Handle the amtopts fields.  To support multi-currency forms in OA,
    // much of this logic will live on the server side.  For other form definitions,
    // we maintain the previously existing client side logic.  Use the existence of
    // amount_field children to determine whether that's necessary.
    if (query_string && !amount_field.children) {
      // handle amtOpts qs
      amount_field.options =
        getQueryStringAmountOptions(query_string.amtopts, amount_field.options, amount_field.valueMin, amount_field.valueMax)
        || amount_field.options;

      // set the default amount if passed in by query
      if (query_string.am) {
        amount_field.default_value =
          getParsedAmountIfValid(query_string.am, amount_field.valueMin, amount_field.valueMax, amount_field.options)
          || amount_field.default_value;
      }
    }
    if (amount_field.children) {
      _.each(amount_field.children, normalizeAmountFieldOptions);
    }
  }

  // Change radio options list from an unorderd dict to an arbitrarily ordered array of single key objects
  // (the format the radio/amount views expect)
  _.each(fields_of_type(form_fields, 'radios', true), function (field) {
    if (_.isArray(field.options) && field.options[0] && field.options[0].display && field.options[0].value) {
      // if options is already an array, do not alter it and mark the field so the view knows how to handle it
      // also check that 'display' and 'value' properties are present, in case the formdef wants an array of values
      // to be used, with the index as the key
      field.ordered = true;
      return;
    }

    field.options = _.map(field.options, function (value, key) {
      var obj = {};
      obj[key] = value;
      return obj;
    });
  });


  if (formdef.metadata && formdef.metadata.options) {
    // Metadata-sourced dropdowns
    _.each(_.filter(form_fields, function (field) {
      return _.isString(field.options) &&
        formdef.metadata.options[field.options];
    }), function (field) {
      field.options_src = field.options;
      field.options = _.clone(formdef.metadata.options[field.options]);
    });

    // metadata-sourced frequency amount options
    if (amount_field) {
      _.each(_.filter([amount_field].concat(amount_field.children || []), function (field) {
        return !!field.frequencyOptions;
      }), function (field) {
        _.each(field.frequencyOptions, function (freqOpt) {
          if (_.isString(freqOpt.options) && formdef.metadata.options[freqOpt.options]) {
            freqOpt.options_src = freqOpt.options;
            freqOpt.options = normalizeAmountFieldOptions(_.cloneDeep(formdef.metadata.options[freqOpt.options]));
          }
        });
      });
    }
  }

  function getRecurringOptions() {
    // get the base recurring amount options (they're the same for recurring frequencies)
    var baseOptionsObj = _.find(amount_field.frequencyOptions, function (opt) {
      return opt.value !== '0' && opt.options && opt.options.options;
    });
    return baseOptionsObj ? baseOptionsObj.options.options : null;
  }

  // handle recurringAmtOpts qs for single-currency forms
  if (amount_field && !amount_field.children && amount_field.frequencyOptions && query_string.recurringamtopts) {
    var optionOverrides =
      getQueryStringAmountOptions(query_string.recurringamtopts, getRecurringOptions(), amount_field.valueMin, amount_field.valueMax);

    if (optionOverrides) {
      _.forEach(amount_field.frequencyOptions, function (freqOpt) {
        // frequency '0' is one-time, so don't change that
        if (freqOpt.value !== '0' && freqOpt.options) {
          freqOpt.options.options = _.cloneDeep(optionOverrides);
        }
      });
    }
  }

  // handle recurringAm qs (default recurring amount) for single-currency forms
  if (amount_field && !amount_field.children && amount_field.frequencyOptions && query_string.recurringam) {
    var recurringAm = getParsedAmountIfValid(query_string.recurringam, amount_field.valueMin, amount_field.valueMax, getRecurringOptions());
    if (recurringAm) {
      _.forEach(amount_field.frequencyOptions, function (freqOpt) {
        if (freqOpt.value !== '0') {
          freqOpt.options.default_value = recurringAm;
        }
      });
    }
  }

  // State Dropdowns
  _.each(options_of_type(form_fields, 'states', true), function (field) {
    field.options = _.map(states, function (state) {
      return { value: state, display: state };
    });
    field.options.unshift({ value: '', display: stateSelect });
  });

  // Country Dropdowns
  _.each(options_of_type(form_fields, 'countries', true), function (field) {
    field.options = _.map(countryKeys, function (key) {
      return { value: countries[key], display: countries[key] };
    });
    field.options.unshift({ value: '', display: countrySelect });
  });

  // Expiration Months
  _.each(options_of_type(form_fields, 'months', true), function (field) {
    field.options = _.map(_.range(1, 13), function (month) {
      return { value: ('0' + month).slice(-2), display: ('0' + month).slice(-2) };
    });
    field.options.unshift({ value: '', display: defaultSelect });
  });

  // Expiration Years
  _.each(options_of_type(form_fields, 'years', true), function (field) {
    var current_year = parseInt(moment().format('YY'), 10);
    // Note, what follows is a Y2.1K problem
    field.options = _.map(_.range(current_year, current_year + 15), function (year) {
      return { value: year, display: year + 2000 };
    });
    field.options.unshift({ value: '', display: defaultSelect });
  });

  // There may be a textfield that needs to be associated with an 'other' option
  // Go through textfields and try to associate all 'QuestionOtherTextResponse' fields
  _.each(fields_of_type(form_fields, 'textfield', true), function (field) {
    if (startsWith(field.name, 'CustomFormFieldQuestion') || startsWith(field.name, 'QuestionOtherResponse')) {
      ///////////////////////////////////////////////////////////////////////////////////////////////////////
      // Formdef section containing 'other' text field information is marshalled as follows:
      //{
      //  "name": "CustomFormFieldQuestion_1100129298962751_QuestionOtherResponse_8925876883531115520",
      //  "type": "textfield",
      //  "title": "Other",
      //  "required": false,
      //  "allowMultipleValues": false
      //},
      //////////////////////////////////////////////////////////////////////////////////////////////////////////

      var expr = new RegExp('((CustomFormFieldQuestion|QuestionOtherResponse)_.*)_(QuestionOtherTextResponse_.*)$');
      //Attempt to split the field name based on the expected pattern.  This allows us to match up for required
      //in situations where the parent value has to take the required attribute instead of the child
      var splitFieldName = expr.exec(field.name);
      if (splitFieldName) {
        var index = _.findIndex(form_fields, function (check) { return check.name.indexOf(splitFieldName[1]) > 0; });
        // Look for question and update options
        var select = index > 0 ? form_fields[index] : find(form_fields, splitFieldName[1]);

        // define a property on the response that associates it with a textfield
        // The field name will be used to 'bind' the $elem.$other property to the
        // textfield on the view (dropdown, checkbox or radio) during rendering
        if (select) {
          select.other = { name: field.name };
          if (select.type === 'radios' || select.type === 'checkbox') {
            // We want to hide the label above the textfield for radio options
            field.labelhide = true;
          }
        }
      }
    }

  });


  _.each(fields_of_type(form_fields, 'select', true), function (field) {
    // Only want to convert the ones which have the wrong format
    if (field.options) {
      if ((field.options.length > 0) && field.options[0] && (!_.has(field.options[0], 'value') || !_.has(field.options[0], 'display'))) {
        field.options = _.map(field.options, function (option, key) {
          return { value: key, display: option };
        });
      }
      // Always add a blank value if one isn't there already
      if (field.options.length === 0 || field.options[0].value !== '') {
        field.options.unshift({ value: '', display: defaultSelect });
      }
    }
  });

  if (nologin) {
    formdef.fastActionNologin = true;
  }

  return invokeCallbacks('alterFormDefinition', { form_definition: formdef, query_string: query_string }).form_definition;
}

function add_field_to_fieldset(formDef, field, fieldsetName) {
  var fieldset = _(fields(formDef)).find({ 'name': fieldsetName, 'type': 'fieldset' });
  fieldset.children.push(field);
}

export default {
  fields: fields,
  find_field: find,
  fields_of_type: fields_of_type,
  options_of_type: options_of_type,
  clone: _.clone,
  querystring_dict: querystring_dict,
  fix: fix,
  add_field_to_fieldset: add_field_to_fieldset
};
