import FieldsetView from 'app/views/FieldsetView';
import SelectView from 'app/views/SelectView';
import TextFieldView from 'app/views/TextFieldView';
import MarkupView from 'app/views/MarkupView';
import PaymentInformationView from 'app/views/PaymentInformationView';
import ContributionView from 'app/views/ContributionView';
import NotMeView from 'app/views/NotMeView';
import CaptchaView from 'app/views/CaptchaView';
import TicketingView from 'app/views/TicketingView';
import HiddenView from 'app/views/HiddenView';
import AdvocacyView from 'app/views/AdvocacyView';
import AdvocacyTweetsView from 'app/views/AdvocacyTweetsView';
import TributeGiftView from 'app/views/TributeGiftView';
import RecipientInformationView from 'app/views/RecipientInformationView';
import ActivistCodeQuestionView from 'app/views/ActivistCodeQuestionView';
import ShippingInformationView from 'app/views/ShippingInformationView';
import EventInformationView from 'app/views/EventInformationView';
import TimeView from 'app/views/TimeView';
import InternationalPhoneView from 'app/views/InternationalPhoneView';
import SignaturePadView from 'app/views/SignaturePadView';
import PaymentMethodView from 'app/views/PaymentMethodView';
import MatchProView from 'app/views/MatchProView';

export default {
  'fieldset_view': FieldsetView,
  'select_view': SelectView,
  'textfield_view': TextFieldView,
  'markup_view': MarkupView,
  'payment_information_view': PaymentInformationView,
  'contribution_view': ContributionView,
  'notme_view': NotMeView,
  'captcha_view': CaptchaView,
  'ticketing_view': TicketingView,
  'hidden_view': HiddenView,
  'advocacy_view': AdvocacyView,
  'advocacy_tweets_view': AdvocacyTweetsView,
  'tribute_gift_view': TributeGiftView,
  'recipient_information_view': RecipientInformationView,
  'activistCodeQuestion_view': ActivistCodeQuestionView,
  'shipping_information_view': ShippingInformationView,
  'event_information_view': EventInformationView,
  'time_view': TimeView,
  'international_phone_view': InternationalPhoneView,
  'signature_pad_view': SignaturePadView,
  'payment_method_view': PaymentMethodView,
  'matchpro_view': MatchProView
};
