import loadScript from 'app/loadScript';
import $ from 'lib/jquery';
import telemetry from 'app/views/helpers/telemetry';
import _ from 'lib/lodash.custom';
// actionTagConfig was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'config/actionTagConfig';

// Google Developer docs: https://developers.google.com/recaptcha/docs/invisible

// Overview of how ActionTag integrations:
/*
    - Wrap all calls to grecaptcha in grecaptcha.ready(func)
        - As soon as grecaptcha is available on window, this function exists and can be used
        - Ensures if we need to use one of the grecaptcha immediately, and its not quite ready for us, we are using it safely
    - Call grecaptcha.render(container, { ... }) after the formview initially renders
        - This requires a container div to hook into
        - This call returns a widget ID we need to use for future calls
        - If the container gets blown away from the DOM, we will need to call grecaptcha.render(container, {...}) again
        - The parameters to the function include registering callbacks, the most noteworthy is just called "callback"
            - The callback is triggered when the recaptcha code has a submission token ready for us to use
            - Is only called triggered immediately after the token becomes available, so if you aren't ready for it you have to call grecaptcha.getResponse(widgetId) to get it
    - Call grecaptcha.execute(widgetId) on submit to initiate the recaptcha flow
        - This will potentially prompt the user to fill in a captcha
        - After calling this, and recaptcha has determined the user is ok to proceed, the "callback" registered in the .render() will be triggered
    - Call grecaptcha.reset(widgetId) on server errors to ensure we don't attempt to reuse a token that has been "verified" already
        - Once we call the verify API on the server side with a token, it cannot be used again
        - The server responses from OA will explicitly indicate whether or not ActionTag should invalidate the token
*/

var jsUrl = 'https://www.google.com/recaptcha/api.js?render=explicit';

// fetch the JS file and load it
function init() {
  if (window.grecaptcha) {
    return $.Deferred().resolve(true);
  }

  try {
    return loadScript(jsUrl, 5000).then(function () {
      return !!window.grecaptcha;
    }, function () {
      return !!window.grecaptcha;
    });
  } catch (lserr) {
    return $.Deferred().resolve(!!window.grecaptcha);
  }
}

// ensure the JS file has been fetched, then wrap google's .ready() with our own
function ready() {
  var dfd = $.Deferred();
  init().then(function (success) {
    if (!success) {
      return dfd.reject('grecaptcha init failed to fetch script');
    }

    if (window.grecaptcha) {
      try {
        return window.grecaptcha.ready(function () {
          dfd.resolve(window.grecaptcha);
        });
      } catch (err) {
        return dfd.reject(err);
      }
    }
    dfd.reject('window.grecaptcha not defined after fetching script');
  }, dfd.reject);
  return dfd.promise();
}

function createCallback(formview) {
  return function responseCallback(res) {
    if (formview.captcha.deferredResponse) {
      // determine how long it took to get a token back
      var elapsedMs = 0;
      if (formview.captcha.startExecute) {
        var stop = Date.now();
        elapsedMs = stop - formview.captcha.startExecute;
        formview.captcha.startExecute = null;
      }

      formview.captcha.deferredResponse.resolve({ token: res, elapsedMs: elapsedMs });
      // null out the "deferredResponse" value to indicate we are no longer actively waiting for a response
      formview.captcha.deferredResponse = null;
    }
  };
}

function createErrorCallback(formview) {

  // from the Google documentation:
  //  executed when reCAPTCHA encounters an error (usually network connectivity)
  //  and cannot continue until connectivity is restored.
  //  If you specify a function here, you are responsible for informing the user that they should retry.
  return function errorCallback(err) {

    if (formview.captcha.deferredResponse) {
      // we only care about errors if we are currently waiting for a response
      formview.captcha.deferredResponse.reject(err);
      formview.captcha.deferredResponse = null;
    }
  };
}

// "render" the invisible recaptcha into the container
// this will cause the recaptcha badge to appear
function initForm(formview) {

  var dfd = $.Deferred();

  // hang on to the deferred so we can easily chain on it later
  formview.captcha.ready = dfd;

  if (!formview.captcha.publicKey) {
    return dfd.resolve();
  }

  ready().then(function (grecaptcha) {
    try {
      var containerElem = formview.$el.find('.recaptcha-container')[0];
      formview.captcha.widgetId = grecaptcha.render(containerElem, {
        sitekey: formview.captcha.publicKey,
        size: 'invisible',
        callback: createCallback(formview),
        'error-callback': createErrorCallback(formview),
        isolated: true
      });
      dfd.resolve();
    } catch (err) {
      // if we somehow try and re-init, it will throw an error
      // we have to re-render at times, need to be safe here
      dfd.reject(err);
    }
  }, dfd.reject);

  return dfd.promise();
}

// explicitly reset the captcha token in case the form builder server side has told us its no longer valid
function reset(formview) {
  if (!formview.captcha.widgetId) {
    return $.Deferred().resolve();
  }
  return ready().then(function (grecaptcha) {
    grecaptcha.reset(formview.captcha.widgetId);
  });
}

// try and get the captcha response immediately
// if it isn't already available execute the captcha so we can get the response via our previously registered callback
function getResponse(formview, submissionCorrelationProperties) {
  var dfd = $.Deferred();

  // this should be registered no matter what (see initForm)
  if (!formview.captcha.ready) {
    return dfd.reject('not ready');
  }

  // wait for the form view initialization to be ready
  formview.captcha.ready
    .then(function () {

      // no captcha was setup, just resolve
      if (!formview.captcha.widgetId) {
        return dfd.resolve({});
      }

      ready().then(function (grecaptcha) {
        // check if the token already exists (from being previously generated)
        // this can happen if we previously executed the captcha and the submission was rejected afterwards (but not invalidated by the server)
        var res = grecaptcha.getResponse(formview.captcha.widgetId);
        if (res) {
          return dfd.resolve({ token: res, elapsedMs: 0 });
        }

        // we need to prompt the user for it

        // 1. register our dfd so our custom callback will kick in once it is available
        formview.captcha.deferredResponse = dfd;

        // track how long was spent in the captcha flow
        formview.captcha.startExecute = Date.now();


        // Log success/errors on request completion
        dfd.then(function () {
          telemetry.trackEvent({
            name: 'reCAPTCHA execution complete',
            properties: _.defaults({
              Status: 'success'
            }, submissionCorrelationProperties)
          });
        }, function () {
          telemetry.trackEvent({
            name: 'reCAPTCHA execution complete',
            properties: _.defaults({
              Status: 'failure'
            }, submissionCorrelationProperties)
          });
        });

        // 2. execute the captcha (which may potentially display the challenge)
        grecaptcha.execute(formview.captcha.widgetId);

        try {
          // track in GTM that was triggered captcha
          window.nvtag.track(formview.model.get('type'), 'Captcha Call', 'Google Recaptcha v2 Invisible', null, formview);

          // track in App Insights too
          telemetry.trackEvent({
            name: 'reCAPTCHA executed',
            properties: _.defaults({
              CaptchaPublicKey: formview.captcha.publicKey // log so we know what the level it (easy/medium/hard)
            }, submissionCorrelationProperties)
          });

        } catch (logErr) {
          //ignore
        }
      });

    })
    .fail(function (readyErr) {
      dfd.reject(readyErr);
    });

  return dfd.promise();
}

export default {
  init: init,
  initForm: initForm,
  reset: reset,
  getResponse: getResponse
};
