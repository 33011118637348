import Backbone from 'lib/backbone';
import FieldsetView from 'app/views/FieldsetView';
import invokeCallbacks from 'app/NVTagCallbacks';
import formatRows from 'app/views/helpers/formatRows';
import RadiosView from 'app/views/RadiosView';
import _ from 'lib/lodash.custom';

var $ = Backbone.$;
export default FieldsetView.extend({
  __name__: 'ShippingInformationView',
  type: 'shipping_information',
  events: {
    'change   :radio': 'onDestinationChange',
    'keyup input[name="destinations"]': 'cycleOptions',
    'click a.at-dismiss': 'closeCta',
    'click a.auto-fill': 'autoFillAddress'
  },
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);
    this.template = this.options.templates.shipping_information;
    this.giftName = 'gift';

    // listen to gift changes
    window.nvtag.on('giftChanged', this.onGiftChanged, this);
  },
  context: function () {
    var def = this.def;
    var context = {
      title: def.title,
      // if there is a recipient fields section, add the call to action to autofill
      showCta: this.getRecipientInformationSection() ? true : false
    };
    context.title = context.title.replace(/{{\s*PremiumGift\s*}}/g, this.giftName);

    var children = _.map(def.children, function (child, index) {
      return {
        type: child.type,
        index: index,
        name: child.name
      };
    });
    context.rows = formatRows.format('ShippingInformation', children);
    return invokeCallbacks('alterContext', { element: 'fieldset', context: context, def: def }).context;
  },
  render: function () {
    var selectedGift = this.getSelectedGift();
    if (selectedGift && selectedGift.no_gift) {
      this.hide = true; // Prevent setval from overriding hide
      this.$el.hide();
      return this;
    }

    this.hide = false;
    this.$el.show();
    this.$el.html(this.template(this.context()));
    // hide shipping info fields until 'Different Address' is selected
    this.displayShippingFields(false);

    return this;
  },
  val: function () {
    var destination = this.getCheckedDestination();
    if (destination === 'other') {
      return FieldsetView.prototype.val.call(this);
    } else if (destination === 'yourinfo') {
      var contactInfo = this.options.formview.subviews.ContactInformation;
      var contactVals = contactInfo ? contactInfo.val() : undefined;
      if (contactVals) {
        var values = this.mapValues(contactVals);

        // if no country value and there is a Country field, fill it
        if (!_.isEmpty(values) && !values.ShippingCountry && this.subviews.ShippingCountry) {
          values.ShippingCountry = 'US';
        }
        return _.isEmpty(values) ? undefined : values;
      }
    }

    return undefined;
  },
  errors: function () {
    var isShippingInfoVisible = this.$('.at-shipping-info').is(':visible');
    if (!isShippingInfoVisible) {
      return [];
    }
    return FieldsetView.prototype.errors.call(this);
  },
  renderFeedback: function (e) {
    if (e) {
      e.stopPropagation();
    }
    var isShippingInfoVisible = this.$('.at-shipping-info').is(':visible');
    if (!isShippingInfoVisible) {
      return [];
    }
    return FieldsetView.prototype.renderFeedback.call(this);
  },
  cycleOptions: function (e) {
    RadiosView.prototype.cycleOptions.call(this, e);
  },
  mapValues: function (values, prefix) {
    var prefixMapping = prefix || '';
    var shippingVals = _.reduce(this.subviews, function (memo, val) {
      var base = val.name.replace('Shipping', '');
      var key = prefixMapping + base;
      //  special case for recipient info since there's only streetaddress
      // for recipient info, but contact and shipping info have address line 1 and line 2
      if (prefixMapping === 'Recipient' && base === 'AddressLine1') {
        key = 'RecipientStreetAddress';
      }
      var value = values[key];

      if (value) {
        memo[val.name] = value;
      }
      return memo;
    }, {});

    // shipping info doesn't have an address line 2 field, so to avoid it getting dropped
    // just append the line 2 to the line 1 value.
    if (values.AddressLine2 && shippingVals.ShippingAddressLine1) {
      shippingVals.ShippingAddressLine1 += ' ' + values.AddressLine2;
    }

    return shippingVals;
  },
  closeCta: function () {
    this.$('.at-shipping-cta').hide();
  },
  autoFillAddress: function () {
    var hasSomeValues = this.getRecipientInformationSection().val();
    var mappedValues = this.mapValues(hasSomeValues, 'Recipient');
    this.setval(mappedValues);
    this.closeCta();
    this.clearFeedback();
    return this;
  },
  onGiftChanged: function (giftInfo) {
    if (!giftInfo.newGift || giftInfo.newGift.no_gift) {
      this.hide = true;
      this.$el.hide();
      return;
    } else {
      this.giftName = giftInfo.newGift.title || 'gift';
      this.hide = false;
      this.$el.show();
      this.render();
    }
  },
  getSelectedGift: function () {
    var premiums = this.options.formview.subviews.Premiums;
    if (premiums) {
      var gifts = premiums.subviews.PremiumGift;
      if (gifts) {
        return gifts.getSelectedGift();
      }
    }
    return undefined;
  },
  getTributeGiftSection: function () {
    return this.options.formview.subviews.TributeGift;
  },
  getRecipientInformationSection: function () {
    return this.options.formview.subviews.RecipientInformation;
  },
  onDestinationChange: function (e) {
    var destination = e.currentTarget.value;
    if (destination === 'other') {
      // see if recipient info has stuff
      var recipientInfo = this.getRecipientInformationSection();
      if (recipientInfo) {
        var defaultValues = recipientInfo.getDefaultValues();
        var recipientInfoVals = recipientInfo.val();
        var changedValues = _.reduce(recipientInfoVals, function (memo, val, key) {
          var defaultValue = defaultValues[key];
          if (defaultValue !== val) {
            memo[key] = val;
          }
          return memo;
        }, {});
        var shippingCta = this.$('.at-shipping-cta > .cta-text');
        var mappedValues = this.mapValues(changedValues, 'Recipient');

        if (!_.isEmpty(mappedValues)) {
          var tributeGiftSection = this.getTributeGiftSection();
          var ctaText = this.resources.PrimaryResources.GiftMembershipRecipientInfoAutoFillPrompt;
          if (tributeGiftSection) {
            ctaText = this.resources.PrimaryResources.TributeGiftRecipientInfoAutoFillPrompt;
          }
          if (shippingCta) {
            shippingCta.text(ctaText);
          }
          this.$('.at-shipping-cta').show();
        } else {
          this.$('.at-shipping-cta').hide();
        }
      }

      this.displayShippingFields(true);
    } else {
      this.displayShippingFields(false);
    }
  },
  displayShippingFields: function (show) {
    var shippingInfo = this.$('.at-shipping-info');
    if (show) {
      shippingInfo.show();
    } else {
      shippingInfo.hide();
      this.clearFeedback();
    }
  },
  getCheckedDestination: function () {
    var checkedElem = $('input[name=destinations]:checked');
    if (checkedElem && checkedElem.length) {
      return checkedElem[0].value;
    }
    return undefined;
  }
});
