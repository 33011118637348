import FieldView from 'app/views/FieldView';
// Backbone was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'lib/backbone';

export default FieldView.extend({
  __name__: 'TicketHolderView',
  tagName: 'tr',
  type: 'ticket_holder',
  events: {
    'keyup input': 'changeName',
    'change input': 'changeName'
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    this.name = this.options.definition.name = 'TicketHolder' + this.cid;
  },
  changeName: function (e) {
    if (e) {
      e.stopPropagation();
    }
    var val = e.currentTarget.value,
      id = e.currentTarget.id;

    // Is this a first name?
    var isFirst = /first/i.test(id);
    this.model.set((isFirst ? 'first' : 'last'), val);

    if (isFirst) {
      this.renderFirstNameFeedback(this.firstNameError());
    } else {
      this.renderLastNameFeedback(this.lastNameError());
    }

    return this;
  },
  isRequired: function () {
    return this.model.attributes.required;
  },
  render: function () {
    var model = this.model.toJSON();
    model.level = model.level.toJSON();
    this.$el.html(this.template(model));
    return this;
  },
  field_name: function () {
    return 'TicketHolder';
  },
  firstNameError: function () {
    var errors = [];
    if (this.isRequired()) {
      var values = this.val();
      if (values && values.TicketId && !values.FirstName) {
        errors.push(this.toError('First Name Required'));
      }
    }
    return errors;
  },
  lastNameError: function () {
    var errors = [];
    if (this.isRequired()) {
      var values = this.val();
      if (values && values.TicketId && !values.LastName) {
        errors.push(this.toError('Last Name Required'));
      }
    }
    return errors;
  },
  errors: function () {
    var errors = [];
    var firstName = this.firstNameError();
    var lastName = this.lastNameError();
    errors = errors.concat(firstName);
    errors = errors.concat(lastName);
    this.renderFirstNameFeedback(firstName);
    this.renderLastNameFeedback(lastName);
    return errors;
  },
  renderFirstNameFeedback: function (errors) {
    var values = this.val();
    var errorMsg = this.$('label.ticket-guest-first-label small.error');
    if (errorMsg) {
      errorMsg.remove();
    }
    var $label = this.$('label.ticket-guest-first-label');
    if (!values.FirstName && errors.length) {
      $label.addClass('error');
      $label.append('<small class="error">First Name is required.</small>');
    } else {
      $label.removeClass('error');
    }
  },
  renderLastNameFeedback: function (errors) {
    var values = this.val();
    var errorMsg = this.$('label.ticket-guest-last-label small.error');
    if (errorMsg) {
      errorMsg.remove();
    }
    var $label = this.$('label.ticket-guest-last-label');
    if (!values.LastName && errors.length) {
      $label.addClass('error');
      $label.append('<small class="error">Last Name is required.</small>');
    } else {
      $label.removeClass('error');
    }
  },
  getTicketId: function () {
    return this.model &&
      this.model.attributes &&
      this.model.attributes.level ?
      this.model.attributes.level.id :
      null;
  },
  val: function () {
    return {
      'FirstName': this.$('input.ticket-guest-first').val(),
      'LastName': this.$('input.ticket-guest-last').val(),
      'TicketId': this.getTicketId()
    };
  },
  setval: function (val) { // jshint ignore:line
    // TODO: make this work! Not necessary at first, will be for failed network latent
    // form submission. If this isn't set, the names will be emptied if the request
    // to Oberon fails for any reason.
    return this;
  }
});
