import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import TextFieldView from 'app/views/TextFieldView';
import loadScript from 'app/loadScript';
import loadCss from 'app/loadCSS';
import assetUrl from 'app/assetUrl';
import _ from 'lib/lodash.custom';

var utilsUrl = assetUrl('js/intl-tel.input.utils.js');
var intlInputUrl = assetUrl('js/intlTelInput.js');
var inputScriptPromise;

function loadUtils() {
  // don't load utils twice
  if (window.intlTelInputUtils) {
    return;
  }

  loadScript(utilsUrl);
}

function loadIntlTelInputJs() {
  if (!window.intlTelInput) {
    return loadScript(intlInputUrl);
  }
  return Backbone.$.Deferred().resolve().promise();
}

export default TextFieldView.extend({
  __name__: 'InternationalPhoneView',
  tagName: 'label',
  type: 'international_phone',
  events: {
    'change': 'renderFeedback'
  },
  className: function () {
    return 'at-text   ' + this.options.definition.name;
  },
  multiple_fields: function () {
    return [this.name, this.name + 'CountryCode'];
  },
  initialize: function () {
    // for now, forms only has us clients, so the database code will be us.
    // if/when non-us clients get digital the database country will need to be fetched dynamically
    this.databaseCountryCode = 'us';

    // load CSS file
    loadCss(assetUrl('styles/intlTelInput.min.css'));

    TextFieldView.prototype.initialize.call(this);
    this.controlClass = '.intl-phone-' + this.name;

    // jquery 2.1 (currently checked into lib/) has a bug with modern browsers: if jquery loads after
    // DOMContentLoaded has already fired but when document.readyState === 'interactive', jquery.ready()
    // mistakenly thinks the window is not ready and won't consider it ready until DOMWindow.load() fires.
    // By the time DOMWindow.load() fires, we're too late to load the international phone input because the form is rendered.
    // This might be a problem if client code adds at.js to the DOM dynamically, after the DOM has loaded.
    // The bug is fixed in jquery 4, but for now let's only do jquery.ready() if document.readyState === 'loading'
    // https://github.com/jquery/jquery/commit/cf84696fd1d7fe314a11492606529b5a658ee9e3#diff-84f52c7e1e0a2b29be9419125fed268171c4a375e09f7ce5601a49e11e9cd5afR69
    if (document.readyState !== 'loading') {
      inputScriptPromise = loadIntlTelInputJs();
      loadUtils();
    } else {
      Backbone.$().ready(function () {
        inputScriptPromise = loadIntlTelInputJs();
        loadUtils();
      });
    }
  },
  context: function () {
    var context = {
      name: this.name,
      label: this.def.label || this.title,
      title: this.title,
      required: this.def.required && 'required',
      value: this.def.default_value || '',
      type: this.def.type,
      labelhide: this.options.labels === 'placeholder'
    };
    if (context.labelhide) {
      context.placeholder = 'placeholder="' + _.escape(this.title) + (context.required ? '*' : '') + '"';
    }
    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  _initializeIntlTelInstance: function () {
    // if the textbox has a value from a prefill, save that
    var prefilledValue = Backbone.$(this.controlClass).val();
    var context = this.context();
    var metadata = this.options.formview.options.form_definition.metadata;
    //object {'iso_code': 'display_name'} formatted for lib to use for translations
    var countries = (this.def.options || []).reduce(function (obj, item) {
      obj[item.value.toLowerCase()] = item.display;
      return obj;
    }, {});
    this.$el.html(this.template(context));
    this.$input = this.$el.find(this.controlClass);
    this.intlTelInstance = window.intlTelInput(this.$input[0], {
      initialCountry: this.databaseCountryCode,
      nationalMode: true,
      numberType: 'MOBILE',
      preferredCountries: (metadata && metadata.languageCode) ? [] : ['us', 'gb', 'ca'],
      dropdownContainer: document.body,
      autoPlaceholder: 'off',
      originalCountryCode: this.databaseCountryCode,
      localizedCountries: countries,
      onlyCountries: Object.keys(countries)
    });

    // map default values from smartlinks, etc to control
    this.$input.val(prefilledValue || this.def.default_value || '');
    this.intlTelInstance.setCountry(this.def.default_country_code || this.databaseCountryCode);

    this.$input.on('countrychange', this.renderFeedback);
  },
  render: function () {
    var context = this.context();
    this.$el.html(this.template(context));
    var self = this;
    if (inputScriptPromise) {
      inputScriptPromise.then(function () { self._initializeIntlTelInstance(); });
    }
    return this;
  },
  renderFeedback: function () {
    if (!this.intlTelInstance) {
      return TextFieldView.prototype.renderFeedback.call(this);
    }
    if (!window.intlTelInputUtils) {
      return this.renderFeedbackWithErrors(this.errors());
    }
    var baseNumber = this.getNumberOnly();
    if (baseNumber) {
      var countryCode = this.intlTelInstance.getSelectedCountryData().iso2;
      if (this.name === 'WorkPhone' && this.intlTelInstance.getExtension()) {
        var ext = this.intlTelInstance.getExtension();

        // validate and display base number as you would without an extension, then tack the extension back on at the end
        var formattedNum = window.intlTelInputUtils.formatNumber(baseNumber, countryCode, window.intlTelInputUtils.numberFormat.NATIONAL);
        this.$input.val(formattedNum + ' x' + ext);

      } else if (this.intlTelInstance.isValidNumber()) {
        // causes the displayed number to get formatted since autoformat is not supported anymore
        // https://github.com/jackocnr/intl-tel-input/issues/346
        this.$input.val(window.intlTelInputUtils.formatNumber(baseNumber, countryCode, window.intlTelInputUtils.numberFormat.NATIONAL));
      }
    }
    return this.renderFeedbackWithErrors(this.errors());
  },
  errors: function () {
    if (!this.intlTelInstance || !window.intlTelInputUtils) {
      return [];
    }
    var errors = !this.require_valid() ? [this.resources.fill(this.resources.PrimaryResources.BlankIsRequired, this.title)] : [];
    if (this.getNumberOnly() && !this.intlTelInstance.isValidNumber()) {
      errors.push(this.resources.fill(this.resources.PrimaryResources.PleaseEnterValidBlank, this.title));
    }

    errors = _.map(invokeCallbacks('alterErrors', {
      val: this.getNumberOnly(),
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors, this.toError, this);
    return errors;
  },
  getFullNumber: function () {
    var number;
    if (window.intlTelInputUtils) {
      number = this.intlTelInstance.getNumber(window.intlTelInputUtils.numberFormat.NATIONAL);
    } else {
      number = Backbone.$(this.controlClass).val();
    }
    if (this.name === 'WorkPhone' && this.intlTelInstance.getExtension()) {
      number = number + ' x' + this.intlTelInstance.getExtension();
    }
    return number.replace(/\D/g, '');
  },
  getNumberOnly: function () {
    var number;
    if (window.intlTelInputUtils) {
      number = this.intlTelInstance.getNumber(window.intlTelInputUtils.numberFormat.NATIONAL);
      if (this.name === 'WorkPhone' && this.intlTelInstance.getExtension()) {
        var ext = this.intlTelInstance.getExtension();
        // get phone number without extension
        number = number.substr(0, number.length - ext.length);
      }
    } else {
      number = Backbone.$(this.controlClass).val();
    }
    return number.replace(/\D/g, '');
  },
  val: function () {
    if (!this.intlTelInstance) {
      return Backbone.$(this.controlClass).val();
    }
    var phoneType = this.context().name;
    var countryData = this.intlTelInstance.getSelectedCountryData();

    var toReturn = {};
    toReturn[phoneType] = this.getNumberOnly();
    if (!toReturn[phoneType]) {
      return null;
    }
    toReturn[phoneType + 'CountryCode'] = countryData.iso2;
    toReturn[phoneType + 'InternationalDialingPrefix'] = countryData.dialCode;
    if (phoneType === 'WorkPhone') {
      toReturn.WorkPhoneExtension = this.intlTelInstance.getExtension();
    }
    return toReturn;
  },
  setval: function (value) {
    var phoneType = this.context().name;
    if (!this.intlTelInstance) {
      Backbone.$(this.controlClass).val(value[phoneType]);
      return this.touch().hideOrShow();
    }
    var typeCountryCode = phoneType + 'CountryCode';
    if (_.isNull(value)) {
      return this;
    }
    if (!_.isNull(value[phoneType]) && !_.isUndefined(value[phoneType])) {
      this.$input.val(value[phoneType]);
    } else {
      this.$input.val('');
    }
    if (!_.isNull(value[typeCountryCode]) && !_.isUndefined(value[typeCountryCode])) {
      this.intlTelInstance.setCountry(value[typeCountryCode]);
    }
    return this.touch().hideOrShow();
  },
});
