import $ from 'lib/jquery.ui';

var regex = {
  trailingSlash: /\/+$/
};

function form() {
  return $('.ngp-form');
}

function url() {
  var u = form().data('fastactionEndpoint') || 'https://fastaction.ngpvan.com';
  return u.replace(regex.trailingSlash, '');
}

function profile() {
  return url() + '/api/v3/profile';
}

function profile_with_id(fastActionAccountId) {
  return url() + '/api/v3/profile/' + fastActionAccountId;
}

function profile_with_id_login(fastActionAccountId) {
  return url() + '/api/v3/profile/' + fastActionAccountId + '/log_in';
}

function forms() {
  return url() + '/api/v1/forms/';
}

export default {
  urls: {
    base: url,
    forms: forms,
    profile: profile,
    profile_with_id: profile_with_id,
    profile_with_id_login: profile_with_id_login
  }
};
