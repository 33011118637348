import Backbone from 'lib/backbone';
import moment from 'lib/moment';
import invokeCallbacks from 'app/NVTagCallbacks';
import FieldView from 'app/views/FieldView';
import FormError from 'app/FormError';
import deviceInfo from 'app/deviceInfo';
// formDefUtils was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'app/FormDefUtils';

var $ = Backbone.$,
  _ = Backbone._;

export default FieldView.extend({
  __name__: 'DateView',
  tagName: 'label',
  className: function () {
    return 'at-date   ' + this.options.definition.name.toLowerCase();
  },
  events: {
    'change': 'renderFeedback'
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    $('.ui-datepicker').remove();

    // this.resources.Datepicker is NOT available on mobile, since we don't use that jquery UI datepicker on mobile
    this.datepickerConfig = this.resources.Datepicker || {};

    var format = this.format = {
      // used by moment for the output value (returned by this.val())
      value: 'YYYY-MM-DD',
      // The display should be consistent (but not the same) with what the Datepicker uses so the value isn't immediately considered invalid
      // however, moment uses MM, DD, and YYYY as you would expect, while the date picker lib uses mm, dd and yy
      // datepicker docs: https://api.jqueryui.com/datepicker/
      // This format MUST have 10 characters (based on how the value is validated within this.val())
      display: (this.datepickerConfig.MOMENT_FORMAT || 'MM/DD/YYYY').toUpperCase(), // used by moment to format & parse formatted output, must use M, D and Y chars
      valueFormat: function () {
        return deviceInfo.mobile ? format.value : format.display;
      }
    };

  },
  context: function () {
    var context = {
      name: this.name,
      title: this.title,
      placeholder: (this.resources.PrimaryResources.DateFormat ||
        this.datepickerConfig.MOMENT_FORMAT || 'MM/DD/YYYY').toUpperCase(),
      type: deviceInfo.mobile ? 'date' : 'text',
      labelhide: this.options.labels === 'placeholder',
      required: this.def.required ? 'required' : '',
      value: this.def.default_value ? moment(new Date(this.def.default_value)).utc().format(this.format.valueFormat()) : ''
    };

    if (context.labelhide && context.title) {
      context.placeholder = context.title;
    }
    if (this.name === 'EndDate') {
      context.labelhide = true;
      context.value = context.value || moment().utc().add('months', 1).format(this.format.valueFormat());
    }

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    this.$el.html(this.template(this.context()));
    this.$date = this.$('input');
    if (!deviceInfo.mobile) {
      // set latest possible date for datepicker
      var maxDate;
      if (this.name === 'DateOfBirth') {
        maxDate = 0;
      } else if (this.name === 'NotificationSendDate') {
        maxDate = '+1y';
      }
      // set earliest start date for datepicker
      var minDate;
      if (this.name === 'EndDate' || this.name === 'NotificationSendDate') {
        minDate = 0;
      }
      this.$date.datepicker('destroy');
      // Use localized date picker settings as the base configuration
      var settings = _.extend({}, this.datepickerConfig, {
        maxDate: maxDate,
        minDate: minDate,
        yearRange: '1900:2050',
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        showAnim: ''
      });
      this.$date.datepicker(settings);
    }
    return this;
  },
  errors: function () {
    var errors = [];
    var val = this.val();
    if (val === 'invalid') {
      errors.push(new FormError(this.resources.PrimaryResources.PleaseEnterValidDate, this));
    }
    return FieldView.prototype.errors.call(this, errors);
  },
  val: function () {
    var val = this.$date && this.$date.val();
    if (val) {
      var date = moment(val, this.format.valueFormat()).utc();
      return date.isValid() && val.length === 10 ? date.format(this.format.value) : 'invalid';
    } else {
      return null;
    }
  },
  setval: function (val) {
    var date = val || this.def.default_value;
    var value = date ? moment(new Date(date)).utc().format(this.format.valueFormat()) : '';
    if (!value && this.name === 'EndDate') {
      value = moment().utc().format(this.format.valueFormat());
    }
    this.$date.val(value);
    return this.touch();
  },
  type: 'date'
});
