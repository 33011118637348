import Backbone from 'lib/backbone';
import FieldView from 'app/views/FieldView';

var _ = Backbone._;
var $ = Backbone.$;
export default FieldView.extend({
  __name__: 'TargetsView',
  className: 'at-targets clearfix',
  type: 'targets',
  events: {
    'click a.at-show-targets': 'showAll',
    'click a.at-hide-targets': 'hideRest'
  },
  context: function contextTargets() {
    this.withImages = [];
    var context = _.reduce(this.def.targets, function (memo, target, idx) {
      target.idx = idx;
      if (target.img && memo.head.length < 3) {
        memo.head.push(target);
      } else {
        if (target.img) {
          this.withImages.push(target);
        }
        memo.rest.push(target);
      }
      return memo;
    }, { head: [], rest: [] }, this);

    context.hasImages = !!context.head.length;

    if (!context.hasImages) {
      var take = Math.min(context.rest.length, 3);
      while (take--) {
        context.head.push(context.rest.shift());
      }
    }

    return context;
  },
  render: function renderTargets() {
    var self = this;
    var ctx = this.context();
    this.$el
      .html(this.template(ctx))
      .find('img')
      .one('error', function () {
        if (self.withImages.length) {
          var idx = $(this).data('idx');
          self.def.targets[idx].img = null;
          self.render();
        }
      });
    return this;
  },
  showAll: function showAllTargets(e) {
    e.preventDefault();
    this.$(e.currentTarget)
      .hide()
      .parent()
      .find('.at-targets-rest')
      .show()
      .find('a.at-hide-targets')
      .focus();
  },
  hideRest: function hideRestTargets(e) {
    e.preventDefault();
    this.$(e.currentTarget)
      .parent()
      .hide()
      .parent()
      .find('a.at-show-targets')
      .show()
      .focus();
  }
});
