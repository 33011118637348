import ActionTagConfig from 'config/actionTagConfig';

function getPath(baseUrl, path) {
  path = path || '';
  if (path && path.indexOf('/') !== 0) {
    path = '/' + path;
  }
  return baseUrl + path;
}

function getAssetUrl(path) {
  return getPath(ActionTagConfig.ASSET_BASE_URL, path);
}

// special case for zip lookups since sometimes they aren't in the same spot
getAssetUrl.zip = function getZipAssetUrl(path) {
  return getPath(ActionTagConfig.ZIP_LOOKUP_BASE_URL, path);
};

export default getAssetUrl;
