import Backbone from 'lib/backbone';
import FieldsetView from 'app/views/FieldsetView';

var $ = Backbone.$;
var _ = Backbone._;
export default FieldsetView.extend({
  __name__: 'AdvocacyTweetsView',
  type: 'advocacy_tweets',
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);
    this.title = this.def.title;
    this.template = this.options.templates.fieldset;
    this.def.children = _.reduce(this.def.messages, function (children, def) {
      var child = _.extend({ type: 'advocacy_tweet' }, def);
      children.push(child);

      return children;
    }, []);
  },
  _onSubviewsRendered: function () {
    var self = this;
    var block = this.parent.$('.at-form-submit');
    block.append('<a href="' + this.def.back.href + '" tabindex="-1" class="back-link at-submit btn-at btn-at-link">' + this.def.back.text + '</a>');
    var submit = block.find('input[type="submit"]');
    submit.val('Continue');

    // disable button until a tweet window is opened and closed
    submit.prop('disabled', true);
    submit.prop('title', 'Post a Tweet to continue');
    submit.css('pointer-events', 'auto'); // enables tooltip on disabled button

    // attach listeners to subviews
    _.each(self.subviews, function (subview) {
      self.listenTo(subview, 'tweetOpened', self.tweetOpened);
    });

    //force focus to the step section of page
    $('html,body').scrollTop(this.parent.$('form').offset().top);
  },
  tweetOpened: function (tweet) {
    var self = this;
    $.ajax({
      method: 'POST',
      xhrFields: { withCredentials: true },
      url: this.def.reportUrl,
      data: _.extend({}, tweet)
    }).always(function () {
      // enable continue button
      var block = self.parent.$('.at-form-submit');
      var submit = block.find('input[type="submit"]');
      submit.prop('disabled', false);
      submit.prop('title', 'Continue');
    });
  }
});
