// Helper to manage the "alias" concept across ActionTag
// a form element can have a "alias" property, linking it to a 'source of truth' form element name
// this 'source of truth' (aka 'primary' below) name will be used as:
// - the submission key
// - the fill key
// also, if the 'source of truth' form element is actually present, the two will be kept in sync
// -- intention is to have one version for org mode and the other for person mode.
// -- currently (5/26/21) the values are only synced when the contact mode changes

import _ from 'lib/lodash.custom';
import FormDefUtils from 'app/FormDefUtils';

// fields that need to have value updates deferred to handle competing operations
function _isDeferredFieldName(name) {
  return name.indexOf('StateProvince') >= 0;
}

function extractAliasMapping(def) {

  var allSecondaries = [];
  var aliases = _.reduce(FormDefUtils.fields(def.form_elements), function (memo, field) {
    // construct alias lookup, mapping primary field -> aliased fields
    if (field.alias) {
      memo[field.alias] = memo[field.alias] || [];
      if (memo[field.alias].indexOf(field.name) < 0) {
        memo[field.alias].push(field.name);
        allSecondaries.push(field.name);
      }
    }
    return memo;
  }, {});

  var primaryAliases = _.keys(aliases);
  // validate alias mapping -- primary alias keys cannot be members of alias lists
  _.forEach(primaryAliases, function (aliasKey) {
    if (allSecondaries.indexOf(aliasKey) >= 0) {
      console.error('removing invalid alias list', aliasKey);
      delete aliases[aliasKey];
    }
  });

  var secondaryToPrimary = _.reduce(_.keys(aliases), function (memo, primary) {
    var secondarys = aliases[primary];
    _.forEach(secondarys, function (secondary) {
      memo[secondary] = primary;
    });
    return memo;
  }, {});

  var aliasGroups = [];
  var aliasGroupsDeferred = [];
  _.forOwn(aliases, function (secondarys, primary) {
    var all = [].concat(secondarys);
    all.push(primary);

    if (_.any(all, _isDeferredFieldName)) {
      // weird fields that need to be synced in a deferred way
      aliasGroupsDeferred.push(all);
    } else {
      aliasGroups.push(all);
    }
  });

  return {
    // lookup from Primary name -> array of secondary names
    primaryToSecondary: aliases,

    // lookup secondary name -> primary name
    secondaryToPrimary: secondaryToPrimary,

    // array of arrays comprised of names that are aliased together
    aliasGroups: aliasGroups,
    aliasGroupsDeferred: aliasGroupsDeferred
  };
}

function _updateFieldValues(activeField, allFields) {
  var activeValue = activeField.val();
  _.forEach(allFields, function (field) {
    if (field.name !== activeField.name) {
      field.setval(activeValue);
    }
  });
}

// sync up fields
// when deferred, have to determine the active field immediately, but defer updating the fields
// so we have to regrab fields in case they have changed under the hood (like StateProvince changing to/from textfield)
function _syncAliasFieldGroup(group, formview, nocache, defer) {
  var getFields = function getFields() {
    return _.map(group, function (name) {
      return nocache ? formview.getFieldNoCache(name) : formview.getField(name);
    });
  };
  var allFields = getFields();
  var activeField = _.find(allFields, function (field) {
    return field && field.isActive();
  });
  if (activeField) {
    if (defer) {
      _.defer(function () {
        allFields = getFields();
        _updateFieldValues(activeField, allFields);
      });
    } else {
      _updateFieldValues(activeField, allFields);
    }
  }
}

function syncAliasFields(aliases, formview) {
  _.forEach(aliases.aliasGroups, function (all) {
    _syncAliasFieldGroup(all, formview, false);
  });
  // process deferred fields slightly different
  _.forEach(aliases.aliasGroupsDeferred, function (all) {
    _syncAliasFieldGroup(all, formview, true, true);
  });
}

function fixAliasFillValues(aliases, fillDict) {
  _.forOwn(aliases.primaryToSecondary, function (secondarys, primary) {
    if (!_.has(fillDict, primary)) {
      return; // only force a fill value if the primary key is present
    }
    var primaryValue = fillDict[primary];
    _.forEach(secondarys, function (secondary) {
      fillDict[secondary] = primaryValue;
    });
  });
}

function fixAliasSubmissionValues(aliases, valueDict) {
  var nameLookup = aliases.secondaryToPrimary;
  _.forEach(_.keys(valueDict), function (valueKey) {
    var mapping = nameLookup[valueKey];
    if (mapping) {
      // map value from secondary key to primary
      valueDict[mapping] = valueDict[valueKey];

      // delete the secondary key from data dict
      delete valueDict[valueKey];
    }
  });
}

export default {
  extractAliasMapping: extractAliasMapping,
  syncAliasFields: syncAliasFields,
  fixAliasSubmissionValues: fixAliasSubmissionValues,
  fixAliasFillValues: fixAliasFillValues
};
