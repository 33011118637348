import Backbone from 'lib/backbone';
import doublebracket from 'app/DoubleBracket';
import MarkupView from 'app/views/MarkupView';
// formDefUtils was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'app/FormDefUtils';

var _ = Backbone._;
export default MarkupView.extend({
  __name__: 'NotMeView',
  events: { 'click [href="?nf=1"]': 'notMe' },
  isRequired: function () {
    return !!(this.options.formview.options.form_definition.metadata.hideCompletedFormSections
      && !this.options.formview.isFirstForm());
  },
  initialize: function () {
    MarkupView.prototype.initialize.call(this);
    this.touch_state = false;
    this.template = this.options.templates[this.type];
    this.def = this.options.definition;
    this.name = _.result(this, 'name') || this.def.name || this.options.name;
    this.title = _.result(this, 'title') || this.def.title || this.options.title;
  },
  render: function () {
    // Don't show not me when:
    // It's explicitly turned off by this view
    // If a value of false is passed to the render function, hide it.
    this.$el.html(doublebracket.translate(this.options.definition.markup));
    if (this.isRequired() && !this.options.formview.options.clickedNotMe) {
      this.$el.show();
    } else {
      if (this.parent.context().children.length === 1) {
        this.parent.remove();
      } else {
        this.$el.hide();
      }
    }
    return this;
  },
  notMe: function (e) {
    e.preventDefault();
    var form = this.options.formview;
    form.options.smart = false;
    form.options.showNotMe = false;
    form.options.clickedNotMe = true;
    form.render().clear().options.parent_tag.bringIntoView();
  }
});
