import Backbone from 'lib/backbone';
import FieldsetView from 'app/views/FieldsetView';
import invokeCallbacks from 'app/NVTagCallbacks';
import formatRows from 'app/views/helpers/formatRows';

var _ = Backbone._;

export default FieldsetView.extend({
  __name__: 'RecipientInformationView',
  type: 'recipient_information',
  events: {
    'click [name="IncludeRecipient"]': 'toggleRecipientInfo',
    'click [name="NotifyRecipient"]': 'toggleNotifyRecipient'
  },
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);

    // get show/hide default values
    var includeRecipientElem = this.getIncludeRecipientElemFromDef();
    this._includeRecipient = (!includeRecipientElem || includeRecipientElem.default_value
      || includeRecipientElem.value);

    var notifyRecipientElem = this.getNotifyRecipientElemFromDef();
    this._notifyRecipient = (!notifyRecipientElem || notifyRecipientElem.default_value
      || notifyRecipientElem.value);

    // listen to the fill event
    this.listenTo(this.options.parent, 'fill', this.postFill);
    this.template = this.options.templates.recipient_information;
  },
  context: function () {
    var context = {
      children: [],
      title: this.def.title,
      id: this.name.toLowerCase(),
      notificationFields: []
    };
    var includeRecipientElem = this.getIncludeRecipientElemFromDef();
    var notificationFields = this.getNotificationFieldsFromDef();
    var recipientContactInfo = _.without(this.def.children, includeRecipientElem);

    // make this the first element in the fieldset
    context.includeRecipient = { type: includeRecipientElem.type, index: 0, name: includeRecipientElem.name };

    // set up the recipient contact info into the rows format
    _.each(recipientContactInfo, function (element, index) {
      // separate out the notification information field so that
      // they will be controlled by the notify recipient checkbox
      if (_.contains(notificationFields, element)) {
        context.notificationFields.push({ type: element.type, index: index + 1, name: element.name });
      }
      else {
        context.children.push({ type: element.type, index: index + 1, name: element.name });
      }
    });

    var children = context.children.concat(context.notificationFields, context.includeRecipient);
    context.rows = formatRows.format('RecipientInformation', children);

    return invokeCallbacks('alterContext', { element: 'fieldset', context: context, def: this.def }).context;
  },
  render: function () {
    this.$el.html(this.template(this.context()));
    this.displayRecipientInfo(this._includeRecipient);
    this.displayRecipientMsg(this._includeRecipient && this._notifyRecipient);
  },
  errors: function () {
    // if we're hidden, ignore errors
    var recipientInfoVisible = this.$('.at-recipient-info').is(':visible');
    var recipientMsgVisible = this.$('.at-recipient-msg').is(':visible');

    // if info area is not visible ignore all errors
    if (!recipientInfoVisible) {
      return [];
    }

    var errors = [];
    // if it is visible and only the msg part is invisible, return all the errors except the ones for the
    // notifcation message
    if (!recipientMsgVisible) {
      errors = _(this.subviews)
        .reduce(function (errors, subview) {
          if (subview.name !== 'NotificationMessage') {
            errors.push(_.invoke([subview], 'errors'));
          }
          return errors;
        }, []);

      _(errors).flatten().compact().value();
    }
    else {
      errors = _(this.subviews).chain().invoke('errors').flatten().compact().value();
    }

    return invokeCallbacks('alterError',
      { val: this.val(), field_name: this.field_name(), errors: errors, def: this.def }).errors;
  },
  renderFeedback: function (e) {
    if (e) {
      e.stopPropagation();
    }
    // if we're hidden, ignore errors
    var recipientInfoVisible = this.$('.at-recipient-info').is(':visible');
    var recipientMsgVisible = this.$('.at-recipient-msg').is(':visible');

    // if info area is not visible ignore all errors
    if (!recipientInfoVisible) {
      return [];
    }

    // if it is visible and only the msg part is invisible, return all the errors except the ones for the
    // notifcation message
    if (!recipientMsgVisible) {
      var errors = _(this.subviews)
        .reduce(function (errors, subview) {
          if (subview.name !== 'NotificationMessage') {
            errors.push(_.invoke([subview], 'renderFeedback'));
          }
          return errors;
        }, []);

      return _(errors).flatten().compact().value();
    }
    return _(this.subviews).invoke('renderFeedback').flatten().compact().value();
  },
  displayRecipientInfo: function (show) {
    if (show) {
      this.$('.at-recipient-info').show();
      this.displayRecipientMsg(this._notifyRecipient);
    } else {
      this.$('.at-recipient-info').hide();
      this.displayRecipientMsg(false);
      this.clearFeedback();
    }
  },
  displayRecipientMsg: function (show) {
    if (show) {
      this.$('.at-recipient-msg').show();
    } else {
      this.$('.at-recipient-msg').hide();
      this.clearFeedback();
    }
  },
  toggleRecipientInfo: function (event) {
    this.displayRecipientInfo(event.currentTarget.checked);
  },
  toggleNotifyRecipient: function (event) {
    this.displayRecipientMsg(event.currentTarget.checked);
  },
  postFill: function (args) {
    // check if the element is in the formdef.
    // if it isn't ignore these values
    if (this.getIncludeRecipientElemFromDef()) {
      this._includeRecipient = (args && args.IncludeRecipient);
      this.displayRecipientInfo(this._includeRecipient);
    }

    if (this.getNotifyRecipientElemFromDef()) {
      this._notifyRecipient = (args && args.NotifyRecipient);
      this.displayRecipientMsg(this._notifyRecipient);
    }
  },
  getIncludeRecipientElemFromDef: function () {
    return _.find(this.def.children, { name: 'IncludeRecipient' });
  },
  getNotifyRecipientElemFromDef: function () {
    return _.find(this.def.children, { name: 'NotifyRecipient' });
  },
  getNotificationFieldsFromDef: function () {
    return _.filter(this.def.children, function (child) {
      return (child.name === 'NotificationHeaderHtml'
        || child.name === 'NotificationMessage'
        || child.name === 'NotificationSendDate'
        || child.name === 'Ecard'
        || child.name === 'NotificationSendCopy');
    });
  },
  shouldHide: function () {
    // We show the recipient information section if either the EnableTributeGift input
    // or the form definition 'IncludeRecipient' child indicate to do so
    // The former is used for tributes, the latter for gift memberships
    var tgElem = this.parent.$('input[name="EnableTributeGift"]:checked');
    var includeRecipientElem = this.getIncludeRecipientElemFromDef();
    var includeRecipientValue = includeRecipientElem ? includeRecipientElem.default_value || includeRecipientElem.value : false;
    return !(includeRecipientValue || tgElem.val() === 'on');
  },
  getDefaultValues: function () {
    return _.reduce(this.def.children, function (memo, val) {
      memo[val.name] = val.default_value;
      return memo;
    }, {});
  }
});
