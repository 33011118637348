var autocompletes = {
  AdditionalContributionValue: 'transaction-amount',
  Prefix: 'honorific-prefix',
  FirstName: 'given-name',
  MiddleName: 'additional-name',
  LastName: 'family-name',
  Suffix: 'honorific-suffix',
  AddressLine1: 'address-line1',
  AddressLine2: 'address-line2',
  StateProvince: 'address-level1',
  City: 'address-level2',
  PostalCode: 'postal-code',
  Country: 'country-name',
  EmailAddress: 'email',
  HomePhone: 'tel-national',
  Employer: 'organization',
  Occupation: 'organization-title',
  WorkAddressLine1: 'work address-line1',
  WorkAddressLine2: 'work address-line2',
  WorkStateProvince: 'work address-level1',
  WorkCity: 'work address-level2',
  WorkPostalCode: 'work postal-code',
  WorkCountry: 'work country-name',
  WorkEmail: 'work email',
  WorkPhone: 'work tel-national',
  MobilePhone: 'mobile tel-national'
};
var xautocompletes = {
  Prefix: 'name-prefix',
  FirstName: 'given-name',
  MiddleName: 'middle-name',
  LastName: 'surname',
  Suffix: 'name-suffix',
  AddressLine1: 'address-line1',
  AddressLine2: 'address-line2',
  StateProvince: 'administrative-area',
  City: 'locality',
  PostalCode: 'postal-code',
  Country: 'country',
  EmailAddress: 'email',
  HomePhone: 'phone-national',
  Employer: 'organization'
};
export default function autocompleteAttr(name) {
  var attr = 'autocomplete="on"';
  if (name && autocompletes[name]) {
    attr = 'autocomplete="' + autocompletes[name] + '"';
    if (xautocompletes[name]) {
      attr += ' x-autocompletetype="' + xautocompletes[name] + '"';
    }
  }
  return attr;
}
