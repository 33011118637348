// Altered Q1 2024 to use ES Modules, VAN-118309

var props = [
  'protocol', 'host', 'port', 'pathname', 'search',
  'baseURI', 'hostname', 'href', 'hash', 'origin'
], regex = {
  'plus': /\+/g,
  'true': /^true$/i,
  'bool': /^(true|false)$/i,
  'https': /^https:?$/,
  'query': /(?:^|&)([^&=]*)=?([^&]*)/g
};
function decode(s){
  return s ? decodeURIComponent(s).replace(regex['plus'], ' ') : null;
}
export default function parseUrl(href){
  var url = {}, a = document.createElement('a');
  a.href = href;
  for (var i = props.length - 1; i >= 0; i--) {
    url[props[i]] = a[props[i]] || '';
  }
  url._        = a;
  url.ssl      = regex['https'].test(url.protocol);
  url.host     = url.host || url.port ? url.hostname+':'+url.port : url.hostname;
  url.origin   = url.origin || url.protocol+'//'+url.host;
  url.pathname = url.pathname[0] === '/' ? url.pathname : '/'+url.pathname;
  url.toString = url.toJSON = url.valueOf = function(){ return url._.toString(); };
  if (url.search && url.search.length > 1) {
    url.query = {};
    url.search.substr(1).replace(regex['query'], function ($0, $1, $2){
      if ($1) {
        var key = decode($1);
        var val = decode($2);
        if (regex['bool'].test(val)) {
          val = regex['true'].test(val);
        }
        url.query[key] = val;
      }
    })
  }
  return url;
}
