import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import { accounting } from 'lib/accounting';
import DoubleBracket from 'app/DoubleBracket';
import FieldsetView from 'app/views/FieldsetView';

var _ = Backbone._;

// Ticket Holder Model and Collection
var TicketHolder = Backbone.Model.extend({
  __name__: 'TicketHolder',
  initialize: function () {
    this.attributes.cid = this.cid;
  },
  defaults: {
    'first': '',
    'last': '',
    'required': false
  },
  oberon_format: function () {
    return {
      'FirstName': this.get('first'),
      'LastName': this.get('last'),
      'TicketId': this.get('level').get('id')
    };
  }
});

var TicketHolders = Backbone.Collection.extend({
  __name__: 'TicketHolders',
  model: TicketHolder,
  comparator: function (holder) {
    return holder.get('level').get('id');
  },
  holders_by_level: function (level_id) {
    return this.filter(function (holder) {
      return holder.get('level').get('id') === level_id;
    });
  }
});

// Ticket Level Model and Collection
var TicketLevel = Backbone.Model.extend({
  __name__: 'TicketLevel',
  initialize: function () {
    this.attributes.cid = this.cid;
    var option_levels = _(this.get('quantity')).range().map(toLevelOptions).value();
    if (option_levels.length > 0) {
      option_levels.unshift({ 'display': '- Quantity -', 'value': '' });
    }
    this.set('levels', option_levels);
  },
  defaults: {
    'levels': [],
    'title': 'General Admission',
    'quantity': 25,
    'price': 0,
    'default_value': 0
  }
});

var TicketLevels = Backbone.Collection.extend({
  __name__: 'TicketLevels',
  model: TicketLevel
});

// Functions for map and reduce
function toLevelOptions(n) {
  return { 'display': n + 1, 'value': n + 1 };
}

function toLevelTotal(memo, holder) {
  return memo + holder.get('level').get('price');
}

function toInvertedTickets(key, val) {
  return ['Ticket_' + val, key];
}

// Ticketing View
export default FieldsetView.extend({
  __name__: 'TicketingView',
  type: 'ticketing',
  className: 'at-fieldset TicketInformation',
  onMessages: { 'changeQuantityOrAdditional': 'changeQuantityOrAdditional' },
  field_name: function () {
    return 'EventTicketing';
  },
  initialize: function () {
    FieldsetView.prototype.initialize.call(this);
    this.originalDef = _.clone(this.def);
    _.bindAll(this, 'updateStats', 'showHideTotal');

    this.ticket_levels = new TicketLevels(this.options.ticket_levels);
    this.ticket_holders = new TicketHolders();
    for (var i = 0; i < this.ticket_levels.length; i++) {
      for (var j = 0; j < this.ticket_levels.at(i).attributes.default_value; j++) {
        this.ticket_holders.add({ required: this.options.requireTicketHolders, level: this.ticket_levels.at(i).clone() });
      }
    }
    this.updateStats();

    // hack used to persist these values across re-renders
    // since every ticket level change results in a re-render
    this.rerenderState = {
      host: null,
      additional: null,
      coverCosts: null
    };

    var children = _(this.def.children);
    var header = children.find({ 'name': 'TicketHeaderHtml' });
    var footer = children.find({ 'name': 'TicketFooterHtml' });
    this.def.context = {
      header: header && header.markup ? DoubleBracket.translate(header.markup) : '',
      additional: children.findIndex({ 'name': 'AdditionalContributionValue' }),
      host: children.findIndex({ 'name': 'HostCommittee' }),
      footer: footer && footer.markup ? DoubleBracket.translate(footer.markup) : '',
      coverCosts: children.findIndex({ 'name': 'CoverCostsAmount' })
    };
  },
  events: {
    'change *': 'touch',
    'blur input[name^="AdditionalContribution"]': 'formatAdditionalContribution',
    'change input[name^="CoverCostsAmount"]': 'showHideTotal',
    'keyup input[name^="AdditionalContribution"]': 'changeAdditionalContribution',
    'keypress input[name^="AdditionalContribution"]': 'ensureTotalBeforeSubmit'
  },
  showHideTotal: function () {
    var show = !this.coverCostsView || !this.coverCostsView.val();
    this.$el.find('.ticketTotals .totalAmount').toggle(show);
  },
  formatAdditionalContribution: function (e) {
    if (e.currentTarget.value) {
      e.currentTarget.value = accounting.formatMoney(e.currentTarget.value);
    }
    this.renderAdditionalContributionFeedback();
    return this;
  },
  changeAdditionalContribution: _.debounce(function () {
    this.updateStats();
    this.$total.html(accounting.formatMoney(this.stats.total));
    return this;
  }, 150),
  ensureTotalBeforeSubmit: function (e) {
    if (e.which !== 13) {
      return;
    }
    return this.changeAdditionalContribution(e).formatAdditionalContribution(e);
  },
  changeQuantityOrAdditional: function (e) { // jshint ignore:line
    this.updateStats();
    if (document.activeElement) {
      var activeId = document.activeElement.id;
      if (activeId && this.$('#' + activeId).length) {
        this.render();
        this.$('#' + activeId).focus();
        return this;
      }
    }
    this.render();
  },
  context: function () {
    var context = _.omit({
      name: this.name,
      title: this.title,
      stats: this.stats,
      host: this.def.context.host,
      header: this.def.context.header,
      footer: this.def.context.footer,
      coverCosts: this.def.context.coverCosts,
      additional: this.def.context.additional,
      ticket_levels: this.ticket_levels.toJSON(),
      ticket_holders: this.ticket_holders.toJSON(),
      showTicketHolders: this.options.showTicketHolders && this.ticket_holders.length,
      optionalTicketHolders: !this.options.requireTicketHolders,
      guest_names: this.options.formview.model.get('metadata').guest_names || 'Guest Names'
    }, function (value) {
      return value === -1;
    });

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    if (this.subviews) {

      // Since changing the ticket amounts causes a re-render, we need to grab all of the values of each subview
      // before we re-render, so we can reassign the values (within _onSubviewsRendered)
      if (this.hostView) {
        this.rerenderState.host = this.hostView.val();
      }
      if (this.additionalView) {
        this.rerenderState.additional = this.additionalView.val();
      }
      if (this.coverCostsView) {
        this.rerenderState.coverCosts = this.coverCostsView.isChecked();
      }

      //Prevent this Ticket Holder sub views from spawning out of control
      // the template construction below will dup them
      for (var prop in this.subviews) {
        if (prop.indexOf('TicketHolder') >= 0) {
          delete this.subviews[prop];
        }
      }
    }

    this.$el.html(this.template(this.context()));
    return this;
  },
  _onSubviewsRendered: function () {
    this.additionalView = this.subviews.AdditionalContributionValue;
    this.hostView = this.subviews.HostCommittee;
    this.coverCostsView = this.subviews.CoverCostsAmount;
    this.$total = this.$('[data-total="price"]');
    if (this.rerenderState.host && this.hostView) {
      this.hostView.setval(this.rerenderState.host);
      this.rerenderState.host = null;
    }
    if (this.rerenderState.coverCosts !== null && this.coverCostsView) {
      this.coverCostsView.setval(this.rerenderState.coverCosts);
      this.rerenderState.coverCosts = null;
    }
    if (this.additionalView) {
      this.additionalView.setval = function (value) {
        var amount = accounting.formatMoney(value);
        if (accounting.unformat(amount)) {
          this.$('input').val(amount);
        } else {
          this.$('input').val('');
        }
        return this;
      };
      var additionalInput = this.additionalView.$('input:text');
      if (!additionalInput.attr('placeholder')) {
        additionalInput.attr('placeholder', '$0.00');
      }
      if (this.rerenderState.additional) {
        this.additionalView.setval(this.rerenderState.additional);
        this.rerenderState.additional = null;
      }
    }
    this.showHideTotal(); // check if we need to show/hide the total

    // If this looks like a hack, it's because it is.
    setTimeout(this.updateStats, 350);
    return this;
  },
  updateStats: function () {
    this.val();
    this.stats.add = this.additionalView ? accounting.unformat(this.additionalView.val()) || 0 : 0;
    this.stats.count = this.ticket_holders.length;
    this.stats.total = this.ticket_holders.reduce(toLevelTotal, this.stats.add < 0 ? 0 : this.stats.add);

    if (this.additionalView) {
      this.additionalView.$input.prop('required', !this.stats.count);
    }

    this.options.formview.contributionAmountModel.setBaseAmount(this.stats.total);

    if (this.$total) {
      this.$total.html(accounting.formatMoney(this.stats.total));
    }

    this.showHideTotal();
    this.def = this.options.definition = this.originalDef;
    return this.stats;
  },
  stats: {
    count: 0,
    total: 0,
    add: 0
  },
  additionalContributionErrors: function () {
    var errors = [];
    if (this.stats.count === 0) {
      if (this.additionalView) {
        var addVal = accounting.unformat(this.additionalView.val());
        var addDef = this.additionalView.def;
        if ('valueMin' in addDef) {
          var min = addDef.valueMin || 0.01;
          if (addVal < min) {
            errors.push(
              this.additionalView.toError(
                'Invalid amount. Additional contributions must be at least ' +
                accounting.formatMoney(min) + ' without a ticket.'
              )
            );
          }
        }
        if ('valueMax' in addDef) {
          var max = addDef.valueMax || 1000000000000;
          if (addVal > max) {
            errors.push(
              this.additionalView.toError(
                'Invalid amount. Additional contributions must be less than ' +
                accounting.formatMoney(max) + '.'
              )
            );
          }
        }
      }
    }
    return errors;
  },
  errors: function () {
    var errors = _(this.subviews).chain().invoke('errors').flatten().compact().value();
    errors = errors.concat(this.additionalContributionErrors());

    this.renderFeedback();

    return invokeCallbacks('alterErrors', {
      val: this.val(),
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors;
  },
  renderAdditionalContributionFeedback: function () {
    var feedback = [];
    if (this.additionalView) {
      var errs = this.additionalContributionErrors();
      if (errs.length) {
        feedback = this.additionalView.renderFeedbackWithErrors(errs);
      } else {
        this.$('.AdditionalContributionValue small.' + this.error_class).remove();
      }
    }
    return feedback;
  },
  renderHostCommitteeFeedback: function () {
    var feedback = [];
    if (this.hostView) {
      var errs = this.hostView.errors();
      if (errs.length) {
        feedback = this.hostView.renderFeedbackWithErrors(errs);
      }
    }
    return feedback;
  },
  renderFeedback: function () {
    var feedback = [];
    feedback = feedback.concat(this.renderAdditionalContributionFeedback());
    feedback = feedback.concat(this.renderHostCommitteeFeedback());
    return feedback;
  },
  val: function () {
    var _oberon_holders = _(this.ticket_holders.invoke('oberon_format')).chain();
    var _levels = _oberon_holders.countBy('TicketId').map(toInvertedTickets).object();
    var additional = 0;

    if (this.additionalView) {
      additional = accounting.unformat(this.additionalView.val());
      additional = (!_.isNaN(additional) && _.isFinite(additional) && additional >= 0) ? additional : 0;
    }

    var val = {
      Amount: accounting.toFixed(this.stats.total, 2),
      AdditionalContributionValue: accounting.toFixed(additional, 2),
      TicketHolders: _oberon_holders.value()
    };

    if (this.coverCostsView) {
      val.CoverCostsAmount = this.coverCostsView.val();
    }
    if (this.hostView) {
      val.HostCommittee = this.hostView.val();
    }

    return _.extend(val, _levels.value());
  }
});
