import invokeCallbacks from 'app/NVTagCallbacks';
import SelectView from 'app/views/SelectView';
import FieldView from 'app/views/FieldView';
import autocomplete from 'data/autocomplete';
// Backbone was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'lib/backbone';

export default SelectView.extend({
  __name__: 'TargetSelectView',
  initialize: function () {
    FieldView.prototype.initialize.call(this);
    this.template = this.options.templates.target_select;
  },
  render: function () {
    this.el.className = this.className();
    var def = this.def;

    var context = invokeCallbacks('alterContext', {
      element: this.type, def: def, context: {
        name: this.name,
        title: this.title,
        target: def.target,
        autocomplete: autocomplete('Prefix'),
        options: def.options
      }
    }).context;
    this.$el.html(this.template(context));
    this.setval(def.default_value);
    return this;
  },
  type: 'target_select'
});
